import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'filterVideoPipe'
})
export class FilterMultimediaVideoPipe implements PipeTransform {

  constructor() { }
  transform(array, isMaster, isVideo, isRatio, isSameFrame, isSameDuration, currentFile, extension, type, count) {
      var digitalTitle = isMaster ? 'Master' : 'Linked';
    var filteredArray = array.filter(e => e.isvideo == isVideo && e.digitaltype == digitalTitle);
    if( isRatio || currentFile != undefined ){
        var uniques = [];
        filteredArray = filteredArray.filter( (data) => {
            if( data.metadataInfo == undefined || data.metadataInfo == "" ){
                return false;
            }
            if( data.metadataInfo.mediaInfos == "" || data.metadataInfo.mediaInfos == undefined ){
                return false;
            }
            if( data.metadataInfo.mediaInfos.ratio == "" || data.metadataInfo.mediaInfos.ratio == undefined ){
                return false;
            }
            if( isRatio == false && currentFile != undefined){
                if( currentFile.metadataInfo.mediaInfos.ratio != data.metadataInfo.mediaInfos.ratio ){
                    return false;
                }
                return true;
            }
            if( uniques.includes(data.metadataInfo.mediaInfos.ratio) == false  ){
                if( currentFile != undefined ){
                    if( currentFile.metadataInfo.mediaInfos.ratio != data.metadataInfo.mediaInfos.ratio ){
                        return false;
                    }
                }
                uniques.push(data.metadataInfo.mediaInfos.ratio);
                return true;
            }else{
                return false;
            }
        });
    }
    if( isSameFrame || currentFile != undefined ){
        var uniques = [];
        filteredArray = filteredArray.filter( (data) => {
            if( data.metadataInfo == undefined || data.metadataInfo == "" ){
                return false;
            }
            if( data.metadataInfo.mediaInfos == "" || data.metadataInfo.mediaInfos == undefined ){
                return false;
            }
            if( data.metadataInfo.mediaInfos.VideoData == "" || data.metadataInfo.mediaInfos.VideoData == undefined ){
                return false;
            }
            if( data.metadataInfo.mediaInfos.VideoData.FrameSize == "" || data.metadataInfo.mediaInfos.VideoData.FrameSize == undefined ){
                return false;
            }
            if( isSameFrame == false){
                return true;
            }
            if( uniques.includes(data.metadataInfo.mediaInfos.VideoData.FrameSize) == false){
                uniques.push(data.metadataInfo.mediaInfos.VideoData.FrameSize);
                return true;
            }else{
                return false;
            }
        });
    }
    if( isSameDuration || currentFile != undefined ){
        var uniques = [];
        filteredArray = filteredArray.filter( (data) => {
            if( data.metadataInfo == undefined || data.metadataInfo == "" ){
                return false;
            }
            if( data.metadataInfo.mediaInfos == "" || data.metadataInfo.mediaInfos == undefined ){
                return false;
            }
            if( data.metadataInfo.mediaInfos.Duration == "" || data.metadataInfo.mediaInfos.Duration == undefined ){
                return false;
            }

            if( isSameDuration == false){
                return true;
            }
            if( uniques.includes(data.metadataInfo.mediaInfos.Duration) == false){
                uniques.push(data.metadataInfo.mediaInfos.Duration);
                return true;
            }else{
                return false;
            }
        });
    }

    if( extension || currentFile != undefined ){
        var uniques = [];
        filteredArray = filteredArray.filter( (data) => {
            if( data.metadataInfo == undefined || data.metadataInfo == "" ){
                return false;
            }
            
            if( extension == false){
                return true;
            }
            if( uniques.includes(data.metadataInfo.extension) == false){
                uniques.push(data.metadataInfo.extension);
                return true;
            }else{
                return false;
            }
        });
    }
    if( type != undefined ){
        if( type == "size"){
            return filteredArray.map( e => e.metadataInfo.mediaInfos.VideoData.FrameSize).join(", ");
        }
        if( type == "duration"){
            return filteredArray.map(  e => "<span class='durationAction'>" + e.metadataInfo.mediaInfos.Duration.replace("00:", "").replace("00000", "") + "</span>").join(" ");
        }
        if( type == "download"){
            var result = filteredArray.map(  e => "id=" + e.TransferId);;
            return  result.join("&");
        }
    }
    if( count ){
        return filteredArray.length;
    }
    return filteredArray;
  }

}
