import { AccessService } from './../../core/services/auth/access.service';
import { AuthService } from './../../core/services/auth/auth.service';

import { Asset, MultimediaVariant } from './../../core/models/types';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import $ from 'jquery';
import {  transferEnv } from '../../app.constants';


@Component({
    selector: 'ngbd-modal-content',
    template: require('./search-tool-modal.html'),
    styles: [require('./search-tool-modal.scss')],
    providers: [NgbTabsetConfig]
  })
  export class NgbdModalContent implements OnInit, OnDestroy {
    @Input() readLinked;
    @Input() isParent;
    @Input() linkedAssets;
    @Input() filteredListAssets;
    @Input() asset;
    @Input() Company;
    @Input() infoAssets;
    @Input() currentUser;
    multimediaActivated = false;
    @Input() tokenUser;
    @Input() IsVersionShowed;
    @Input() isToVerifyShowed;
    urlTransfer = transferEnv;
    isFullScreen = true;
    isMobile = false;
    isVideo = false;
    isInfos = false;
    DeviceDetectorService;
    AuthService;
    selectedVideo;
    hasVisibleAccess = false;
    multimediaVariants = [];
    constructor(public activeModal: NgbActiveModal, config: NgbTabsetConfig, deviceService: DeviceDetectorService, authService: AuthService) {
      this.DeviceDetectorService = deviceService;
      if( this.DeviceDetectorService.isMobile() ){
        config.orientation = 'vertical';
        this.isMobile = true;
      }else{
        config.justify = 'end';
        config.orientation = 'horizontal';
        this.isMobile = false;
      }
      this.AuthService = authService;
    }
    getSize(video, isHorizontal){
        var height = video.videoHeight;
        var width = video.videoWidth;
        if( width > height ){
            if( isHorizontal){
                return "100%";
              }else{
                return "auto";
              }
        }else{
            if( isHorizontal){
                return "auto";
              }else{
                return "300px";
              }
        }
    }
    ngOnInit(){
        if( this.AuthService.access.addVideo ){
            this.hasVisibleAccess = true;
            this.multimediaVariants = this.asset.MultimediaVariants;
        }else{
            this.hasVisibleAccess = false;
            this.multimediaVariants = this.asset.MultimediaVariants.filter( e => e.isvisible != false);
        }
        if( this.isMobile ){
                $(document).ready(function(){
                    $("#linked-modal-body").prependTo(".tab-content");
                    $("#linked-modal-body").css("padding", "0px");
                    $(".modal-content").css("overflow-y", "auto");
                  });
        }
    }
    ngOnDestroy(){

    }

    async downloadAllFiles(){
      $('.download-all-ratio').each(function(index, elem){
          new Promise((resolve, reject) => {
              resolve(window.open(elem["href"], '_blank'));
            });
      });
  }
    setSelectedVideo(file){
        if( this.selectedVideo == undefined ){
            this.selectedVideo = file;
        }
        return this.selectedVideo == file;
    }
    changeSelectedVideo(event, video){
     this.selectedVideo = video;
     $('.tab-content').scrollTop(0);
    }
    getUrlPicture(){
        if( this.isMobile){
            return "/api/files/thumb/" + this.asset.assetfile;
        }else{
            return "/api/files/" + this.asset.assetfile;
        }
    }
    getLinkedAssets(asset){
        var linked = [];
        if( asset.Children.length > 0 && asset.Parents.length > 0 ){
            linked = this.removeDuplicates(asset.Children.concat(asset.Parents), "_id");
        }else if( asset.Children.length > 0 ){
            linked =  asset.Children;
        }else if( asset.Parents.length > 0 ){
            linked =  asset.Parents;
        }
        return linked;
    }
    previousAsset(){
      var currentAssetIndex = this.filteredListAssets.indexOf(this.asset);
      var newIndex = 0;
      if( currentAssetIndex == 0 ){
        newIndex = this.filteredListAssets.length - 1;
      }else{
        newIndex = currentAssetIndex - 1;
      }
      this.asset = this.filteredListAssets[newIndex];
      event.stopPropagation();
    }

    nextAsset(){
      var currentAssetIndex = this.filteredListAssets.indexOf(this.asset);
      var newIndex = 0;
      if( currentAssetIndex == this.filteredListAssets.length - 1 ){
        newIndex = 0;
      }else{
        newIndex = currentAssetIndex + 1;
      }
      this.asset = this.filteredListAssets[newIndex];
      event.stopPropagation();
    }

    loadLinkedAsset(event, asset){
        this.asset = this.filteredListAssets.filter(a => a._id == asset._id)[0];
        event.stopPropagation();
    }

    preventClick(event){
      event.stopPropagation();
    }
    isLinked(){
      return this.readLinked;
    }

    playVideo(currentVideo){
        if (currentVideo.paused){
         currentVideo.play();
      }
    }
    stopVideo(currentVideo){
        currentVideo.pause();
    }

    removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }
    setActive(){

      if( this.readLinked ){
        this.isInfos = true;
        this.isFullScreen = false;
        this.readLinked = true;
        return 'tab-infos';
      }
    }
    getIsFullScreen(){
      return this.isFullScreen;
    }
    getVideo(currentAsset){
        var url = "";
        var videos = currentAsset.MultimediaVariants.filter(e => e.isvideo == true );

        if(videos.length > 0 ){
            return this.urlTransfer + '/api/rstransfer/' + videos[0].TransferId +"?displayType=2";
        }

       return "";
    }
    hasMultimedia(){
        return this.asset.MultimediaVariants.filter((e) => e.isinteractive && e.isvideo != true).length > 0 ;
}
    hasVideo(){
        if( this.asset.ispartialtoverify && this.isToVerifyShowed != true){
            return false;
        }
        return this.asset.MultimediaVariants.filter((e) =>  e.isvideo).length > 0 ;
    }
    hasSM(){
        return this.asset.MultimediaVariants.filter((e) => e.isinteractive == false && e.isvideo != true).length > 0 ;
    }
    beforeChange(event){
      this.readLinked = false;

       if( event.activeId == "tab-titledesktop" || event.activeId == "tab-titlemobile"){
        this.isFullScreen = false;
      }else if( event.nextId == "tab-titledesktop" || event.nextId == "tab-titlemobile"){
        this.isFullScreen = true;
      }

      if( event.nextId == "tab-infos"){
        this.isInfos = true;
      }else{
        this.isInfos = false;
      }
      if( event.nextId == "tab-video"){
        this.isVideo = true;
      }else{
          this.isVideo = false;
      }
      if( event.nextId == 'tab-digit' || event.nextId == "social-media"){
        var component = this;

        var currentMultimediaVariants;
        var id = "tab-digit";
        if( event.nextId == "social-media" ){
            id = "social-media";
            currentMultimediaVariants = this.asset.MultimediaVariants.filter(e => e.isinteractive == false);
        }else{
            currentMultimediaVariants = this.asset.MultimediaVariants.filter(e => e.isinteractive == true);
        }

        $("#" + id + "-panel .seb-iframe").each(function(index, element){
            var link = '/api/files/' + currentMultimediaVariants[index].FileId;
            element['src'] = link;
        })
        this.multimediaActivated = true;
      }else{
        this.multimediaActivated = false;
      }
    }

    getPicture(){
        return "/api/files/" + this.asset.assetfile;
    }
  }
