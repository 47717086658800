import { Component, Input, ViewChild } from '@angular/core';

import {debounceTime, map, filter, distinctUntilChanged} from 'rxjs/operators';
import {Observable, of, Subject, merge} from 'rxjs';
import { SearchToolComponent } from '../search-tool.component';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'searchPane',
    template: require('./search-pane.html'),
     styles: [require('./search-pane.scss')],
})
export class SearchPaneComponent {
    @Input() currentSearch;
    @Input() assets;
    @Input() tokenUser;
    @Input() parent:SearchToolComponent;
    @Input() IsVersionShowed = false;
    static parameters = [];
    // Search panel focus elements
    focusItem = new Subject<string>();
    clickItem = new Subject<string>();
    @ViewChild('instance') instance: NgbTypeahead;

    constructor() {
    }

    changeSearch(){
        this.parent.changeSearch(this.currentSearch);
    }

    onEnter(event){
        this.currentSearch = event.item.fullname;
        this.parent.changeSearch(event.item);
        this.parent.onEnter();
        event.preventDefault();
    }

    removeSearch(){
        this.parent.removeSearch();
    }

    search = (text$: Observable<string>) =>  {
        const debouncedTexts = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup = this.clickItem.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus = this.focusItem;

        return merge(debouncedTexts, inputFocus, clicksWithClosedPopup).pipe(
            map(term => term === '' ? [] :
                // tslint:disable-next-line:max-line-length
                this.assets.filter(v => v.fullname.toLowerCase().indexOf(term.toLowerCase()) > -1 || (v.companyuniqueid == undefined ? -1 : v.companyuniqueid.toLowerCase().indexOf(term.toLowerCase())) > -1 ||  v.Tags.filter(t => t.name.toLowerCase().indexOf(term.toLowerCase()) > -1).length > 0 ||  v.FeatureCats.filter(t => t.name.toLowerCase().indexOf(term.toLowerCase()) > -1).length > 0 || v.StateCats.filter(t => t.name.toLowerCase().indexOf(term.toLowerCase()) > -1).length > 0).slice(0, 10))
        );

    }

    onFocus(event){
        var val = event.target.value == '' ? ' ' : event.target.value;
        if( val == ' '){
            if( this.currentSearch == '' ){
                this.focusItem.next(val);
            }

        }else{

        }


    }

    onClick(event){
        var val = event.target.value == '' ? ' ' : event.target.value;
    }

    formatter = (x: {fullname: string}) => x.fullname || x;

}
