import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filename'
})
export class FileNamePipe implements PipeTransform {
  transform(value: string) {
      console.log(value);
      if( value == undefined ){
          return "";
      }else{
        return value.split('.').slice(0, -1).join('.');
      }

  }
}
