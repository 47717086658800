import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyprint'
})
export class PrettyPrintPipe implements PipeTransform {

  constructor() { }
  transform(val) {
    return JSON.stringify(val, null, 2)
      .replace('{', '')
      .replace('}', '')
      .replace(/"/g, '')
      .replace(/,/g, '')
      .replace(/\n/g, '\n');
  }

}
