import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class CaptionType {
    _id?: string;
	id?: string;
	name?: string;
	iswebsite?: string;
CompanyId?: string;
	
}


@Injectable()
export class CaptionService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<CaptionType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/captions', {params: body}) as Observable<CaptionType[]>;
        }else{
            return this.http.get('/api/captions') as Observable<CaptionType[]>;
        }
       
    }
	queryInclude(query:{}, include:{}): Observable<CaptionType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/captions',  {params: body}) as Observable<CaptionType[]>;
        }else{
            return this.http.get('/api/captions') as Observable<CaptionType[]>;
        }
       
    }
    get(id: string): Observable<CaptionType> {
        return this.http.get(`/api/captions/${id}`) as Observable<CaptionType>;
    }
    create(caption: CaptionType): Observable<CaptionType> {
        return this.http.post(`/api/captions`, caption);
    }
	update(caption: CaptionType): Observable<CaptionType> {
       return this.http.put(`/api/captions/${caption.id || caption._id}`, caption);
    }
    updateInclude(caption: CaptionType, query:{}, include:{}): Observable<CaptionType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/captions/${caption.id || caption._id}`,  caption,  {params: body}) as Observable<CaptionType[]>;
        }else{
            return this.http.put(`/api/captions/${caption.id || caption._id}`, caption) as Observable<CaptionType[]>;
        }
    }
	remove(caption): Observable<CaptionType> {
        return this.http.delete(`/api/captions/${caption.id || caption._id}`);
    }
    queryRemove(query:{}): Observable<CaptionType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/captions',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

