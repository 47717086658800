import { MultimediaFilterVideoModule } from './../../core/pipes/multimedia-filter-video.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MasterlineComponent } from './masterline.component';

export const ROUTES/*: Routes*/ = [
    { path: 'masterline', component: MasterlineComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        BrowserModule,
        MultimediaFilterVideoModule
    ],
    declarations: [
        MasterlineComponent,
    ],
    exports: [
        MasterlineComponent,
    ],
})
export class MasterlineModule {}
