import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {
  transform(url : string) {
      if( url == undefined ){
          return "";
      }
      if( url.includes("?")){
        return url + '&access_token=' + localStorage.getItem('access_token');
      }else{
        return url + '?access_token=' + localStorage.getItem('access_token');
      }
  }

}
