export class PermissionConstants {
    // Global
    public static Development: string = "rs-development";
    // Asset
    public static GEC_Archive_Asset: string = "lgt-archive-asset";
    public static GEC_Delete_Asset: string = "lgt--delete-asset";
    public static GEC_Verify_Asset: string = "lgt-verify-asset";
    public static GEC_Export_Asset: string = "lgt-export-asset";
    public static GEC_Import_Asset: string = "lgt-import-asset";
    public static GEC_Command_Asset: string = "lgt-command-asset";
    public static GEC_Create_Asset: string = "lgt-create-asset";
    public static GEC_Show_History_Asset: string = "lgt-show-history-asset";
    public static LGT_Approve_Asset:  string = "lgt-approve-asset";

    public static GEC_Manage_Tag: string = "lgt-manage-tag";
    public static GEC_Show_History_Menu: string = "lgt-show-history-menu";
    public static GEC_Show_Archive: string = "lgt-archive-asset";
    public static GEC_Show_Guideline: string = "lgt-show-guideline";
    public static GEC_Show_Masterline: string = "lgt-show-masterline";
    public static GEC_Show_All_Masterline: string = "lgt-show-all-masterline";
    public static GEC_Show_Processing: string = "lgt-show-processing";
    public static GEC_Show_Lightable: string = "lgt-show-lightable";
    public static GEC_Show_Company: string = "gec-show-company-list";
}
