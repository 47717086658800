import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LinkedAssetsComponent } from './linked-assets.component';
import { BrowserModule } from '@angular/platform-browser';
import { SearchPaneModule } from '../../search-tool/search-pane/search-pane.module';

export const ROUTES/*: Routes*/ = [
    { path: 'linked-assets', component: LinkedAssetsComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        BrowserModule,
        SearchPaneModule
    ],
    declarations: [
        LinkedAssetsComponent,
    ],
    exports: [
        LinkedAssetsComponent,
    ],
})
export class LinkedAssetsModule {}
