
    export class Annotation{
	 _id : string = '';
	 folder : string = '' ;
	 comment : string = '' ;
	 drawing : string = '' ;
	 Assets : Asset[] = [];
	 UserId : string;
	 }
	export class Asset{
	 _id : string = '';
	 type : string = '' ;
	 rsuniqueid : string = '' ;
	 companyuniqueid : string = '' ;
	 isenabled : Boolean = false ;
	 fullname : string = '' ;
	 assetfile : string = '' ;
	 comment : string = '' ;
	 begindate : Date = new Date() ;
	 enddate : Date = new Date() ;
	 isarchived : Boolean = false ;
	 hasconstraint : Boolean = false ;
	 isguideline : Boolean = false ;
	 istoverify : Boolean = false ;
	 isapproved : Boolean = false ;
	 version : string = '' ;
	 isversion : Boolean = false ;
	 islastversion : Boolean = false ;
	 UserId : string;
	 HistoryBlocks : HistoryBlock[] = [];
	 CompanyId : string;
	 WeFileId : string;
	 AnnotationId : string;
	 ValidationId : string;
	 }
	export class Caption{
	 _id : string = '';
	 name : string = '' ;
	 iswebsite : string = '' ;
	 CompanyId : string;
	 }
	export class Company{
	 _id : string = '';
	 name : string = '' ;
	 candownload : Boolean = false ;
	 shortname : string = '' ;
	 logo : string = undefined ;
	 background : string = undefined ;
	 Users : User[] = [];
	 CompanyColors : CompanyColor[] = [];
	 Captions : Caption[] = [];
	 RsContacts : RsContact[] = [];
	 Files : File[] = [];
	 Assets : Asset[] = [];
	 Tags : Tag[] = [];
	 FeatureCats : FeatureCat[] = [];
	 StateCats : StateCat[] = [];
	 TagCats : TagCat[] = [];
	 InfoAssets : InfoAsset[] = [];
     Guidelines : Guideline[] = [];
     Masterlines : Masterline[] = [];
     }
     export class Selection{
        _id: string = '';
        name: string = '';
        type : string = '';
        comment : string = '';
        color: string = '';
     }
	export class CompanyColor{
	 _id : string = '';
	 name : string = '' ;
	 color : string = '' ;
	 CompanyId : string;
	 }
	export class FeatureCat{
	 _id : string = '';
	 name : string = '' ;
	 CompanyId : string;
	 }
	export class File{
	 _id : string = '';
	 name : string = '' ;
	 extension : string = '' ;
	 path : string = '' ;
	 blob : string = '' ;
	 type : string = '' ;
	 mimetype : string = '' ;
	 size : string = '' ;
	 UserId : string;
	 CompanyId : string;
	 MultimediaVariants : MultimediaVariant[] = [];
     Guidelines : Guideline[] = [];
     }


	export class Guideline{
	 _id : string = '';
	 type : string = '' ;
	 name : string = '' ;
	 format : string = '' ;
	 FileId : string;
	 CompanyId : string;
     }
     export class Masterline{
        _id : string = '';
        type : string = '' ;
        name : string = '' ;
        format : string = '' ;
        FileId : string;
        CompanyId : string;
        }
	export class HistoryBlock{
	 _id : string = '';
	 bounddate : Date = new Date() ;
	 description : string = '' ;
	 iscreated : Boolean = false ;
	 AssetId : string;
	 UserId : string;
	 }
	export class InfoAsset{
	 _id : string = '';
	 collection : string = '' ;
	 shortname : string = '' ;
	 diameter : string = '' ;
	 type : string = '' ;
	 casebezel : string = '' ;
	 dial : string = '' ;
	 strap : string = '' ;
	 setting : string = '' ;
	 specific : string = '' ;
	 CompanyId : string;
	 }
	export class Metadata{
	 _id : string = '';
	 data : string = '' ;
	 name : string = '' ;
	 }
	export class MultimediaVariant{
	 _id : string = '';
	 name : string = '' ;
	 duration : Number = 0.0 ;
	 isinteractive : Boolean = false ;
	 digitaltype : string = '' ;
     format : string = '' ;
     isvideo: boolean = false;
     FileId : string;
     TransferId: string;
	 }
	export class Nature{
	 _id : string = '';
	 maxsize : string = '' ;
	 minsize : string = '' ;
	 maxwidth : string = '' ;
	 minwidth : string = '' ;
	 }

	export class RsContact{
	 _id : string = '';
	 email : string = '' ;
	 CompanyId : string;
	 }
	export class StateCat{
	 _id : string = '';
	 name : string = '' ;
	 color : string = '' ;
	 CompanyId : string;
	 }
	export class Subject{
	 _id : string = '';
	 color : string = '' ;
	 brand : string = '' ;
	 collection : string = '' ;
	 position : string = '' ;
	 type : string = '' ;
	 }
	export class Tag{
	 _id : string = '';
	 name : string = '' ;
	 TagCatId : string;
	 CompanyId : string;
	 }
	export class TagCat{
	 _id : string = '';
	 name : string = '' ;
	 isdisplayed : Boolean = false ;
	 istagsdisplayed : Boolean = false ;
	 Tags : Tag[] = [];
	 CompanyId : string;
	 }
	export class Test{
	 _id : string = '';
	 name : string = '' ;
	 }
	export class User{
	 _id : string = '';
	 name : string = '' ;
	 firstname : string = '' ;
	 password : string = '' ;
	 email : string = '' ;
     CompanyId : string;
     Company: Company = new Company();
	 Assets : Asset[] = [];
     role : string = '';
	 HistoryBlocks : HistoryBlock[] = [];
	 Files : File[] = [];
	 Annotations : Annotation[] = [];
     Validations : Validation[] = [];
     Companies : Company[] = [];
     }

	export class Validation{
	 _id : string = '';
	 comment : string = '' ;
	 isvalidate : Boolean = false ;
	 UserId : string;
	 Assets : Asset[] = [];
	 }
	export class WeFile{
	 _id : string = '';
	 size : string = '' ;
	 url : string = '' ;
	 filename : string = '' ;
	 Assets : Asset[] = [];
     }

     export class UserExt extends User{
    }
	declare global {
		interface Array<T> {
		  empty(this: Array<T>): boolean;
		}
	  }
	  Array.prototype.empty = function() {
		if (this.length == 0) {
		  return true;
		} else {
		  return this.some((c) => c != null &&
			c != '' // error! c has to be MyObject, not string
		  );
		}
	  };
