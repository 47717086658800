import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuidelineComponent } from './guideline.component';
import { FileModule } from '../../shared-pages/file/file.module';
import { BrowserModule } from '@angular/platform-browser';

export const ROUTES/*: Routes*/ = [
    { path: 'guideline', component: GuidelineComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        BrowserModule,
        FileModule
    ],
    declarations: [
        GuidelineComponent,
    ],
    exports: [
        GuidelineComponent,
    ],
})
export class GuidelineModule {}
