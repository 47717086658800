import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { finalize, map, catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    $('app-rs-loader .notShowed').addClass('showed').removeClass('notShowed');
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.tokenUser}`
      }
    });
    return next.handle(request).pipe(
        map(event => {
          return event;
        }),
        finalize(() => {
            setTimeout(() => {
                $('app-rs-loader .showed').addClass('notShowed').removeClass('showed');
            }, 1000);
        })
        )
  }
}
