import { Router } from '@angular/router';
import { AuthService } from './core/services/auth/auth.service';
import { Component } from '@angular/core';

@Component({
    selector: 'app',
    template: `<navbar></navbar>
    <div id="lightbox-app">
    <router-outlet></router-outlet>
    </div>
    <tour-step-template></tour-step-template>
    <footer></footer>`
})
export class AppComponent {
    constructor(private authService: AuthService, private router: Router){
        if (window.location.href.indexOf('?postLogout=true') > 0) {
            this.authService.signoutRedirectCallback().then(() => {
                let url: string = this.router.url.substring(0,this.router.url.indexOf('?'));
                this.router.navigateByUrl(url);
              });
        }else{
            this.authService.isLoggedInOIDC().then( (result) => {
                if( !result){
                 this.authService.startAuthentication();
                }
            });
        }
    }
}
