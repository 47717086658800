
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../core/services/auth/auth.service';
import { CompanyService } from '../../core/services/company/company.service';
import { Company } from '../../core/models/types';
import {  transferEnv } from '../../app.constants';
import * as $ from 'jquery';

@Component({
    selector: 'masterline',
    template: require('./masterline.html'),
    providers: [CompanyService],
    styles: [require('./masterline-show.scss')],
})
export class MasterlineComponent implements OnInit, OnDestroy {
    AuthService;
    CompanyService;
    currentCompany;
    Company;
    currentUser;
    isMultiple;
    urlTransfer = transferEnv;
    static parameters = [AuthService, CompanyService];
    constructor(private authService: AuthService, private companyService: CompanyService) {
        this.AuthService = authService;
        this.CompanyService = companyService;
        this.currentUser = this.AuthService.currentUser;
        this.setCurrentCompany();
    }
    ngOnInit() {
        $("#masterline-container").show();

    }


    ngOnDestroy(){
        $("#masterline-container").hide();
    }

    setCurrentCompany(){
        this.currentCompany = this.AuthService.currentSelectedCompany;
        this.setIsMultiple();
        this.AuthService.currentSelectedCompanyChanged.subscribe(company => {
                this.currentCompany = company;
                this.setIsMultiple();
        });
    }

    set3dMasterline(masterline){
        var canSeeStats = this.AuthService.access.showAllMasterLine;
        var url = this.urlTransfer + '/api/rstransfer/' + masterline.FileId + "?displayType=1&access_token=" + localStorage.getItem("access_token") + "&stat";
        eval.apply(this, ["var bookMasterline = $('#masterline-container').FlipBook({pdf:'" + url  + "', renderInactivePages: true, controlsProps: {actions: {cmdPrint: {enabled: false}, cmdStats: {enabled: " + canSeeStats + ", fileId: '" + masterline.name + "'  , stat:'" +  this.AuthService.currentSelectedCompany.stats + "'}}}, preloadPages: 10});"]);
    }

    setIsMultiple(){
        if( this.currentCompany.Masterlines.length > 1 ){
            this.isMultiple = true;
            eval.apply(this, ["$('#masterline-container').css('height', '88vh')"]);
        }else{
            this.isMultiple = false;
        }
    }
}
