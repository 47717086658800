import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { AssetComponent } from './asset.component';
import { TagModule } from './tag/tag.module';
import { FileModule } from '../shared-pages/file/file.module';
import { InfoAssetsModule } from './info-assets/info-assets.module';
import { MultimediavariantModule } from './multimediavariant/multimediavariant.module';
import { LinkedAssetsModule } from './linked-assets/linked-assets.module';
import { GuidelineModule } from './guideline/guideline.module';
import { VideoModule } from './video/video.module';


export const ROUTES/*: Routes*/ = [
    { path: 'asset', component: AssetComponent },
    { path: 'asset/:id', component: AssetComponent },
];

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        NgbModule,
        InfoAssetsModule,
        LinkedAssetsModule,
        MultimediavariantModule,
        GuidelineModule,
        TagModule,
        FileModule,
        VideoModule,
        RouterModule.forChild(ROUTES)
    ],
    declarations: [
        AssetComponent
    ],
    exports: [
        AssetComponent
    ],
})
export class AssetModule {}

