import { Component, Input, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorConfig } from '../editor/config';
import { ApplicationType, ModuleType, FileDto } from 'rs-transfer';
import { Subject } from 'rxjs';
import {  transferEnv } from '../../app.constants';

@Component({
    selector: 'confirmationDialog',
    template: require('./confirmation-dialog.html'),
    // styles: [require('./confirmation-dialog.css')],
})
export class ConfirmationDialogComponent implements OnInit {
    ConfirmService;
    @Input() title: string;
    @Input() message: string;
    @Input() hasInput:boolean = false;
    @Input() btnOkText: string;
    @Input() body: string;
    @Input() email: string;
    @Input() btnCancelText: string;
    @Input() companyId: string;
    @Input() actionType: ModuleType;
    urlTransfer = transferEnv;
    files: FileDto[] = [];
    uploadFiles: Subject<any> = new Subject();
    urlInfos = {
        CompanyId: undefined,
        ApplicationId: ApplicationType.Lightbox,
        AccessToken : localStorage.getItem('access_token'),
        Module : ModuleType.CommentVideo,
        Url: this.urlTransfer,
        GenerateThumb: false,
        ReturnFile: true,
        texts: {
          afterUploadMsg_success: 'Successfully Uploaded !',
          afterUploadMsg_error: 'Upload Failed !',
          title: 'Dropzone',
        }
      };
    editorConfig: AngularEditorConfig = {
        spellcheck: true,
        minWidth: "100%",
        maxWidth: "100%",
        width: "100%",
        refusedMimes: ["*"],
        placeholder: 'Enter content mail here...',
        translate: 'no',
        toolBar: [['bold', 'italic']]
      };
    ActiveModal;
    inputData = "";
    videoInfos = {audio: true, typeBitRate: true};
    isVideo = false;
    static parameters = [NgbActiveModal];
    constructor(private activeModal: NgbActiveModal) {
        this.ActiveModal = activeModal;
    }
    ngOnInit(){
        this.urlInfos.Module = this.actionType;
        this.urlInfos.CompanyId = this.companyId;
        if( this.actionType == ModuleType.Video){
            this.isVideo = true;
        }
    }
    saveComment(){

    }
    public decline() {
        this.ActiveModal.close(false);
      }

      public accept() {
          if( this.inputData != "" ){
            this.uploadFiles.next(true);

                    this.uploadFiles.subscribe((data) => {
                        var videoFiles = [];
                            if( data.length >= 0 ){
                                if( data == "No Files"){

                                }else{
                                    data.forEach(element => {
                                        videoFiles.push({"path" : this.urlInfos.Url + "/api/rstransfer/" + element.id + "?displayType=1&download", "filename": element.name, "httpHeaders": {"authorization" : "Bearer " +  localStorage.getItem("access_token")} });
                                });
                                }
                            }
                            this.ActiveModal.close({"email": this.email, "data": this.inputData, "files": videoFiles, "videoInfos": this.videoInfos, "isVideo": this.isVideo});
                    });
          }else{
            this.ActiveModal.close(true);
          }
      }

      public dismiss() {
        this.ActiveModal.dismiss();
      }

}
