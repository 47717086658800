import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class CompanyType {
    _id?: string;
	id?: string;
	name?: string;
	candownload?: boolean;
    logo?: string;
	background?: string;
	shortname?: string;
}

@Injectable()
export class CompanyService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(company?: CompanyType): Observable<CompanyType[]> {
        return this.http.get('/api/companys') as Observable<CompanyType[]>;
    }

    queryInclude(query:{}, include:{}): Observable<CompanyType[]> {
        if( query != undefined || include != undefined ){
                  var body = {};
               body["where"] = JSON.stringify(query);
               body["include"] = JSON.stringify(include);
               return this.http.get('/api/companys',  {params: body}) as Observable<CompanyType[]>;
          }else{
              return this.http.get('/api/companys') as Observable<CompanyType[]>;
          }
         
      }

    queryWithLogo(company?: CompanyType): Observable<CompanyType[]> {
        return this.http.get('/api/companys/logo') as Observable<CompanyType[]>;
    }
    queryWithBckg(company?: CompanyType): Observable<CompanyType[]> {
        return this.http.get('/api/companys/bckg') as Observable<CompanyType[]>;
    }
    queryWithAsset(company?: CompanyType): Observable<CompanyType[]> {
        return this.http.get('/api/companys/assets') as Observable<CompanyType[]>;
    }
    getWithLogo(id: string): Observable<CompanyType[]> {
        return this.http.get(`/api/companys/${id}/logo`) as Observable<CompanyType[]>;
    }
    getWithBckg(id: string): Observable<CompanyType[]> {
        return this.http.get(`/api/companys/${id}/bckg`) as Observable<CompanyType[]>;
    }
    getWithAsset(id: string): Observable<CompanyType[]> {
        return this.http.get(`/api/companys/${id}/assets`) as Observable<CompanyType[]>;
    }
    get(id: string): Observable<CompanyType> {
        return this.http.get(`/api/companys/${id}`) as Observable<CompanyType>;
    }
    create(company: CompanyType): Observable<CompanyType> {
        return this.http.post(`/api/companys`, company);
    }
	update(company: CompanyType): Observable<CompanyType> {
       return this.http.put(`/api/companys/${company.id || company._id}`, company);
    }
    remove(company): Observable<CompanyType> {
        return this.http.delete(`/api/companys/${company.id || company._id}`);
    }
}

