import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AirToolComponent } from './air-tool.component';
import { AgGridModule } from 'ag-grid-angular';

export const ROUTES/*: Routes*/ = [
    { path: 'air-tool', component: AirToolComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        AgGridModule.withComponents([])
    ],
    declarations: [
        AirToolComponent,
    ],
    exports: [
        AirToolComponent,
    ],
})
export class AirToolModule {}
