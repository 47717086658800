import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'videoType'
})
export class VideoTypePipe implements PipeTransform {
  transform(name: string) {
    var videoType = "Standard";
    var nameArray = name.split("_");
    if( nameArray[nameArray.length - 1].includes("IGP") ){
        videoType = "Instagram Portrait";
    }
    if( nameArray[nameArray.length - 1].includes("IGS") ){
        videoType = "Instagram Story";
    }
    return videoType;
  }
}
