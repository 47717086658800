import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TagAdminComponent } from './tag-admin.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { OrderModule } from 'ngx-order-pipe';

export const ROUTES/*: Routes*/ = [
    { path: 'tag-admin', component: TagAdminComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        NgbModule,
        BrowserModule,
        FormsModule,
        ColorPickerModule,
        OrderModule
    ],
    declarations: [
        TagAdminComponent,
    ],
    exports: [
        TagAdminComponent,
    ],
})
export class TagAdminModule {}
