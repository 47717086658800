import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'filterInteractive'
})
export class FilterMultimediaInteractivePipe implements PipeTransform {

  constructor() { }
  transform(array) {
   return array.filter(e => e.isvideo != true);
  }

}
