import { RsTransferModule } from 'rs-transfer';
import { AngularEditorModule } from './../editor/angular-editor.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

export const ROUTES/*: Routes*/ = [
    { path: 'confirmation-dialog', component: ConfirmationDialogComponent },
];


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        AngularEditorModule,
        RsTransferModule
    ],
    declarations: [
        ConfirmationDialogComponent,
    ],
    exports: [
        ConfirmationDialogComponent,
    ],
})
export class ConfirmationDialogModule {}
