import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grdFilterInverse'
})
export class GrdFilterInversePipe implements PipeTransform {
  transform(items: any, filter: any, filterMetadata, isAll: boolean, defaultFilter: boolean): any {
    if (!filter){
      return items;
    }

    if (!Array.isArray(items)){
      return items;
    }
    
    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);

      if (defaultFilter) {
        return items.filter(item =>
            filterKeys.reduce((x, keyName) =>
                (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
      }
      else {
       var result = items.filter(item => {
        return filterKeys.some((keyName) => {
          if( keyName[0] == keyName[0].toUpperCase()){
            if( typeof filter[keyName] == "object"){
            
            }else{
              return item[keyName].filter(childItem => {
                return new RegExp(filter[keyName], 'gi').test(childItem["name"]) || filter[keyName] == "";
          }).length > 0;
            }
          }else{

            if( typeof filter[keyName] == "object"){
              if( keyName == "fullname"){
                filter[keyName] == undefined;
                return filter[keyName] == "";
              }
              var filterTest = filter[keyName];
              return new RegExp(filterTest["fullname"], 'gi').test(item[keyName]) || filterTest["fullname"] == "";
            }
            if( keyName == "fullname"){
              filter[keyName] == undefined;
              return filter[keyName] == "";
            }
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) == false || filter[keyName] == "";
          }
        });
      });
      if( isAll ){
        filterMetadata.count = items.length - result.length;
      }else{
        filterMetadata.count = result.length;
      }
     
      
      return result;
      }
    }
  }
}

