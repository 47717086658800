import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AssetExt } from '../../../core/models/types.extension';
import { isNumber } from 'util';

@Component({
    selector: 'infoAssets',
    template: require('./info-assets.html'),
    styles: [require('./info-assets.scss')],
})
export class InfoAssetsComponent implements OnInit {
    @Input() asset: AssetExt;
    @Input() infos;
    parsedInfos = {};
    static parameters = [];
    constructor() {
        if( this.infos != undefined ){
            this.parseInfos();
        }
    }

    ngOnInit(){
        if( this.infos != undefined ){
            this.parseInfos();
        }
    }

    parseInfos(){
        if( !(this.asset.companyuniqueid == null)){
            var keys = this.asset.companyuniqueid.split(".");
            this.parsedInfos = {};
            this.parsedInfos["collection"] = JSON.parse(this.infos[0]["collection"])[keys[0][0]];
            this.parsedInfos["casebezel"] = JSON.parse(this.infos[0]["casebezel"])[keys[1]];
            var dialKey = keys[2][0] == "0" ? keys[2].substring(1, 4) : keys[2];
            this.parsedInfos["dial"] = JSON.parse(this.infos[0]["dial"])[dialKey];
            this.parsedInfos["strap"] = JSON.parse(this.infos[0]["strap"])[keys[3]];

            this.parsedInfos["type"] = JSON.parse(this.infos[0]["type"])[keys[0]];
            if( keys.length > 5){
                this.parsedInfos["setting"] = JSON.parse(this.infos[0]["setting"])[keys[4]];
                this.parsedInfos["specific"] = JSON.parse(this.infos[0]["specific"])[keys[5].substring(0, 3)];
            }else if( keys.length > 4){
                if( isNumber( keys[4] ) ){
                    this.parsedInfos["setting"] = JSON.parse(this.infos[0]["setting"])[keys[4]];
                }else{
                    this.parsedInfos["specific"] = JSON.parse(this.infos[0]["specific"])[keys[4].substring(0, 3)];
                }
            }
    }
    }
}
