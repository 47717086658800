import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class TagType {
    _id?: string;
	id?: string;
	name?: string;
TagCatId?: string;
	CompanyId?: string;
	
}

	@Injectable({ providedIn: 'root' })
	export class TagService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<TagType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/tags', {params: body}) as Observable<TagType[]>;
        }else{
            return this.http.get('/api/tags') as Observable<TagType[]>;
        }
       
    }
	queryInclude(query:{}, include:{}): Observable<TagType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/tags',  {params: body}) as Observable<TagType[]>;
        }else{
            return this.http.get('/api/tags') as Observable<TagType[]>;
        }
       
    }
    get(id: string): Observable<TagType> {
        return this.http.get(`/api/tags/${id}`) as Observable<TagType>;
    }
    create(tag: TagType): Observable<TagType> {
        return this.http.post(`/api/tags`, tag);
    }
	update(tag: TagType): Observable<TagType> {
       return this.http.put(`/api/tags/${tag.id || tag._id}`, tag);
    }
    updateInclude(tag: TagType, query:{}, include:{}): Observable<TagType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/tags/${tag.id || tag._id}`,  tag,  {params: body}) as Observable<TagType[]>;
        }else{
            return this.http.put(`/api/tags/${tag.id || tag._id}`, tag) as Observable<TagType[]>;
        }
    }
	remove(tag): Observable<TagType> {
        return this.http.delete(`/api/tags/${tag.id || tag._id}`);
    }
    queryRemove(query:{}): Observable<TagType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/tags',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

