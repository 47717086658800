import { RetouchModule } from './retouch/retouch.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProcessingComponent } from './processing.component';


export const ROUTES/*: Routes*/ = [
    { path: 'processing', component: ProcessingComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        RetouchModule
    ],
    declarations: [
        ProcessingComponent,
    ],
    exports: [
        ProcessingComponent,
    ],
})
export class ProcessingModule {}
