import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'filterPipe'
})
export class FilterMultimediaPipe implements PipeTransform {

  constructor() { }
  transform(array, isSM) {
    var filteredArray = array.filter(e => e.isinteractive == isSM && e.isvideo != true);
    return filteredArray;
  }

}
