import { HistoryBlockService } from './../../core/services/historyblock/historyblock.service';
import { MultimediaVariantService } from './../../core/services/multimediavariant/multimediavariant.service';
import { Component, ViewChild, OnInit, OnDestroy, Input } from '@angular/core';
import { AssetService } from '../../core/services/asset/asset.service';
import { SocketService } from '../../core/services/socket/socket.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { UserService } from '../../core/services/auth/user.service';
import { TagComponent } from './tag/tag.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FileComponent } from '../shared-pages/file/file.component';
import {NgbTabChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {MultimediavariantComponent} from './multimediavariant/multimediavariant.component';
import { GuidelineComponent } from './guideline/guideline.component';
import {Location} from '@angular/common';
import { diff } from 'deep-diff';
import { Asset, InfoAsset, Tag, FeatureCat, StateCat, HistoryBlock, MultimediaVariant } from '../../core/models/types';
import { AssetExt } from '../../core/models/types.extension';
import { VideoComponent } from './video/video.component';
import { map } from 'rxjs/operators';


@Component({
    selector: 'asset',
    template: require('./asset.html'),
     styles: [require('./asset.scss')],
	 providers: [AssetService, UserService, MultimediaVariantService, HistoryBlockService]
})

export class AssetComponent implements OnInit, OnDestroy  {
    @ViewChild(FileComponent) file: FileComponent;
    @ViewChild(TagComponent) tag: TagComponent;
    @ViewChild(MultimediavariantComponent) multimedia: MultimediavariantComponent;
    @ViewChild(VideoComponent) video: VideoComponent;
	@Input() assets: AssetExt[] = [];
    @Input() asset: AssetExt =  new AssetExt();
    creator;
    modificator;
    AssetService;
    hideHistory = false;
    SocketService;
    HistoryBlockService;
    AuthService;
    currentUser;
    tagColor = "";
    currentCompany;
    radioValue : string = 'noconstrains';
    activatedRoute;
    newAsset = '';
    initialAsset: AssetExt = new AssetExt();
	errors = {};
    submitted = false;
    Router;
    UserService;
    usersByCompany;
    tokenUser;
    children = [{association: "Tags", include:["TagCat"]}, "StateCats", "FeatureCats", "HistoryBlocks", "Children", "Parents", "MultimediaVariants", "Guidelines"];
    Location;
    MultimediaVariantService;
    differences = {};
    returnUrl : string;
    isUndo = false;
    allowSave = false;
	static parameters = [AssetService, SocketService, AuthService, Router, ActivatedRoute, UserService, Location, MultimediaVariantService, HistoryBlockService];
    constructor(private assetService: AssetService, private socketService: SocketService, private authService: AuthService, private router:Router, private route:ActivatedRoute, private userService: UserService, private location: Location, private multimediaService: MultimediaVariantService, private historyBlockService: HistoryBlockService) {
        this.AssetService = assetService;
		this.SocketService = socketService;
        this.AuthService = authService;
        this.Router = router;
        this.activatedRoute = route;
        this.UserService = userService;
        this.Location = location;
        this.HistoryBlockService = historyBlockService;
        this.constructChildren();
        this.currentUser = this.AuthService.currentUser;
        this.returnUrl = '/home';
        this.setCurrentCompany();

        this.MultimediaVariantService = multimediaService;
    }
    setCurrentCompany(){
        this.currentCompany = this.AuthService.currentSelectedCompany;
        this.UserService.query({"CompanyId" : [this.currentCompany._id, "1"]}).subscribe((data) => {
            this.usersByCompany = data;
        })
        this.AuthService.currentSelectedCompanyChanged.subscribe(company => {
            this.currentCompany = company;
            this.setAssetUserId();
            this.UserService.query({"CompanyId" : [company._id, "1"]}).subscribe((data) => {
                this.usersByCompany = data;
            })
        });
    }

    addedFiles(event){
      if( event == 'differentFile' || this.isNewAsset()){
        this.allowSave = true;
      }

    }
    addedVideos(event){
        this.allowSave = true;
    }
    uploadedFiles(event){
        var include = [{association: "Tags", include:["TagCat"]}, {association: "HistoryBlocks", include:["User"]}, "FeatureCats", "StateCats", "Children", "WeFile", "Validation", "Parents", "Guidelines", "Selections", {association: "MultimediaVariants", include:["File"]}];
            this.AssetService.queryIncludeWhitoutCache({_id: this.asset._id}, include).subscribe((data) => {
                this.AssetService.updateCache(data[0], "create");
                this.Router.navigateByUrl(this.returnUrl);
            });

    }

    setCurrentItem(){
        this.hideHistory = true;
        this.tokenUser = this.AuthService.tokenUser;
        this.setAssetUserId();
          if( window.history.state != undefined ){
            this.returnUrl = window.history.state.returnUrl;
          }
        if( this.activatedRoute.snapshot.paramMap.get('id') != undefined ){
                if( window.history.state.assets != undefined ){
                 this.assets = window.history.state.assets;
                this.initialAsset = window.history.state.asset;
                this.asset = this.jsonCopy(this.initialAsset);
                this.HistoryBlockService.queryInclude({"AssetId":this.asset._id}, ["User"]).subscribe((data) => {
                    this.asset.HistoryBlocks = data;
                });
                this.video.setFiles( this.asset.MultimediaVariants);
                this.file.setLinkedFile(this.asset['assetfile']);
                this.setAssetUserId();
                }
        }else{

        }
    }
     jsonCopy(src) {
        return JSON.parse(JSON.stringify(src));
      }
      ngAfterViewInit() {

        this.setCurrentItem();
        this.file.setLinkedParent(this);
    }
	 ngOnInit() {

    }

    setAssetUserId(){
        this.asset.UserId = this.currentUser._id;
        this.asset.CompanyId = this.currentCompany._id;
        this.initialAsset.UserId = this.currentUser._id;
        this.initialAsset.CompanyId = this.currentCompany._id;
    }

    setChildren(){


    }


    constructChildren(){

    }

    isDisabled(){
        return this.radioValue == "noconstrains";
    }

    getUserName(history){
        if( this.usersByCompany != undefined ){
            var user = this.usersByCompany.filter( e => e._id == history.UserId )[0];
            var firstname = user.firstname != undefined ? user.firstname : "";
            var name = user.name != undefined ? user.name : "";
            return firstname + " " + name;
        }
    }

    beforeChange($event: NgbTabChangeEvent) {
        var isMetadata = $("#" + $event.nextId).find("span").text().includes("Metadatas");
        var isMultimedia = $("#" + $event.nextId).find("span").text().includes("Multimedia");
        var isGuideline = $("#" + $event.nextId).find("span").text().includes("Guid");
        if(isMetadata){
            $("#tagList").detach().appendTo($(".dropzone").first()).show();
            $(".dz-progress").css("opacity" , "0");
        }else if(isGuideline){
            var component = this;
            var index = 0;
            var scriptValue = "";

        }else{
            $(".dz-progress").css("opacity" , "1");
            $("#tagList").detach().appendTo("#tags-container").hide();
        }
      }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('asset');
    }

    getUserNameForAll(isFirst){
        if( this.asset["HistoryBlocks"] != undefined ){
            if( this.asset["HistoryBlocks"].length > 0 ){
                if(isFirst ){
                    return this.getUserName( this.asset["HistoryBlocks"][0]);
                }else{
                    return this.getUserName( this.asset["HistoryBlocks"][this.asset["HistoryBlocks"].length - 1]);
                }
            }else{
                return "";
            }
        }else{
            return "";
        }

    }

    returnBack(){
        this.Location.back();
    }

	save(form){
        this.saveAsset(form);
    }

    getTagColor(){
        return this.tagColor;
    }
    hasModification(){
        var first = JSON.stringify(this.initialAsset);
        var second = JSON.stringify(this.asset);
        return first != second || this.allowSave;
    }
    saveAsset(form) {
        this.errors = {};
        this.tag.errors = {};
        if( this.file.file == undefined ){
            $(".dropzone").css("border", "3px solid red");
        }
        if( this.tag.checkForm() == false){
            this.tagColor = "red";
        }else{
            this.tagColor = "";
        }
		if(form.invalid || Object.keys(this.errors).length > 0 || this.tag.checkForm() == false) return;
        this.submitted = true;
        this.asset["NewFile"] = this.file.file;
        this.asset["OldFile"] = this.file.oldFile;
        if( this.multimedia != undefined ){
            this.multimedia.setMultimediaVariants();
        }
        if( this.AuthService.isRs && this.isNewAsset() || this.isUndo ){

            // Les assets doivent etre mise en ligne par l'admin customer
            this.asset.istoverify = true;
            this.asset.isApproved = false;
        }
        if( this.asset.companyuniqueid == "" ){
            this.asset.companyuniqueid = null;
        }
        if( this.isNewAsset()){
            this.returnUrl = '/toverify';
        }
        return this.AssetService.create(this.asset).subscribe((data:AssetExt) => {
            this.asset._id = data._id;
            this.addOrUpdateFile(data);
        },
        (err) => {
            this.errors = {};

            // Update validity of form fields that match the sequelize errors
            if(err.name) {
                if( err.error.errors != undefined  ){
                 this.errors["companyuniqueid"] = err.error.errors[0].message.replace(err.error.errors[0].path, "");
              }
            }
            this.submitted = false;
        }
        )
    }

    isNewAsset(){
        return this.asset._id == undefined || this.asset._id == "";
    }

    addOrUpdateFile(data:AssetExt){
        if( this.file.files == undefined ){
            this.file.files = [];
        }
        if( this.file.file["_id"] == undefined || this.file.file["_id"] == null ){
            this.file.files.push(this.file.file);
        }
        if( this.AuthService.isRs  ){
            this.multimedia.fileComponent.files.forEach(element => {
                this.file.files.push(element);
            });
        }
        this.video.upload(data.CompanyId).then((videoData) => {
           this.setVideo(data, videoData).then((sucess) => {
             this.file.addFiles("asset", this.currentCompany._id, data);
           })
        });

    }

    setVideo(data:AssetExt, videoData:object[]){
        return new Promise<any>((resolve, reject) => {
            var multimediaVariants = [];
        videoData.forEach(element => {
            for(var key in element ){
                if( element[key]["id"] != undefined ){
                    var video = new MultimediaVariant();
                    video._id = undefined;
                    video.TransferId = element[key]["id"];
                    video.isvideo = true;
                    video.digitaltype = element[key]["type"];
                    multimediaVariants.push(video);
                }
            }
        });
        if( multimediaVariants.length > 0 ){
            var historyblock = new HistoryBlock();
            historyblock.AssetId = this.asset._id;
            historyblock.UserId = this.currentUser._id;
            historyblock.bounddate = new Date();
            historyblock.iscreated = true;
            var oldVideos = this.asset.MultimediaVariants.filter( e => e.isvideo);
            if( multimediaVariants < oldVideos){
                historyblock.description = "videos : removed";
            }else if(multimediaVariants > oldVideos){
                historyblock.description = "videos : added";
            }else{
                historyblock.description = "videos : modified";
            }
            this.HistoryBlockService.create(historyblock).subscribe();
        }
        if( data.MultimediaVariants != undefined){
            var noVideoMultimedia = data.MultimediaVariants.filter( e => e.isvideo != true);
            data.MultimediaVariants = noVideoMultimedia;
            multimediaVariants.forEach(element => {
                data.MultimediaVariants.push(element);
            });
        }

            this.MultimediaVariantService.setVideoAsset(multimediaVariants, data._id).subscribe((data) => {
                resolve();
            }, (error) => {
                reject();
            });
        });

     }

    canUnapprove(asset){
        var result = false;
        if( asset._id ){
            if( asset.HistoryBlocks != undefined ){
                if( asset.HistoryBlocks.length > 0 ){
                    var history = asset.HistoryBlocks[asset.HistoryBlocks.length - 1];
                    if( history.description.includes("istoverify : true -> false")){
                        if( history.description.includes("isApproved : false -> true")){
                            result = true;
                        }
                    }
                }
            }
        }
        return result;
    }

    getHistory(history){
        return history.description.split("|").join("\n");
    }

    getAssetUser(asset){
        return;
    }

    deleteAsset(asset) {
        return this.AssetService.remove(asset._id)
            .subscribe((data) => {
                this.file.delete(data.assetfile);
            });
    }

    isDateValid(datetitle):Boolean{
        var result = false;

        return result;
    }

    daysInMonth = function (m, y) {
        switch (m) {
            case 1 :
                return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
            case 8 : case 3 : case 5 : case 10 :
                return 30;
            default :
                return 31
        }
    };

    isValidDate = function (d, m, y) {
        m = parseInt(m, 10) - 1;
        return m >= 0 && m < 12 && d > 0 && d <= this.daysInMonth(m, y);
    };
    addTagByCats(iptcData){
         this.tag.addFeatureCat(iptcData.caption);
         iptcData.keywords.forEach(element => {
            this.tag.addNewTags({"name": element, "CompanyId": this.currentCompany._id }, {"name": "Demo", "CompanyId": this.currentCompany._id});
        });
    }
    saveAndValidation(assetForm){
        this.asset.ispartialtoverify = true;
        this.save(assetForm);
    }
    approveAsset(assetForm){
        if (this.AuthService.access.approveTag){
            this.asset.isApproved = true;
            this.asset.istoverify = false;
            this.asset.ispartialtoverify = false;
            this.save(assetForm);
        }
    }

}
