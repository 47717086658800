import { NgModule, } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagListComponent } from './tag-list.component';
import { DragulaModule } from 'ng2-dragula';
import { DeviceDetectorModule } from 'ngx-device-detector';

export const ROUTES/*: Routes*/ = [
    { path: 'tag-list', component: TagListComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        BrowserModule,
        DragulaModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        NgbModule
    ],
    declarations: [
        TagListComponent,
    ],
    exports: [
        TagListComponent,
    ],
})
export class TagListModule {}
