import {Component, ElementRef, EventEmitter, Inject, Output, Renderer2, ViewChild, Input} from "@angular/core";
import {AngularEditorService} from "./angular-editor.service";
import {DOCUMENT} from "@angular/common";
import {CustomClass, Font, AngularEditorConfig} from "./config";

@Component({
  selector: 'angular-editor-toolbar',
  template: require('./angular-editor-toolbar.component.html'),
  styles: [require('./angular-editor-toolbar.component.scss')],
})

export class AngularEditorToolbarComponent {
  htmlMode = false;
  showToolbar = true;
  @Input() config: AngularEditorConfig;
  @Input() dropbox;
  block = 'default';
  defaultFontId;
  fontId = 0;
  fontSize = '5';
  fonts: Font[];

  customClassId = -1;
  customClasses: CustomClass[];
  uploadUrl:string;

  tagMap = {
    BLOCKQUOTE: "indent",
    A: "link"
  };

  select = ["H1", "H2", "H3", "H4", "H5", "H6", "P", "PRE", "DIV"];

  buttons = ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript", "justifyLeft", "justifyCenter",
    "justifyRight", "justifyFull", "indent", "outdent", "insertUnorderedList", "insertOrderedList", "link"];


  blockCode = `<label [for]="'heading'" class="block-label" tabindex="-1"></label>
  <select title="Formatting" [id]="'heading'" class="select-heading" [(ngModel)]="block"
          (change)="triggerCommand(block)"
          [disabled]="htmlMode"  tabindex="-1">
    <optgroup label="Formatting"></optgroup>
    <option class="h1" value="h1">Heading 1</option>
    <option class="h2" value="h2">Heading 2</option>
    <option class="h3" value="h3">Heading 3</option>
    <option class="h4" value="h4">Heading 4</option>
    <option class="h5" value="h5">Heading 5</option>
    <option class="h6" value="h6">Heading 6</option>
    <option class="p" value="p">Paragraph</option>
    <option class="pre" value="pre">Predefined</option>
    <option class="div" value="div">Standard</option>
    <option class="default" value="default">Default</option>
  </select>
`;
fontCode = `<label [for]="'fontSelector'" class="block-label"></label>
<select title="Font Name" [id]="'fontSelector'" class="select-font" [(ngModel)]="fontId"
        (change)="setFontName(fontId)"
        [disabled]="htmlMode" tabindex="-1">
  <optgroup label="Font Name"></optgroup>
  <option *ngFor="let item of fonts; let i = index" [class]="item.class" [value]="i">{{item.name}}</option>
 <!-- <option class="arial" value="Arial">Arial</option>
  <option class="calibri" value="Calibri">Calibri</option>
  <option class="comic-sans-ms" value="Comic Sans MS">Comic Sans MS</option>
  <option class="times-new-roman" value="Times New Roman">Times New Roman</option>-->
</select>
`;

fontSizeCode = `<label [for]="'fontSizeSelector-'" class="block-label"></label>
<select title="Font Size" [id]="'fontSizeSelector'" class="select-font-size" [(ngModel)]="fontSize"
        (change)="setFontSize(fontSize)"
        [disabled]="htmlMode" tabindex="-1">
  <optgroup label="Font Sizing"></optgroup>
  <option class="size1" value="1">1</option>
  <option class="size2" value="2">2</option>
  <option class="size3" value="3">3</option>
  <option class="size4" value="4">4</option>
  <option class="size5" value="5">5</option>
  <option class="size6" value="6">6</option>
  <option class="size7" value="7">7</option>
  <option class="size8" value="8">8</option>
  <option class="size9" value="9">9</option>
  <option class="size10" value="10">10</option>
</select>`;

textColorCode = ` <input
style="display: none"
type="color" (change)="insertColor(fgInput.value, 'textColor')"
#fgInput>
<button [id]="'foregroundColorPicker'" type="button" class="angular-editor-button" (click)="fgInput.click()" title="Text Color"
      [disabled]="htmlMode" tabindex="-1"><span class="color-label foreground"><i class="fa fa-font"></i></span></button>`;
 textBckgColor = `<input
 style="display: none"
 type="color" (change)="insertColor(bgInput.value, 'backgroundColor')"
 #bgInput>
<button [id]="'backgroundColorPicker'" type="button" class="angular-editor-button" (click)="bgInput.click()" title="Background Color"
       [disabled]="htmlMode" tabindex="-1"><span class="color-label background"><i class="fa fa-font"></i></span></button>`;

customClassCode = `<label [for]="'customClassSelector'" class="block-label"></label>
<select title="Custom Style" [id]="'customClassSelector'" class="select-custom-style" [(ngModel)]="customClassId"
        (change)="setCustomClass(customClassId)"
        [disabled]="htmlMode" tabindex="-1">
  <optgroup label="Custom Class"></optgroup>
  <option class="" value=-1>Clear Class</option>
  <option *ngFor="let item of customClasses; let i = index" [class]="item.class" [value]="i">{{item.name}}</option>
</select>`;

    tools = {
        "undo" : {"name":"undo", "command": "triggerCommand('undo')", "icon": "fa-undo", "type": "button", "title" : "Undo"},
        "redo" : {"name":"redo", "command": "triggerCommand('redo')", "icon": "fa-redo", "type": "button", "title" : "Redo"},
        "bold" : {"name":"bold", "command": "triggerCommand('bold')", "icon": "fa-bold", "type": "button", "title" : "Bold"},
        "italic" : {"name":"italic", "command": "triggerCommand('italic')", "icon": "fa-italic", "type": "button", "title" : "Italic"},
        "underline" : {"name":"underline", "command": "triggerCommand('underline')", "icon": "fa-underline", "type": "button", "title" : "Underline"},
        "strikeThrough" : {"name":"strikeThrough", "command": "triggerCommand('strikeThrough')", "icon": "fa-strikethrough", "type": "button", "title" : "Strikethrough"},
        "subscript" : {"name":"subscript", "command": "triggerCommand('subscript')", "icon": "fa-subscript", "type": "button", "title" : "Subscript"},
        "superscript" : {"name":"superscript", "command": "triggerCommand('superscript')", "icon": "fa-superscript", "type": "button", "title" : "Superscript"},
        "justifyLeft" : {"name":"justifyLeft", "command": "triggerCommand('justifyLeft')", "icon": "fa-align-left", "type": "button", "title" : "Justify Left"},
        "justifyCenter" : {"name":"justifyCenter", "command": "triggerCommand('justifyCenter')", "icon": "fa-align-center", "type": "button", "title" : "Justify Center"},
        "justifyRight" : {"name":"justifyRight", "command": "triggerCommand('justifyRight')", "icon": "fa-align-right", "type": "button", "title" : "Justify Right"},
        "justifyFull" : {"name":"justifyFull", "command": "triggerCommand('justifyFull')", "icon": " fa-align-justify", "type": "button", "title" : "Justify Full"},
        "indent" : {"name":"indent", "command": "triggerCommand('indent')", "icon": "fa-indent", "type": "button", "title" : "Indent"},
        "outdent" : {"name":"outdent", "command": "triggerCommand('outdent')", "icon": "fa-outdent", "type": "button", "title" : "Outdent"},
        "insertUnorderedList" : {"name":"insertUnorderedList", "command": "triggerCommand('insertUnorderedList')", "icon": "fa-list-ul", "type": "button", "title" : "Unordered List"},
        "insertOrderedList" : {"name":"insertOrderedList", "command": "triggerCommand('insertOrderedList')", "icon": "fa-list-ol", "type": "button", "title" : "Ordered List"},
        "link" : {"name":"link", "command": "insertUrl()", "icon": "fa-link", "type": "button", "title" : "Insert Link"},
        "unlink" :{"name":"unlink", "command": "triggerCommand('unlink')", "icon": "fa-chain-broken", "type": "button", "title" : "Unlink"},
        "horizontalLine" :{"name":"horizontalLine", "command": "triggerCommand('insertHorizontalRule')", "icon": "fa-minus", "type": "button", "title" : "Horizontal Line"},
        "clearFormatting" :{"name":"clearFormatting", "command": "triggerCommand('removeFormat')", "icon": "fa-remove", "type": "button", "title" : "Clear Formatting"},
        };
    uploadFile = {"name":"uploadFile", "icon": "fa-file-upload", "type": "button", "title" : "Upload File"};
    toolsCode = [ {"block" :{"name":"block", "code": this.blockCode}},
    {"font" :{"name":"font", "code": this.fontCode}},
    {"fontSize" :{"name":"fontSize", "code": this.fontSize}},
    {"textColor" :{"name":"textColor", "code": this.textColorCode}},
    {"textBckg" :{"name":"textBckg", "code": this.textBckgColor}},
    {"customClass" :{"name":"customClass", "code": this.customClassCode}},]

  @Output() execute: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('fileInput') myInputFile: ElementRef;

  constructor(private _renderer: Renderer2,
              private editorService: AngularEditorService, @Inject(DOCUMENT) private _document: any) {
  }

  /**
   * Trigger command from editor header buttons
   * @param command string from toolbar buttons
   */
  triggerCommand(command: string) {
    this.execute.emit(command);
  }

  /**
   * highlight editor buttons when cursor moved or positioning
   */
  triggerButtons() {
    if (!this.showToolbar) {
      return;
    }
    this.buttons.forEach(e => {
      const result = this._document.queryCommandState(e);
      const elementById = this._document.getElementById(e);
      if( elementById != undefined ){
        if (result) {
            this._renderer.addClass(elementById, "active");
          } else {
            this._renderer.removeClass(elementById, "active");
          }
      }
    });
  }


  /**
   * trigger highlight editor buttons when cursor moved or positioning in block
   */
  triggerBlocks(nodes: Node[]) {
    if (!this.showToolbar) {
      return;
    }
    let found = false;
    this.select.forEach(y => {
      const node = nodes.find(x => x.nodeName === y);
      if (node !== undefined && y === node.nodeName) {
        if (found === false) {
          this.block = node.nodeName.toLowerCase();
          found = true;
        }
      } else if (found === false) {
        this.block = 'default';
      }
    });

    found = false;
    if (this.fonts) {
      this.fonts.forEach((y, index) => {
        const node = nodes.find(x => {
          if (x instanceof HTMLFontElement) {
            return x.face === y.name;
          }
        });
        if (node !== undefined) {
          if (found === false) {
            this.fontId = index;
            found = true;
          }
        } else if (found === false) {
          this.fontId = this.defaultFontId;
        }
      });
    }

    found = false;
    if (this.customClasses) {
      this.customClasses.forEach((y, index) => {
        const node = nodes.find(x => {
          if (x instanceof Element) {
            return x.className === y.class;
          }
        });
        if (node !== undefined) {
          if (found === false) {
            this.customClassId = index;
            found = true;
          }
        } else if (found === false) {
          this.customClassId = -1;
        }
      });
    }


    Object.keys(this.tagMap).map(e => {
      const elementById = this._document.getElementById(this.tagMap[e]);
      if( elementById != undefined ){
        const node = nodes.find(x => x.nodeName === e);
        if (node !== undefined && e === node.nodeName) {
          this._renderer.addClass(elementById, "active");
        } else {
          this._renderer.removeClass(elementById, "active");
        }
      }
    });
  }


  /**
   * insert URL link
   */
  insertUrl() {
    const url = prompt("Insert URL link", 'http:\/\/');
    if (url && url !== '' && url !== 'http://') {
      this.editorService.createLink(url);
    }
  }

  /** insert color */
  insertColor(color: string, where: string) {
    this.editorService.insertColor(color, where);
    this.execute.emit("");
  }

  /**
   * set font Name/family
   * @param fontId number
   */
  setFontName(fontId: number): void {
    this.editorService.setFontName(this.fonts[fontId].name);
    this.execute.emit("");
  }

  /**
   * set font Size
   * @param fontSize string
   *  */
  setFontSize(fontSize: string): void {
    this.editorService.setFontSize(fontSize);
    this.execute.emit("");
  }

  /**
   * toggle editor mode (WYSIWYG or SOURCE)
   * @param m boolean
   */
  setEditorMode(m: boolean) {
    const toggleEditorModeButton = this._document.getElementById("toggleEditorMode");
    if (m) {
      this._renderer.addClass(toggleEditorModeButton, "active");
    } else {
      this._renderer.removeClass(toggleEditorModeButton, "active");
    }
    this.htmlMode = m;
  }

  /**
   * Upload image when file is selected
   */
  onFileChanged(event) {
         const files = event.target.files[0];
        if(this.uploadUrl){
            this.editorService.uploadFiles([files], this.config);
        } else{
          var reader = new FileReader();
          reader.onload = (_event) => {
            this.editorService.insertImage([_event.target['result']], ["inline-image"], ["Image"]);
          }
          reader.readAsDataURL(files);
        }
  }

  /**
   * Reset Input
   */
  fileReset() {
    this.myInputFile.nativeElement.value = "";
  }

  /**
   * Set custom class
   */
  setCustomClass(classId: number) {
    this.editorService.createCustomClass(this.customClasses[classId]);
  }
}
