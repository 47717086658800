import { NgModule, ModuleWithProviders } from '@angular/core';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { AuthGuardService } from './auth-guard.service';
import { CompanyService } from '../company/company.service';

@NgModule({
    providers: [
        AuthService,
        UserService,
        CompanyService,
        AuthGuardService,
    ]
})
export class AuthModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AuthModule,
            providers: [ AuthService, AuthGuardService ]
          }
    }
}
