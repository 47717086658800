import { Component, OnInit, OnDestroy } from '@angular/core';
import { CaptionService } from '../../core/services/caption/caption.service';
import { SocketService } from '../../core/services/socket/socket.service';
import {AuthService} from '../../core/services/auth/auth.service';

class Caption {
    		_id: '';
		name: '';
		iswebsite: '';
		CompanyId: '';
}

@Component({
    selector: 'caption',
    template: require('./caption.html'),
     styles: [require('./caption.scss')],
	 providers: [CaptionService]
})

export class CaptionComponent implements OnInit, OnDestroy  {
	captions: Caption[] = [];
	caption: Caption =  new Caption();;
	CaptionService;
	SocketService;
	AuthService;
	currentUser;
	newCaption = '';
	 errors: {field?: Error} = {};
    submitted = false;

	static parameters = [CaptionService, SocketService, AuthService];
    constructor(private captionService: CaptionService, private socketService: SocketService, private authService: AuthService) {
        this.CaptionService = captionService;
		 this.SocketService = socketService;
		 this.AuthService = authService;
         this.currentUser = this.AuthService.currentUser;
    }

	 ngOnInit() {
		 this.CaptionService.query()
		   .subscribe((captions: Caption[]) => {
                this.captions = captions;
                this.SocketService.syncUpdates('caption', this.captions);
            });
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('caption');
    }

	save(form){
        if( this.caption._id == undefined ){
            this.createCaption(form);
        }else{
            this.updateCaption(form);
        }
    }


    createCaption(form) {
		if(form.invalid) return;

        this.submitted = true;

            return this.CaptionService.create({
				name: this.caption.name,
		iswebsite: this.caption.iswebsite,
		CompanyId: this.caption.CompanyId,
			})
		.subscribe(err => {
                this.errors = {};

                // Update validity of form fields that match the sequelize errors
                if(err.name) {
                    err.fields.forEach(field => {
                        this.errors[field] = err.message;
                    });
                }

                this.submitted = false;
            })
    }
	updateCaption(form) {
		if(form.invalid) return;

        this.submitted = true;

            return this.CaptionService.update({
		 _id: this.caption._id,
				name: this.caption.name,
		iswebsite: this.caption.iswebsite,
		CompanyId: this.caption.CompanyId,
			})
		.catch(err => {
                this.errors = {};

                // Update validity of form fields that match the sequelize errors
                if(err.name) {
                    err.fields.forEach(field => {
                        this.errors[field] = err.message;
                    });
                }

                this.submitted = false;
            });
    }


    deleteCaption(caption) {
        return this.CaptionService.remove(caption._id)
            .subscribe(() => {
                console.log('Deleted Caption');
            });
    }

}

