import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CompanyColorComponent } from './companycolor.component';
import { ColorPickerModule } from 'ngx-color-picker';

export const ROUTES/*: Routes*/ = [
    { path: 'companycolor', component: CompanyColorComponent },
];



@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
		NgbModule,
        RouterModule.forChild(ROUTES),
        ColorPickerModule
    ],
    declarations: [
        CompanyColorComponent,
    ],
    exports: [
        CompanyColorComponent,
    ],
})
export class CompanyColorModule {}

