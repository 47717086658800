import {Asset, HistoryBlock, MultimediaVariant, Tag, TagCat, FeatureCat, StateCat, Guideline} from './types';

export class AssetExt extends Asset{
        _id : string = '';
        type : string = '' ;
        rsuniqueid : string = '' ;
        companyuniqueid : string = '' ;
        isenabled : Boolean = false ;
        fullname : string = '' ;
        assetfile : string = '' ;
        comment : string = '' ;
        begindate : Date = new Date() ;
        enddate : Date = new Date() ;
        isarchived : Boolean = false ;
        istoverify : Boolean = false ;
        isApproved : Boolean = false ;
        hasconstraint : Boolean = false ;
        ispartialtoverify : Boolean = false;
        UserId : string;
        HistoryBlocks : HistoryBlock[] = [];
        CompanyId : string;
        MultimediaVariants : MultimediaVariant[] = [];
        Guidelines : Guideline[] = [];
        InfoId : string;
        FeatureCats: FeatureCat[] = [];
        StateCats: StateCat[] = [];
        Tags: Tag[] = [];
        TagCats: TagCat[] = [];
        Children: Asset[];
        Parents: Asset[];
        Selections: Selection[];
}

if (!Array.prototype.hasOwnProperty("last")) {
    Object.defineProperty(Array.prototype, "last", {
      get: function() {
        return this[this.length - 1];
      }
    });
  }

