
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FileImporterComponent } from './file-importer.component';
import { FileModule } from '../../pages/shared-pages/file/file.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
export const ROUTES/*: Routes*/ = [
    { path: 'file-importer', component: FileImporterComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        FileModule,
        NgbModule,
        FormsModule,
        BrowserModule
    ],
    declarations: [
        FileImporterComponent,
    ],
    exports: [
        FileImporterComponent,
    ],
})
export class FileImporterModule {}
