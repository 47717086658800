
import { ConfirmationDialogService } from './../../core/services/confirm/confirm.service';
import { SelectionService } from './../../core/services/selection/selection.service';

import { Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth/auth.service';
import { MasterlineService } from './../../core/services/masterline/masterline.service';
import { CompanyService } from '../../core/services/company/company.service';
import {TourService} from 'ngx-tour-ng-bootstrap';
import { Company, User } from '../../core/models/types';
import { apiContactsEnv, transferEnv } from '../../app.constants';
import { element } from '@angular/core/src/render3';

@Component({
    selector: 'navbar',
    template: require('./navbar.html'),
    styles: [require('./navbar.scss')],
    providers: [SelectionService, ConfirmationDialogService, MasterlineService]
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
    isCollapsed = true;
    urlTransfer = transferEnv;

    Router;
    isAdmin;
    isAdminCustomer;
    isSuperAdminCustomer;
    isLoggedIn;
    currentUser = new User();
    AuthService;
    IsChoosenCustomer;
    CompanyService;
    SelectionService;
    MasterlineService;
    hasSettings;
    show:boolean = false;
    apiContactsEnv = apiContactsEnv;
    ConfirmationDialogService;
    TourService;
    Selections;
    hasRouteChanged = false;
    showHelp = false;
    static parameters = [AuthService, Router, CompanyService, TourService, SelectionService, ConfirmationDialogService, MasterlineService];
    constructor(private authService: AuthService, private router: Router, private companyService: CompanyService, private tourService: TourService, private selectionService: SelectionService, private confirmationService: ConfirmationDialogService, private masterlineService: MasterlineService ) {
        this.AuthService = authService;
        this.Router = router;
        this.SelectionService = selectionService;
        this.ConfirmationDialogService = confirmationService;
        this.MasterlineService = masterlineService;
        router.events.subscribe(val => {
            var routesToShow = ["/home", "/archived", "/toverify"];
            if( val["url"] != undefined ){
                if( routesToShow.includes(val["url"]) ){
                    this.showHelp = true;
                }else{
                    this.showHelp = false;
                }
            }

            this.hasRouteChanged = true;
          });
        this.CompanyService = companyService;
        this.TourService = tourService;
         this.reset();
          this.AuthService.currentSelectedCompanyChanged.subscribe(user => {
        this.reset();

        });


    }



    ngAfterViewInit(){

    }


    runHelp(){
        this.TourService.toggle();
        $('#sidebar').toggleClass('active');
    }

    ngOnInit(){
        this.setNavBar();
    }

    setGuideline(){
        if( this.AuthService.currentSelectedCompany.Guidelines.length > 0 ){
             var url = '/api/files/' + this.AuthService.currentSelectedCompany.Guidelines[this.AuthService.currentSelectedCompany.Guidelines.length - 1].FileId;
             var action = "var book = $('#solid-container').FlipBook({pdf:'" + url  + "', renderInactivePages: true, controlsProps: { actions: {cmdStats: {enabled:false}}}, preloadPages: 40});";
            eval.apply(this, [action]);
        }
        if( this.AuthService.currentSelectedCompany.Guidelines.length == 0 ){
            eval.apply(this, ["if ('book' in window){if( book != null && book != undefined){if(book.dispose instanceof Function){book.dispose()}}}"]);
        }
    }
    setMasterline(){
        if( this.AuthService.currentSelectedCompany.Masterlines.length > 0 ){
            var ids = this.AuthService.currentSelectedCompany.Masterlines.map(e => e._id);
            var canSeeStats = this.AuthService.access.showAllMasterLine;
            this.MasterlineService.query({_id:ids}).subscribe((data) => {
                this.AuthService.currentSelectedCompany.Masterlines = data;
                var rsIds = data.map( e => e.FileId);
                if(canSeeStats){
                    this.MasterlineService.getStatsUrl(rsIds).subscribe((rsFiles) => {
                        this.openMasterlines(data, canSeeStats, rsFiles);
                    })
                }else{
                    this.openMasterlines(data, canSeeStats, undefined);
                }

            });
        }else{
            eval.apply(this, ["if ('bookMasterline' in window){if( bookMasterline != null && bookMasterline != undefined){if(bookMasterline.dispose instanceof Function){bookMasterlines.dispose()}}}"]);
        }
    }


    openMasterlines(data, canSeeStats, rsFiles){
        var rsFilesString = "";
        if( rsFiles ){
        if( rsFiles.length > 0){
            rsFilesString = JSON.stringify(rsFiles);
            this.AuthService.currentSelectedCompany.stats = rsFilesString;
        }}
        if( data.length == 1 ){
            data.forEach(element => {
                var url = this.urlTransfer + '/api/rstransfer/' + element.FileId + "?displayType=1&access_token=" + localStorage.getItem("access_token") + "&stat";
                eval.apply(this, ["var bookMasterline = $('#masterline-container').FlipBook({pdf:'" + url  + "', renderInactivePages: true, controlsProps: { actions: {cmdStats: {enabled: " + canSeeStats + ", fileId: '" + element.name + "' , stat:'" + rsFilesString + "'}}}, preloadPages: 10});"]);
            });
        }else{
            var url = this.urlTransfer + '/api/rstransfer/' + data[0].FileId + "?displayType=1&access_token=" + localStorage.getItem("access_token") + "&stat";
            eval.apply(this, ["var bookMasterline = $('#masterline-container').FlipBook({pdf:'" + url  + "', renderInactivePages: true, controlsProps: { actions: {cmdStats: {enabled: " + canSeeStats + ", fileId: '" + data[0].name + "'  , stat:'" + rsFilesString + "'}}}, preloadPages: 10});"]);
        }
    }


    ngOnDestroy(){

    }


    toggleCollapse() {
        this.show = !this.show
    }

    _opened: boolean = false;

     _toggleSidebar() {
      this._opened = !this._opened;
    }
    removeSelection($event, item){
        this.ConfirmationDialogService.confirm("Delete selection", "Do you want to delete this selection ?")
        .then((confirmed) => {
            if( confirmed ){
                this.SelectionService.remove(item).subscribe();
                this.Selections.splice( this.Selections.indexOf(item), 1 );
                this.Router.navigate(['/home']);
            }
          })
        $event.stopPropagation();
    }
    reset() {
       this.setGuideline();
       this.setMasterline();
       this.setSelections();
        this.AuthService.isLoggedInOIDC().then( (result) => {
            if( this.AuthService.access.accessPublicity == false &&  this.AuthService.access.showMasterLine == true){
                this.router.navigate(["/masterline"]);
            }else if( this.AuthService.access.accessPublicity == false &&  this.AuthService.access.showGuideline == true){
                this.router.navigate(["/guideline-show"]);
            }
            if( result ){
                this.isLoggedIn = true;
            }
        });
    }

    changeSelectedCompany(currentCompany){
        this.AuthService.currentSelectedCompany = currentCompany;
    }
    setSelections(){
        this.Selections = this.AuthService.currentSelectedCompany.Selections;
    }
    setNavBar(){
        $('#sidebarCollapse').on('click', function () {
            $('#sidebar').toggleClass('active');
            $('#lightbox-app').toggleClass('active');
            $('#flip-book-list-container').toggleClass('active');
            $('.solid-container').toggleClass('active');
            $('.fixed-top').toggleClass('active');
            $(".icon-menuburger-deploy").toggleClass('icon-menuburger-normal');
            $('.search-modal').toggleClass('active');
        });
    }

    logout() {
        this.AuthService.logout();
    }}
