import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class HistoryBlockType {
    _id?: string;
	id?: string;
	bounddate?: string;
	description?: string;
	iscreated?: boolean;
AssetId?: string;
	UserId?: string;
	
}


@Injectable()
export class HistoryBlockService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<HistoryBlockType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/historyblocks', {params: body}) as Observable<HistoryBlockType[]>;
        }else{
            return this.http.get('/api/historyblocks') as Observable<HistoryBlockType[]>;
        }
       
    }
	queryInclude(query:{}, include:{}): Observable<HistoryBlockType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/historyblocks',  {params: body}) as Observable<HistoryBlockType[]>;
        }else{
            return this.http.get('/api/historyblocks') as Observable<HistoryBlockType[]>;
        }
       
    }
    get(id: string): Observable<HistoryBlockType> {
        return this.http.get(`/api/historyblocks/${id}`) as Observable<HistoryBlockType>;
    }
    create(historyblock: HistoryBlockType): Observable<HistoryBlockType> {
        return this.http.post(`/api/historyblocks`, historyblock);
    }
	update(historyblock: HistoryBlockType): Observable<HistoryBlockType> {
       return this.http.put(`/api/historyblocks/${historyblock.id || historyblock._id}`, historyblock);
    }
    updateInclude(historyblock: HistoryBlockType, query:{}, include:{}): Observable<HistoryBlockType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/historyblocks/${historyblock.id || historyblock._id}`,  historyblock,  {params: body}) as Observable<HistoryBlockType[]>;
        }else{
            return this.http.put(`/api/historyblocks/${historyblock.id || historyblock._id}`, historyblock) as Observable<HistoryBlockType[]>;
        }
    }
	remove(historyblock): Observable<HistoryBlockType> {
        return this.http.delete(`/api/historyblocks/${historyblock.id || historyblock._id}`);
    }
    queryRemove(query:{}): Observable<HistoryBlockType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/historyblocks',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

