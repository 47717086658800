import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagComponent } from './tag.component';
import { TagListModule } from './tag-list/tag-list.module';
import { DragulaModule } from 'ng2-dragula';
import { DeviceDetectorModule } from 'ngx-device-detector';

export const ROUTES/*: Routes*/ = [
    { path: 'tag', component: TagComponent },
];



@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        NgbModule,
        TagListModule,
        DragulaModule.forRoot(),
          DeviceDetectorModule.forRoot(),
        RouterModule.forChild(ROUTES)
    ],
    declarations: [
        TagComponent
    ],
    exports: [
        TagComponent,
    ],
})
export class TagModule {}




