import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class TagCatType {
    _id?: string;
	id?: string;
	name?: string;
	isdisplayed?: boolean;
	istagsdisplayed?: boolean;
CompanyId?: string;
	
}

	@Injectable({ providedIn: 'root' })
	export class TagCatService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<TagCatType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/tagcats', {params: body}) as Observable<TagCatType[]>;
        }else{
            return this.http.get('/api/tagcats') as Observable<TagCatType[]>;
        }
       
    }
	queryInclude(query:{}, include:{}): Observable<TagCatType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/tagcats',  {params: body}) as Observable<TagCatType[]>;
        }else{
            return this.http.get('/api/tagcats') as Observable<TagCatType[]>;
        }
       
    }
    get(id: string): Observable<TagCatType> {
        return this.http.get(`/api/tagcats/${id}`) as Observable<TagCatType>;
    }
    create(tagcat: TagCatType): Observable<TagCatType> {
        return this.http.post(`/api/tagcats`, tagcat);
    }
	update(tagcat: TagCatType): Observable<TagCatType> {
       return this.http.put(`/api/tagcats/${tagcat.id || tagcat._id}`, tagcat);
    }
    updateInclude(tagcat: TagCatType, query:{}, include:{}): Observable<TagCatType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/tagcats/${tagcat.id || tagcat._id}`,  tagcat,  {params: body}) as Observable<TagCatType[]>;
        }else{
            return this.http.put(`/api/tagcats/${tagcat.id || tagcat._id}`, tagcat) as Observable<TagCatType[]>;
        }
    }
	remove(tagcat): Observable<TagCatType> {
        return this.http.delete(`/api/tagcats/${tagcat.id || tagcat._id}`);
    }
    queryRemove(query:{}): Observable<TagCatType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/tagcats',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

