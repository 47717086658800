// Enable certain polyfills depending on which browsers you need to support

 //import 'core-js/es7';
 import 'core-js/es6/symbol';
 import 'core-js/es6/object';
 import 'core-js/es6/function';
 import 'core-js/es6/parse-int';
 import 'core-js/es6/parse-float';
 import 'core-js/es6/number';
 import 'core-js/es6/math';
 import 'core-js/es6/string';
 import 'core-js/es6/date';
 import 'core-js/es6/array';
 import 'core-js/es6/regexp';
 import 'core-js/es6/map';
 import 'core-js/es6/weak-map';
 import 'core-js/es6/set';
 import 'core-js/es6/reflect';
import 'classList.js';  // Run `npm install --save classlist.js`.
import 'web-animations-js';  // Run `npm install --save web-animations-js`.
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'reflect-metadata';
import 'zone.js/dist/zone';

(window as any).global = window;

interface IPolyFillErrorConstructor extends ErrorConstructor {
    stackTraceLimit: any;
}

if(!ENV) {
    var ENV = 'development';
}

if(ENV === 'production' || ENV === 'qa') {
    // Production
} else {
    // Development

    (<IPolyFillErrorConstructor>Error).stackTraceLimit = Infinity;
    // require('zone.js/dist/long-stack-trace-zone');
}
