import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { transferEnv } from '../../../app.constants';

class MasterlineType {
    _id?: string;
	id?: string;
	type?: string;
	name?: string;
	format?: string;
FileId?: string;
	CompanyId?: string;

}


@Injectable()
export class MasterlineService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<MasterlineType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/masterlines', {params: body}) as Observable<MasterlineType[]>;
        }else{
            return this.http.get('/api/masterlines') as Observable<MasterlineType[]>;
        }

    }
	queryInclude(query:{}, include:{}): Observable<MasterlineType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/masterlines',  {params: body}) as Observable<MasterlineType[]>;
        }else{
            return this.http.get('/api/masterlines') as Observable<MasterlineType[]>;
        }

    }
    get(id: string): Observable<MasterlineType> {
        return this.http.get(`/api/masterlines/${id}`) as Observable<MasterlineType>;
    }
    create(guideline: MasterlineType): Observable<MasterlineType> {
        return this.http.post(`/api/masterlines`, guideline);
    }
	update(guideline: MasterlineType): Observable<MasterlineType> {
       return this.http.put(`/api/masterlines/${guideline.id || guideline._id}`, guideline);
    }
    updateInclude(guideline: MasterlineType, query:{}, include:{}): Observable<MasterlineType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/masterlines/${guideline.id || guideline._id}`,  guideline,  {params: body}) as Observable<MasterlineType[]>;
        }else{
            return this.http.put(`/api/masterlines/${guideline.id || guideline._id}`, guideline) as Observable<MasterlineType[]>;
        }
    }


	remove(guideline): Observable<MasterlineType> {
        return this.http.delete(`/api/masterlines/${guideline.id || guideline._id}`);
    }
    queryRemove(query:{}): Observable<MasterlineType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/masterlines',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}

        getStatsUrl(ids): Observable<any> {
            var idsString = "";
            ids.forEach((element) => {
                idsString += "ids=" + element + "&";
            })
			return this.http.get(transferEnv + "/api/rstransfer/stat?" + idsString);
		}


}

