import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TagCatService } from '../tagcat/tagcat.service';
import { TagService } from '../tag/tag.service';
import { FeatureCatService } from '../featurecat/featurecat.service';
import { StateCatService } from '../statecat/statecat.service';



@Injectable()
export class TagManagerService {
    TagAssetService;
    TagCatService;
    TagService;
    FeatureCatService;
    FeatureCatAssetService
    StateCatService;
    StateCatAssetService;
    _assetId;
    constructor( private tagCatService: TagCatService, private featureCatService: FeatureCatService, private tagService: TagService, private stateCatService: StateCatService) {
        this.TagCatService = this.tagCatService;
        this.TagService = this.tagService;
        this.FeatureCatService = this.featureCatService;
        this.FeatureCatAssetService = this.FeatureCatAssetService;
    }

    
    set assetId(assetId){
        this._assetId = assetId;
    }


  




}

