import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GuidelineShowComponent } from './guideline-show.component';

export const ROUTES/*: Routes*/ = [
    { path: 'guideline-show', component: GuidelineShowComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
    ],
    declarations: [
        GuidelineShowComponent,
    ],
    exports: [
        GuidelineShowComponent,
    ],
})
export class GuidelineShowModule {}
