 import { SelectionService } from './../../core/services/selection/selection.service';
import { ModuleType } from 'rs-transfer';
import { AccessService } from './../../core/services/auth/access.service';
import { MultimediaVariant, Selection } from './../../core/models/types';
import { filter } from 'rxjs/operators';
import { MultimediaVariantService } from './../../core/services/multimediavariant/multimediavariant.service';

import { Component, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { AuthService } from '../../core/services/auth/auth.service';
import { AssetService } from '../../core/services/asset/asset.service';
import { TagService } from '../../core/services/tag/tag.service';
import { FeatureCatService } from '../../core/services/featurecat/featurecat.service';
import { StateCatService } from '../../core/services/statecat/statecat.service';
import {TagCatService} from '../../core/services/tagcat/tagcat.service';
import {CompanyService} from '../../core/services/company/company.service';
import { NgbdModalContent } from './search-tool-modal.component';
import { NgbdModalValidationContent } from './validation/search-tool-modal.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FileService } from '../../core/services/file/file.service';
import { CommandVisualsService } from '../../core/services/command-visuals/command-visuals.service';
import { InfoDialogService } from '../../core/services/info-dialog/info-dialog.service';
import { ConfirmationDialogService } from '../../core/services/confirm/confirm.service';
import {TourService, IStepOption} from 'ngx-tour-ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AssetExt } from '../../core/models/types.extension';
import { FileImporterComponent } from './../file-importer/file-importer.component';
import { SocketService } from '../../core/services/socket/socket.service';
import {  transferEnv } from '../../app.constants';

@Component({
    selector: 'searchTool',
    template: require('./search-tool.html'),
    providers:[FileService, CommandVisualsService, InfoDialogService, ConfirmationDialogService, SocketService, MultimediaVariantService, SelectionService],
    styles: [require('./search-tool.scss')]
})


export class SearchToolComponent implements OnInit, OnDestroy {
    @ViewChildren(FileImporterComponent) fileImporter !: QueryList<FileImporterComponent>;
    currentSearch: any;
    urlTransfer = transferEnv;
    filterMetadata = { count: 0 };
    filterInverseMetadata = { count: 0};
    AssetService;
    MultimediaVariantService
    filtered: false;
    AuthService;
    rangeOpacity = 70;
    currentCompany;
    sortName = "";
    assetWithPicture;
    _assets: AssetExt[] = [];
    originalAssets: AssetExt[] = [];
    assetsAll: AssetExt[] = [];
    allFeatures;
    allStates;
    allTags;
    checkedAssets = false;
    visualsToCommand = [];
    allTagCats;
    linkedFeatures;
    colors = {"Black": "#272424", "Golden" : "#CFB53B", "Pink" : "pink", "Red" : "red", "Yellow" : "yellow", "Green" : "green", "Purple" : "purple", "Grey" : "grey", "White" : "white", "Blue" : "blue"};
    linkedTags;
    linkedStates;
    TagService;
    FeatureCatService;
    StateCatService;
    tagCats;
    TagCatService;
    SelectionService;
    NgbModal;
    Router;
    infoAssets;
    CompanyService;
    currentUser;
    isNodesSwaped = false;
    shwoLoader = true;
    CommandVisualsService;
    ConfirmationDialogService;
    InfoDialogService;
    FileService;
    currentTest;
    searchOptions;
    include;
    isAdmin;
    isAdminCustomer;
    isSuperAdminCustomer;
    TourService;
    isCommandVisible = false;
    CookieService;
    lasScrollValue = 0;
    commandShow = true;
    isArchivedShowed = false;
    isToVerifyShowed = false;
    IsVersionShowed = false;
    modalRef;
    showProgress = false;
    valueProgress = '0';
    showVideoTags;
    IsSelection = false;
    SelectionId;
    Selection;
    AccessManager;
    IsCurrentVisual = false;
    SocketAssetService = new SocketService();
    ratioVariants;
    static parameters = [AssetService, AuthService, FeatureCatService, TagService, StateCatService, TagCatService, NgbModal, Router, CompanyService, CommandVisualsService, InfoDialogService, ConfirmationDialogService, FileService, TourService, CookieService, MultimediaVariantService, ActivatedRoute, SelectionService];
    constructor(private assetService:AssetService, private authService: AuthService, private featureCatService: FeatureCatService, private tagService: TagService, private stateCatService: StateCatService,  private tagCatService: TagCatService, private modalService: NgbModal, private router:  Router, private companyService: CompanyService, private commandVisualsService: CommandVisualsService, private infoDialog: InfoDialogService, private confirmationDialog: ConfirmationDialogService, private fileService: FileService, private tourService: TourService, private cookieService: CookieService, private multimediaService: MultimediaVariantService, private activatedRoute : ActivatedRoute, private selectionService: SelectionService) {
        this.AssetService = assetService;
        this.AuthService = authService;
        this.SelectionService = selectionService;
        this.TagService = tagService;
        this.FeatureCatService = featureCatService;
        this.StateCatService = stateCatService;
        this.TagCatService = tagCatService;
        this.NgbModal = modalService;
        this.MultimediaVariantService = multimediaService;
        this.Router = router;
        if( this.Router.url == "/archived"){
            this.isArchivedShowed = true;
        }
        if (this.Router.url == "/toverify") {
            this.isToVerifyShowed = true;
        }
        if (this.Router.url == "/isversion") {
            this.IsVersionShowed = true;
            this.commandShow = false;
        }
        if( this.Router.url == "/selection"){
            this.IsSelection = true;
        }else{
            this.IsSelection = false;
        }
        if( this.isArchivedShowed == false && this.isToVerifyShowed == false && this.IsVersionShowed == false && this.IsSelection == false ){
            this.IsCurrentVisual = true;
        }
        if( this.activatedRoute.snapshot.paramMap.get('id') != undefined ){
            this.activatedRoute.params.subscribe((data) => {
                if( this.SelectionId != undefined ){
                    this.SelectionId = data.id;
                    this.getAllAssets();
                }else{
                    this.SelectionId = data.id;
                }
            });
        }
        this.AccessManager = this.AuthService.access;
        this.CompanyService = companyService;
        this.CommandVisualsService = commandVisualsService;
        this.ConfirmationDialogService = confirmationDialog;
        this.InfoDialogService = infoDialog;
        this.FileService = fileService;
        this.TourService = tourService;
        this.CookieService = cookieService;
        this.setCurrentCompany();


    }

    set assets(assets: AssetExt[]){
        this.filterInverseMetadata.count = assets.length;
        this.originalAssets = assets;
        this.assetsAll = assets;
        this._assets = assets;
    }
    get assets(){
        return this._assets;
    }
    ngOnDestroy(){
        if( this.modalRef != undefined ){
            this.modalRef.close();
        }
   this.SocketAssetService.unsyncUpdates('asset');
    }

    ngOnInit(){
        var hasToRemove = false;
        var component = this;

        if( this.CookieService.get('filter') != undefined ){
            this.currentSearch =  this.CookieService.get('filter');
        }
        $(window).keydown(function(e){
            if( e.keyCode === 8 ){
                component.swapOnDelete(component);
            }
            if( e.keyCode === 46 ){
                component.swapOnDelete(component);
            }

            if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
                document.getElementById("typeahead-template").focus();
                e.preventDefault();
            }
        });
        this.tourService.stepShow$.subscribe((step: IStepOption) => {
            if( step.anchorId == "comand-all-tour" && this.getNbrCommand() == 0 ){
                hasToRemove = true;
                this.checkAllAssets();
                this.checkedAll();
            }else if(step.anchorId == "command-button-tour" ){

            }else if(hasToRemove == true){
                this.checkAllAssets();
                this.checkedAll();
                hasToRemove = false;
            }
          });
          this.tourService.end$.subscribe((step: IStepOption) => {
            if(hasToRemove == true){
                this.checkAllAssets();
                this.checkedAll();
                hasToRemove = false;
            }
          })
          this.TourService.initialize([{
            anchorId: 'search-tour',
            content: 'While typing here, visuals will instantly get sorted out, based on any text-matching criteria (on file name as well as any other categories). You may use special characters for triggering specific attribute or logic behavior. Be creative.',
            placement: "bottom",
            title: 'ⓘ Text-based search field',
          },
          {
            anchorId: 'sort-tour',
            content: 'This menu lists chosen categories you may choose from. Find one visual quickly and convieniently or click a more general category to sort and display a family ressemblance subset.',
            placement: "bottom",
            title: 'ⓘ Sort Menu',
          },
          {
            anchorId: 'opacity-tour',
            content: 'Set your preferred info oppacity here. Try a complete transparency for a cleaner look (infos will still visible on rollover)',
            placement: "right",
            title: 'ⓘ Opacity slider'
          },
          {
            anchorId: 'selected-infos-tour',
            content: 'Displays the number of filtered items. By default the number of non-filtered visuals',
            placement: "right",
            title: 'ⓘ Number of filtered items'
          },
          {
            anchorId: 'comand-all-tour-begin',
            content: 'Clic here to add all the sorted items to your mail request. Use with caution, your request will be thoroughly examined.',
            placement: "right",
            title: 'ⓘ Make request'
          },
          {
            anchorId: 'comand-all-tour',
            content: 'All the assets are now selected.',
            placement: "right",
            title: 'ⓘ Command all selected visuals'
          },
          {
            anchorId: 'command-button-tour',
            content: 'When 1 or more visual is selected, use this button to create a new mail in your mail app.',
            placement: "left",
            title: 'ⓘ Send mail request'
          },
        ]);

        this.getSelectedCompanies( this.currentCompany);
        $(".infos .btn-primary").click(function(e) {
            e.stopPropagation();
         });
         var component = this;
         window.onscroll = function (e) {
             var elems = $(".selected-infos");
             if( elems.length == 2 ){
                var $firstElem =  $(elems[0]);
                var $secondElem = $(elems[1]);

                if( $firstElem.offset().top > $secondElem.offset().top ){
                    if( component.isNodesSwaped == false){
                        component.swapNodes($firstElem[0], $secondElem[0]);
                        component.isNodesSwaped = true;
                    }
                 }else{
                     if(  component.isNodesSwaped == true ){
                        component.swapNodes($firstElem[0], $secondElem[0]);
                        component.isNodesSwaped = false;
                     }
                    //
                 }
             }
            }

    }


    swapNodes(a, b) {
        var aparent = a.parentNode;
        var asibling = a.nextSibling === b ? a : a.nextSibling;
        b.parentNode.insertBefore(a, b);
        aparent.insertBefore(b, asibling);
    }

    getVideo(currentAsset){
        var url = "";
        var videos = currentAsset.MultimediaVariants.filter(e => e.isvideo == true );

        if(videos.length > 0 ){
            return this.urlTransfer + '/api/rstransfer/' + videos[0].TransferId +"?displayType=2";
        }

       return "";
    }

    swapOnDelete(component){
        var textSelected = component.getSelection(document.getElementById("typeahead-template"));
        if( textSelected == component.currentSearch ){
            if( component.isNodesSwaped ){
              var elems = $(".selected-infos");
             var $firstElem =  $(elems[0]);
             var $secondElem = $(elems[1]);
             component.swapNodes($firstElem[0], $secondElem[0]);
             component.isNodesSwaped = false;
            }
        }else if($("#typeahead-template").val()['length'] == 1 ){
            if( component.isNodesSwaped ){
                var elems = $(".selected-infos");
             var $firstElem =  $(elems[0]);
             var $secondElem = $(elems[1]);
             component.swapNodes($firstElem[0], $secondElem[0]);
             component.isNodesSwaped = false;
            }
        }
    }

    editAsset(event, asset){
        this.Router.navigate(['/asset/' + asset._id], {state: {assets: this.assets, asset: asset, returnUrl: this.Router.url}});
       event.stopPropagation();
    }

    hasVideo(asset){
        return asset.MultimediaVariants.filter( e => e.isvideo ).length > 0 ;
    }
    hasMultimedia(asset){
        return asset.MultimediaVariants.filter((e) => e.isinteractive && e.isvideo != true).length > 0 ;
    }
    hasSM(asset){
        return asset.MultimediaVariants.filter((e) => e.isinteractive == false && e.isvideo != true).length > 0 ;
    }
    hasValidations(asset){
        if( asset.Validation != undefined && this.IsVersionShowed){
             return true;
        }
        return false;
    }

    getLinkedAssets(asset){
        var linked = [];
        if( asset.Children.length > 0 && asset.Parents.length > 0 ){
            linked = this.removeDuplicates(asset.Children.concat(asset.Parents), "_id");
        }else if( asset.Children.length > 0 ){
            linked =  asset.Children;
        }else if( asset.Parents.length > 0 ){
            linked =  asset.Parents;
        }
        return linked;
    }


    removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    getCheckedAssetColor(){
        return this.checkedAssets == true ? 'red' : document.querySelector('body').style.getPropertyValue('--clientColor');
    }
    checkAllAssets(){
        if( this.checkedAssets == true ){
            this.checkedAssets = false;
        }else{
            this.checkedAssets = true;
        }
    }
    getTagColor(name){
        if( this.colors[name] != undefined ){
            return this.colors[name];
        }else{
            return "var(--clientColor)";
        }
    }
    getIcon(name){
        if( this.colors[name] != undefined ){
            return true;
        }else{
            return false;
        }
    }
    archiveAsset(event, asset){
        if( asset.isarchived == true ){
            asset.isarchived = false;
        }else{
            asset.isarchived = true;
        }
        this.assets = this.assets.filter((item) => item !== asset);
        this.InfoDialogService.confirm("Archive", 'Confirm archive of asset !', 'grey', true).then((confirmed) => {
            if( confirmed ){
                this.AssetService.update(asset).subscribe();
            }
        });

        event.stopPropagation();
    }
    getTagName(name){
        if( this.colors[name] != undefined ){
            return ``;
        }else{
            return name;
        }
    }

    public ngAfterViewInit(): void
    {

    }

    deleteAsset(event, asset: AssetExt){

        this.ConfirmationDialogService.confirm(asset.fullname, 'Do you really want to remove this asset ?')
        .then((confirmed) => {
           if( confirmed){
            this.AssetService.remove(asset).subscribe(deletedAsset => {
                this.assets = this.assets.filter(e => e._id != asset._id);
            });
           }
          });
          event.stopPropagation();
    }

    // Obselete: Old code with verified button
    showVerifiedButton(asset: AssetExt) {
        if (asset.isApproved) {
            return true;
        }
    }

    getColorInfo(asset){
        if( asset["toSend"] ){
            return 'red';
        }else{
            return document.querySelector('body').style.getPropertyValue('--clientColor');
        }
    }

    getCompanyColor(){
        return document.querySelector('body').style.getPropertyValue('--clientColor');
    }
    getColor(name){
        if( this.colors[name] != undefined ){
            return this.colors[name];
        }
    }


    setCurrentCompany(){
        this.currentCompany = this.AuthService.currentSelectedCompany;
        this.AuthService.currentSelectedCompanyChanged.subscribe(company => {
                this.getSelectedCompanies(company);
        });
    }

    hasComment(asset){
        var result = false;
        if( asset.comment != ""  ){
            result = true;
        }
        return result;
    }
    getComment(asset){
        asset.comment;
    }

    chekBox(event, asset){
       if( asset["toSend"] != true ){
           asset["toSend"] = true;
       }else{
           asset["toSend"] = false;
       }
        event.stopPropagation();

    }

    checkedAll(){
        if( this.checkedAssets == false ){
            this.visualsToCommand = [];
            this.assets.forEach(element => {
                element["toSend"] = false;
            });
        }else{
            this.assets.forEach(element => {
                element["toSend"] = false;
            });
            if( this.filterMetadata.count > 0 ){
                this.filterMetadata["assets"].forEach(element => {
                    element["toSend"] = true;
                });
            }else{
                this.assets.forEach(element => {
                    element["toSend"] = true;
                });
            }

            this.visualsToCommand = this.filterMetadata["assets"];
        }
    }


    commandVisuals(){
        if(this.checkedAssets){
            this.sendCommand(this.visualsToCommand);
        }else{
            var visuals = this.assets.filter(e => { return e["toSend"] == true });
            this.sendCommand(visuals);
        }

    }

    exportVisuals(){
        if(this.checkedAssets){
            this.exportCommand(this.visualsToCommand);
        }else{
            var visuals = this.assets.filter(e => { return e["toSend"] == true });
            this.exportCommand(visuals);
        }

    }

    createSelection(selectionList){
        var visuals = this.assets.filter(e => { return e["toSend"] == true });
        var ids = visuals.map(i => i._id);
        this.NgbModal.open(selectionList).result.then((result) => {
            this.AssetService.createSelection({ids: ids, companyId: this.currentCompany._id, comment: result.comment.value, title: result.title.value}).subscribe((data) => {
                this.AuthService.currentSelectedCompany.Selections.push(data);
                this.AuthService.currentSelectedCompany = this.AuthService.currentSelectedCompany;
                this.uncheckAssets(visuals);
            });
          }, (reason) => {

          });
    }

    exportCommand(visuals){
        var ids = visuals.map(i => i._id);
        this.showProgress = true;
        var component = this;
             var interval = setInterval(function(){
                 if( component.valueProgress != '100'){
                    var valueNumber =  Number(component.valueProgress) + 3;
                    component.valueProgress = valueNumber.toString();
                 }else{
                 }
             }, 500);
        this.AssetService.export({ids: ids, companyId: this.currentCompany._id}).subscribe(result => {
            const url= window.URL.createObjectURL(result);
            var link = document.createElement("a");
            link.download = "Exported Assets";
            link.href = url;
            link.click();
            this.valueProgress = '100';
            this.uncheckAssets(visuals);
           setTimeout(function(){
                clearInterval(interval);
                component.valueProgress = '0';
                component.showProgress = false;
            }, 500);
        });
    }
    sendCommand(visuals){
        if( visuals.length > 0 ){
            this.ConfirmationDialogService.confirm("New command", "Describe your command : ", true, "Send", 'Cancel', '', '', this.currentCompany._id, ModuleType.Undefined, 'lg')
            .then((confirmed) => {
                if( confirmed != false ){

                    this.CommandVisualsService.command(visuals, this.AuthService.currentUser, this.currentCompany, confirmed).subscribe((data) => {
                        this.uncheckAssets(visuals);
                        this.checkedAssets = false;
                        this.InfoDialogService.confirm("Command of assets", 'Command successfully passed', 'green')

                    }, (err)=> {
                        this.uncheckAssets(visuals);
                        this.checkedAssets = false;
                        this.InfoDialogService.confirm("Command of assets", 'Command successfully passed', 'green')
                    });
                }
              })
            }else{
                this.InfoDialogService.confirm("Not asset cheked", 'Check assets to command', 'red')
                .then((confirmed) => {
                  })
            }
    }

    getNbrCommand(){
        var nbrToCommand = 0;
        if( this.checkedAssets){
            nbrToCommand = this.visualsToCommand.length;
        }else{
            nbrToCommand = this.assets.filter(e => { return e["toSend"] == true }).length;
        }
        return nbrToCommand;
    }

    isLinkedAssetHidden(asset){
        if( asset.Children.length == 0 && asset.Parents.length == 0 && asset.MultimediaVariants.length == 0){
            return true;
        }else{
            return false;
        }
    }

    uncheckAssets(assets){
        assets.forEach(element => {
            element.toSend = false;
        });
        }

    getSelectedCompanies(company){
            if( company._id != undefined ){
            this.currentCompany = company;
            this.getAllAssets();
            }
    }
    getAllSelectionAssets(){
        this.assets = this.assets.filter( e => e.Selections.filter( f => f._id == this.SelectionId).length > 0);
        if( this.assets.length > 0 ){
            this.Selection = this.assets[0].Selections.filter( f => f._id == this.SelectionId)[0];
        }

        this.filterInverseMetadata.count = this.assets.length;
        this.originalAssets = this.assets;
        this.assetsAll = this.assets;


    }
    getAllAssets(){

        this.searchOptions = { CompanyId: this.currentCompany._id };
        if(this.AccessManager.showArchive){

        }else{
            this.searchOptions.$or = [{isarchived: false}, {isarchived : undefined}];
        }
        if(this.AccessManager.showProcessing){

        }else{
            this.searchOptions.$or = [{isversion: false}, {isversion : undefined}];
        }

        if (this.AccessManager.verifyAsset) {

        }
        else{
            this.searchOptions.$or = [{istoverify: false}, {istoverify : undefined}];
            // default liste (home)
            this.searchOptions.istoverify = false;
            this.searchOptions.ispartialtoverify = false;
        }
        this.TagCatService.queryInclude({CompanyId: this.currentCompany._id}, ["Tags"]).subscribe((tagCats) => {
            this.tagCats = tagCats;
        })
        this.FeatureCatService.queryInclude({CompanyId: this.currentCompany._id}, ["Assets"]).subscribe((features) => {
            this.allFeatures = features;
        })
        this.StateCatService.queryInclude({CompanyId: this.currentCompany._id}, ["Assets"]).subscribe((states) => {
            this.allStates = states;
        })
        this.TagService.queryInclude({CompanyId: this.currentCompany._id}, ["Assets"]).subscribe((tags) => {
            this.allTags = tags;
        });
        var searchCatOptions = {CompanyId: this.currentCompany._id, isdisplayed: true};

        this.TagCatService.query(searchCatOptions).subscribe((tagCats) => {
            this.allTagCats = tagCats;
        })
        this.include = [{association: "Tags", include:["TagCat"]}, "FeatureCats", "StateCats", "Children", "WeFile", "Validation", "Parents", "Guidelines", "Selections", {association: "MultimediaVariants", include:["File"]}];

        if( this.IsVersionShowed ){
            this.include = [{association: "Tags", include:["TagCat"]}, "FeatureCats", "StateCats", "WeFile", "Validation", "Annotation", "Children", "Parents"];
        }
        if( this.assets == undefined ){
            this.assets = [];
        }
        this.AssetService.queryInclude(this.searchOptions, this.include).subscribe((assets) =>{
        if( assets == "Cached" ){
            assets = JSON.parse(localStorage.getItem("assets"));
        }else{
            localStorage.setItem("assets", JSON.stringify(assets));
        }
            this.assets = this.filterAssets(assets);

            if( this.SelectionId !=  undefined ){
                this.getAllSelectionAssets();
                return;
            }
         //   this.SocketAssetService.syncUpdates('asset', this.assets);
          }, (error) => {
            var test = "";
          });
    }

    openLinkedVisuals(asset){
        this.open(asset, true);
        event.stopPropagation();
    }

    filterAssets(assets){
        if(this.isArchivedShowed){
            return assets.filter( e => e.isarchived == true);
        }
        else if( this.IsVersionShowed){
            return assets.filter( e => e.isversion == true);
        }
        else if (this.isToVerifyShowed) {
            return assets.filter( e => (e.istoverify == true || e.ispartialtoverify == true) && e.isarchived == false);
//            searchOptions.istoverify = true;
        }
        else{
            return assets.filter( e => e.istoverify == false && e.isarchived == false);
        }

        return assets;
    }

    getVideoMultimediaInfos(asset){
        return new Promise<any>((resolve, reject) => {
            var videosVariants = asset.MultimediaVariants.filter( e => e.isvideo);
            var videosIds = videosVariants.map(e => e.TransferId);
            var searchMasters = this.MultimediaVariantService.getFilesInfo(videosIds).subscribe((data) => {
                data.forEach(element => {
                    var currentVideo = videosVariants.filter(e => e.TransferId == element.id)[0];
                    if( element.mediaInfos != undefined && element.mediaInfos != ""){
                        element.mediaInfos = JSON.parse(element.mediaInfos);
                        currentVideo["metadataInfo"] = element;
                    }
                });
                resolve();
            }, (error) => {
                reject();
            });
        });
    }

    open(asset, linked) {
        this.getVideoMultimediaInfos(asset).then((data) => {
            this.launchModal(asset, linked);
        }, (error) => {
            this.launchModal(asset, linked);
        });

      }

      launchModal(asset, linked){
        var modalClass = ' active';
        if($('#sidebar').hasClass('active')){
            modalClass = '';
        }
        var modal = NgbdModalContent;
        if( this.IsVersionShowed ){
            modal = NgbdModalValidationContent;
        }
        this.modalRef = this.NgbModal.open(modal, { windowClass: 'dark-modal search-modal' + modalClass });
        if( linked ){
            this.modalRef.componentInstance.readLinked = true;
        }else{
            this.modalRef.componentInstance.readLinked = false;
        }
        this.modalRef.componentInstance.tokenUser =  this.AuthService.tokenUser;
        this.modalRef.componentInstance.asset = asset;
        this.modalRef.componentInstance.Company = this.currentCompany;
        this.modalRef.componentInstance.infoAssets = this.infoAssets;
        this.modalRef.componentInstance.currentUser = this.AuthService.currentUser;
        this.modalRef.componentInstance.IsVersionShowed = this.IsVersionShowed;
        this.modalRef.componentInstance.isToVerifyShowed = this.isToVerifyShowed;
        var isParent = asset.Parents.length > 0;
        this.modalRef.componentInstance.isParent = isParent;
        if( this. filterMetadata.count == 0 ){
            this.modalRef.componentInstance.filteredListAssets = this.assets;
        }else{
            this.modalRef.componentInstance.filteredListAssets =  this.filterMetadata["assets"];
        }
      }

      changeSearch(currentSearch){
          this.currentSearch = currentSearch;
        this.checkedAll();
          if(  $("html").scrollTop() != 0  ){
            $("html").animate({ scrollTop: 0 }, "slow");
          }

        if( typeof this.currentSearch == "object"){
            this.cookieService.set( 'filter', this.currentSearch["fullname"] );
        }else{
            this.cookieService.set( 'filter', this.currentSearch );
        }

     }
    removeSearch(){
        $("html").animate({ scrollTop: 0 }, "slow");
        if( this.isNodesSwaped ){
            var elems = $(".selected-infos");
           var $firstElem =  $(elems[0]);
           var $secondElem = $(elems[1]);
           this.swapNodes($firstElem[0], $secondElem[0]);
           this.isNodesSwaped = false;
          }
        this.CookieService.set("filter", "");
        this.assets.forEach(element => {
            element["toSend"] = false;
        });
        this.sortName = "";
        this.checkedAssets = false;
        this.currentSearch = "";
        $("#typeahead-template").val("");
    }

    filterByTags($event, search){
        if( $event.metaKey == true && this.currentSearch != "" ){
            this.currentSearch = this.currentSearch + " #" + search;
            $event.stopPropagation();
        }else if($event.altKey == true && this.currentSearch != "" ){
            this.currentSearch = this.currentSearch + ", #" + search;
            $event.stopPropagation();
        }else{
            this.currentSearch = "#" + search;
            $event.stopPropagation();
        }
    }

    setTagSearch(search, sortName){
        this.currentSearch =  "#" + search;
        this.sortName = sortName;
    }

    setSearch(search, sortName){
        this.currentSearch = search;
        this.sortName = sortName;
    }

    getTagByCats(tagCat){
        if( this.allTags != undefined ){
            return this.allTags.filter(e => {
                return e.TagCatId == tagCat._id;
            })
        }
        return [];
    }

    onEnter() {

    }

    getSearchLength(){
        return this.assets.length;
    }


    getOpacity(event){
        return this.rangeOpacity / 100;
    }

    over(event){

            var children = event.target.children;
            for( var key in children ){
                if( children[key].className == "infos"){
                    children[key].style.opacity = 1;
                }
            }


    }

    leave(event){

            var children = event.target.children;
            for( var key in children ){
                if( children[key].className == "infos"){
                    children[key].style.opacity = this.getOpacity(undefined);
                }
            }

    }

    showGuideline(e){
        if( e.metaKey === true && this.currentSearch != ""){
            this.currentSearch = this.currentSearch + " " + "#Guideline";
        }else if( e.altKey === true && this.currentSearch != "" ){
            this.currentSearch =  this.currentSearch + ", " + "#Guideline";
        }else{
            this.currentSearch = "#Guideline";
        }

        e.stopPropagation();
    }
    showVideo(e){
        if( e.metaKey === true && this.currentSearch != "" ){
            this.currentSearch = this.currentSearch + " " + "#Video";
        }else if( e.altKey === true && this.currentSearch != ""){
            this.currentSearch =  this.currentSearch + ", " + "#Video";
        }else{
            this.currentSearch = "#Video";
        }

        e.stopPropagation();
    }
    showDigital(e){
        if( e.metaKey === true && this.currentSearch != "" ){
            this.currentSearch = this.currentSearch + " " + "#Digital";
        }else if( e.altKey === true && this.currentSearch != ""){
            this.currentSearch =  this.currentSearch + ", " + "#Digital";
        }else{
            this.currentSearch = "#Digital";
        }

        e.stopPropagation();
    }
    showSM(e){
        if( e.metaKey === true && this.currentSearch != "" ){
            this.currentSearch = this.currentSearch + " " + "#SocialMedia";
        }else if( e.altKey === true && this.currentSearch != ""){
            this.currentSearch =  this.currentSearch + ", " + "#SocialMedia";
        }else{
            this.currentSearch = "#SocialMedia";
        }

        e.stopPropagation();
    }
    openNewAsset(contentNewAsset) {
        const modalRef = this.modalService.open(FileImporterComponent);
        modalRef.componentInstance.isChildComponent =  true;
      }

    getSelection(textbox)
  {
     var selectedText = null;
     var activeElement = document.activeElement;

     // all browsers (including IE9 and up), except IE before version 9
     if (window.getSelection && activeElement &&
         (activeElement.tagName.toLowerCase() == "textarea" || (activeElement.tagName.toLowerCase() == "input" && activeElement["type"].toLowerCase() == "text")) &&
         activeElement === textbox)
     {    var startIndex = textbox.selectionStart;
          var endIndex = textbox.selectionEnd;

          if(endIndex - startIndex > 0)
          {
              var text = textbox.value;
              selectedText = text.substring(textbox.selectionStart, textbox.selectionEnd);
          }
     }
     else if (document["selection"] && document["selection"].type == "Text" &&  document["selection"].createRange) // All Internet Explorer
     {
           var range = document["selection"].createRange();
           selectedText = range.text;
     }

     return selectedText;
  }
}
