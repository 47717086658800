import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class GuidelineType {
    _id?: string;
	id?: string;
	type?: string;
	name?: string;
	format?: string;
FileId?: string;
	CompanyId?: string;
	
}


@Injectable()
export class GuidelineService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<GuidelineType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/guidelines', {params: body}) as Observable<GuidelineType[]>;
        }else{
            return this.http.get('/api/guidelines') as Observable<GuidelineType[]>;
        }
       
    }
	queryInclude(query:{}, include:{}): Observable<GuidelineType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/guidelines',  {params: body}) as Observable<GuidelineType[]>;
        }else{
            return this.http.get('/api/guidelines') as Observable<GuidelineType[]>;
        }
       
    }
    get(id: string): Observable<GuidelineType> {
        return this.http.get(`/api/guidelines/${id}`) as Observable<GuidelineType>;
    }
    create(guideline: GuidelineType): Observable<GuidelineType> {
        return this.http.post(`/api/guidelines`, guideline);
    }
	update(guideline: GuidelineType): Observable<GuidelineType> {
       return this.http.put(`/api/guidelines/${guideline.id || guideline._id}`, guideline);
    }
    updateInclude(guideline: GuidelineType, query:{}, include:{}): Observable<GuidelineType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/guidelines/${guideline.id || guideline._id}`,  guideline,  {params: body}) as Observable<GuidelineType[]>;
        }else{
            return this.http.put(`/api/guidelines/${guideline.id || guideline._id}`, guideline) as Observable<GuidelineType[]>;
        }
    }
	remove(guideline): Observable<GuidelineType> {
        return this.http.delete(`/api/guidelines/${guideline.id || guideline._id}`);
    }
    queryRemove(query:{}): Observable<GuidelineType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/guidelines',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

