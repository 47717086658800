// @flow
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type UserType = {
    // TODO: use Mongoose model
    id?: string;
    _id?: string;
    firstname?: string
    name?: string;
    email?: string;
    CompanyId?: string;
};

@Injectable()
export class UserService {
    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    query(): Observable<UserType[]> {
        return this.http.get('/api/users') as Observable<UserType[]>;
    }
    get(user: UserType = {id: 'me'}): Observable<UserType> {
        return this.http.get(`/api/users/${user.id || user._id}`) as Observable<UserType>;
    }
}
