/*eslint no-process-env:0*/

export var env = process.env.NODE_ENV;
var config = require(`./production.js`) || {};
export var port = config.PORT;
export var urlEnv = config.url;
export var transferEnv = config.transfer;
export var authEnv = config.auth;
export var apiContactsEnv = config.clientContact;


// List of user roles
export const userRoles = ['admin', 'super-admin-customer', 'admin-customer', 'validator', 'user', 'rs-user'];

export default {
    userRoles,
};
