export class RouteConstants {
    public static AppRouting_Archive_Page = 'archived';
    public static AppRouting_TagAdmin_PageId = "tag-admin";
    public static AppRouting_Verify_Page = 'toverify';
    public static AppRouting_Selection_Page = 'selection';
    public static AppRouting_AirTool_Page = 'air-tool';
    public static AppRouting_Guideline_PageId = "guideline-show";
    public static AppRouting_Masterline_PageId = "masterline";
    public static AppRouting_HistoryPanePageId = "history-pane";
    public static AppRouting_HistoryBlockPageId = "historyblock";
    public static AppRouting_FileImport_PageId = "file-importer";
    public static AppRouting_Processing_PageId = "processing";
}
