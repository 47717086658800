
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../core/services/auth/auth.service';
import { CompanyService } from '../../core/services/company/company.service';
import { Company } from '../../core/models/types';

import * as $ from 'jquery';

@Component({
    selector: 'guidelineShow',
    template: require('./guideline-show.html'),
    providers: [CompanyService]
    // styles: [require('./guideline-show.css')],
})
export class GuidelineShowComponent implements OnInit, OnDestroy {
    AuthService;
    CompanyService;
    currentCompany;
    Company;
    currentUser;
    static parameters = [AuthService, CompanyService];
    constructor(private authService: AuthService, private companyService: CompanyService) {
        this.AuthService = authService;
        this.CompanyService = companyService;
        this.currentUser = this.AuthService.currentUser;
    }
    ngOnInit() {
        $("#solid-container").show();

    }

    loadGuideline(){

    }

    ngOnDestroy(){
        $("#solid-container").hide();
    }

}
