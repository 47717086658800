import { Component } from '@angular/core';

@Component({
    selector: 'monitorTool',
    template: require('./monitor-tool.html'),
    // styles: [require('./monitor-tool.css')],
})
export class MonitorToolComponent {
    message/*: string*/;

    static parameters = [];
    constructor() {
        this.message = 'hello';
    }
}
