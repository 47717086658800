import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';


import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './main.component';


import { AssetModule } from '../../pages/asset/asset.module';

import { ListPaneModule } from '../../pages/list-pane/list-pane.module';
import {SearchToolModule} from '../../pages/search-tool/search-tool.module';

export const ROUTES: Routes = [
    { path: 'home', component: MainComponent },
    { path: 'archived', component: MainComponent },
    { path: 'toverify', component: MainComponent },
    { path: 'selection/:id', component: MainComponent },
    { path: 'isversion', component: MainComponent}
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AssetModule,
        ListPaneModule,
        SearchToolModule,
        RouterModule.forChild(ROUTES),
    ],
    declarations: [
        MainComponent
    ],
    exports: [
        MainComponent
    ],
})
export class MainModule {}
