import { Component, OnInit, OnDestroy } from '@angular/core';
import { HistoryBlockService } from '../../core/services/historyblock/historyblock.service';
import { SocketService } from '../../core/services/socket/socket.service';
import {AuthService} from '../../core/services/auth/auth.service';

class HistoryBlock {
    		_id: '';
		bounddate: '';
		description: '';
		iscreated: '';
		AssetId: '';
		UserId: '';
}

@Component({
    selector: 'historyBlock',
    template: require('./historyBlock.html'),
     styles: [require('./historyBlock.scss')],
	 providers: [HistoryBlockService]
})

export class HistoryBlockComponent implements OnInit, OnDestroy  {
	historyBlocks: HistoryBlock[] = [];
	historyBlock: HistoryBlock =  new HistoryBlock();;
	HistoryBlockService;
	SocketService;
	AuthService;
	currentUser;
	newHistoryBlock = '';
	 errors: {field?: Error} = {};
    submitted = false;

	static parameters = [HistoryBlockService, SocketService, AuthService];
    constructor(private historyblockService: HistoryBlockService, private socketService: SocketService, private authService: AuthService) {
        this.HistoryBlockService = historyblockService;
		 this.SocketService = socketService;
		 this.AuthService = authService;
         this.currentUser = this.AuthService.currentUser;
    }

	 ngOnInit() {
		 this.HistoryBlockService.query()
		   .subscribe((historyblocks: HistoryBlock[]) => {
                this.historyBlocks = historyblocks;
                this.SocketService.syncUpdates('historyblock', this.historyBlocks);
            });
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('historyblock');
    }

	save(form){
        if( this.historyBlock._id == undefined ){
            this.createHistoryBlock(form);
        }else{
            this.updateHistoryBlock(form);
        }
    }


    createHistoryBlock(form) {
		if(form.invalid) return;

        this.submitted = true;

            return this.HistoryBlockService.create({
				bounddate: this.historyBlock.bounddate,
		description: this.historyBlock.description,
		iscreated: this.historyBlock.iscreated,
		AssetId: this.historyBlock.AssetId,
		UserId: this.historyBlock.UserId,
			})
		.subscribe(err => {
                this.errors = {};

                // Update validity of form fields that match the sequelize errors
                if(err.name) {
                    err.fields.forEach(field => {
                        this.errors[field] = err.message;
                    });
                }

                this.submitted = false;
            })
    }
	updateHistoryBlock(form) {
		if(form.invalid) return;

        this.submitted = true;

            return this.HistoryBlockService.update({
		 _id: this.historyBlock._id,
				bounddate: this.historyBlock.bounddate,
		description: this.historyBlock.description,
		iscreated: this.historyBlock.iscreated,
		AssetId: this.historyBlock.AssetId,
		UserId: this.historyBlock.UserId,
			})
		.catch(err => {
                this.errors = {};

                // Update validity of form fields that match the sequelize errors
                if(err.name) {
                    err.fields.forEach(field => {
                        this.errors[field] = err.message;
                    });
                }

                this.submitted = false;
            });
    }


    deleteHistoryBlock(historyblock) {
        return this.HistoryBlockService.remove(historyblock._id)
            .subscribe(() => {
                console.log('Deleted HistoryBlock');
            });
    }

}

