import { Component, ViewChild, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DropzoneComponent , DropzoneDirective,
    DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { AuthService } from '../../../core/services/auth/auth.service';
import { FileService } from '../../../core/services/file/file.service';
import { Router } from '@angular/router';
import { isNumber } from 'util';
import * as EXIF from 'exif-js';
import { setMetadatas } from 'server/api/file/file.controller';
import * as ParserSubject from '../../../core/tools/parser-subject';



@Component({
    selector: 'file',
    template: require('./file.html'),
     styles: [require('./file.scss')],
     providers:[FileService]
}
)
export class FileComponent implements OnInit {
    @Input() name: string;
    @Input() fileType: string;
    @Input() displayedName: string;
    @Input() size: number;
    @Input() redirect: string;
    @Input() link: string = "/api/files";
    @Input() maxFiles;
    @Input() entity:string;
    picture = '';
    file:File;
    oldFile:File;
    parent;
    currentFile;
    assetId;
    AuthService;
    Router;
    FileService;
    currentCompany;
    uploadMultiple: false;
    initialFile;
    files: any[] = [];
    @Output() addedFiles = new EventEmitter(false);
    @Output() uploadedFiles = new EventEmitter();
    @ViewChild(DropzoneComponent) componentRef?: DropzoneComponent;
    @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective;
    fileComponent = this;

    config: DropzoneConfigInterface = {
        clickable: true,
        maxFiles: 1,
        url: this.link,
        paramName: 'files',
        maxFilesize: 5,
        resizeQuality: 1,
        timeout: 180000,
        filesizeBase: 500000,
        acceptedFiles: this.fileType,
        autoProcessQueue: false,
        autoReset: null,
        errorReset: null,
        cancelReset: null,
        resize: function(file, width, height, test){
            var srcWidth = 500;
            var srcHeight = srcWidth * file['height'] / file['width'];
            return { srcWidth: file['width'], srcHeight: file['height'], trgWidth: srcWidth, trgHeight: srcHeight }
        },
        init: function() {
            this.on("thumbnail", function(file, dataUrl) {
            $(".dz-progress").css("visibility", "hidden");
             $(".dropzone").css("border", "2px solid  rgba(0,0,0,0.3)");
             $(".dz-progress").css("z-index", "200");
             $(this.element).find(".dz-preview").on('click', function(e) {
                e.stopPropagation();
                $(this).parent(".dropzone").trigger( "click" );
            })
             var width = file['width'];
             var height = file['height'];
            if( width > height ){
                height = (100 * height ) / width;
                width = 100;
            }else{
                width = (100 * width ) / height;
                height = 100;
            }
                $(".dz-image img").css({"width":"100%", "height":"100%"});


            })

            this.on("uploadprogress", function(file, dataurl){

            })
        }
      };

    static parameters = [AuthService, FileService, Router];
    constructor(private authService:AuthService, private fileService: FileService, private router: Router) {
        this.AuthService = authService;
        this.FileService = fileService;
        this.Router = router;
        this.setCurrentCompany();

    }
    setCurrentCompany(){
        this.currentCompany = this.AuthService.currentSelectedCompany;
        this.AuthService.currentSelectedCompanyChanged.subscribe(company => {
            this.currentCompany = company;
        });
    }
    ngOnInit(){
        if( !(this.maxFiles == null || this.maxFiles == undefined )){
            this.config.maxFiles = this.maxFiles;
            if( this.maxFiles == "null"){
                this.maxFiles = null;
            }
        }else{
            this.maxFiles = 1;
        }

        this.config.url = this.link;
        this.config.acceptedFiles = this.fileType;
         this.config.maxFilesize = this.size;

    }

    setLinkedFile(id){
        this.FileService.query({_id: id}).subscribe((data)=> {
            this.currentFile = data;
            this.initialFile = data;
            this.directiveRef.dropzone().emit("addedfile", data[0]);
            this.directiveRef.dropzone().emit("thumbnail", data[0], "/api/files/" + id + "?access_token=" + localStorage.getItem('access_token'));
        });
    }

    setLinkedParent(parent){
        this.parent = parent;
    }

    getDifferences(){
        if( this.initialFile != this.currentFile ){
            return this.initialFile["name"]  + "=>" + this.currentFile["name"] + ", " + this.initialFile["size"]  + "=>" + this.currentFile["size"];
        }
        return "";
    }

    delete(id){
        this.FileService.removeById(id);
    }

    onUploadError(event) {
        var test = event;
    }


    onUploadSuccess(event){
        if( event[0] != undefined){
            if( event[0].entity == "Asset" || event[0].entity == "MultimediaVariant"){
                if( event[1] != undefined){
                    this.uploadedFiles.emit(event[1]);
                }
            }
        }
    }
    queueComplete(event){
        if( event[1] == 100 ){

        }
    }
    completeFile(event){
        this.file = event;
    }
    addedFile(event){
        var reader = new FileReader();
        if( event.type == "image/tiff"){

        }
        var component = this;
        reader.addEventListener("load", function () {
            var result = reader.result;
            EXIF.getData({src: result.toString()}, function(datas) {
                var allMetaData = EXIF.getAllTags(this);
                var iptcData = EXIF.getAllIptcTags(this);
                component.setLinkedInfos(allMetaData, iptcData);
            });
          }, false);
        if(event instanceof Blob){
            reader.readAsDataURL(event);
        }
        if( this.maxFiles == null ){
            this.setMultipleFiles(event);
        }

        if( this.file != undefined ){
            this.oldFile = this.file;
            this.addedFiles.emit("differentFile");
            if( this.maxFiles != null ){
                this.directiveRef.dropzone().removeFile(this.oldFile);
            }
        }else{
            if( this.entity == "MultimediaVariant"){
                this.addedFiles.emit("differentFile");
            }
        }
        this.file = event;
        this.file["entity"] = this.entity;
        this.addedFiles.emit(true);
    }

    setMultipleFiles(event){
          this.files.push(event);
    }

    setLinkedInfos(allMetadata, iptcData){
        if( this.parent != undefined ){
            if( this.parent.isNewAsset()){
                if( this.currentCompany.InfoAssets.length > 0 ){
                    this.setMetaDatas();
                }else{
                    var asset = this.parent.asset;
                    asset.fullname = iptcData.bylineTitle;
                    this.parent.addTagByCats(iptcData);
                }

            }
        }
    }

    setMetaDatas(){
        var tags = new ParserSubject.ParserSubject(this.file.name, this.currentCompany.InfoAssets, this.parent.tag.tags);
        this.parent.asset.fullname = tags['fullname'];
        this.parent.asset.companyuniqueid = tags['companyuniqueid'];
        this.addTag(tags['tagShortname']);
        this.addTag(tags['tagRef']);
    }

    addTag(tagShortname){
        tagShortname.forEach(element => {
            var hasnotParentTag = this.parent.asset.Tags.filter(t => t._id == element._id).length == 0;
            if( hasnotParentTag ){
                this.parent.asset.Tags.push(element);
            }
        });
    }

    updateFile(companyId?, assetId?){
        if( this.link == "" ) return;
        var name = this.name;
        var component = this;
        if( this.currentFile == undefined ){
            this.addFile(companyId);
        }
        this.directiveRef.dropzone().on("sending", function(file, xhr, formData){
            xhr.ontimeout = (() => {
                /*Execute on case of timeout only*/
                  console.log('Server Timeout')
              });
            formData.append("type", name);
            formData.append("name", file.name);
            formData.append("entity", file.entity);
            formData.append("extension", component.getExtension(file.name));
            formData.append("UserId", component.AuthService.currentUser._id);
            formData.append("date", file.lastModifiedDate );
            formData.append("dateSec", file.lastModified );
            if( component.oldFile != undefined ){
                formData.append("oldPath", component.oldFile["path"]);
            }
            if( component.currentFile._id != undefined  && component.currentFile._id != ""){
                formData.append("_id", component.currentFile._id);
            }
            if( assetId != undefined ){
                formData.append("AssetId", assetId);
            }
            if( companyId == undefined ){
                formData.append("CompanyId", component.currentCompany._id);
            }else{
                formData.append("CompanyId", companyId);
            }
        });
            return this.directiveRef.dropzone().processQueue(this.file);
    }

    addFile(companyId?, assetId?) {
        if( this.link == "" ) return;
        var name = this.name;
        var component = this;
        this.directiveRef.dropzone().on("sending", function(file, xhr, formData){
            xhr.ontimeout = (() => {
                /*Execute on case of timeout only*/
                  console.log('Server Timeout')
              });
            formData.append("type", name);
            formData.append("name", file.name);
            formData.append("extension", component.getExtension(file.name));
            formData.append("UserId", component.AuthService.currentUser._id);
            formData.append("date", file.lastModifiedDate );
            formData.append("dateSec", file.lastModified );
            if( assetId != undefined ){
                formData.append("AssetId", assetId);
            }
            if( companyId == undefined ){
                formData.append("CompanyId", this.currentCompany._id);
            }else{
                formData.append("CompanyId", companyId);
            }
        });
      return this.directiveRef.dropzone().processQueue(this.file);
    }

    addFiles(type, companyId?, asset?) {
        var name = this.name;
        var component = this;
        this.directiveRef.dropzone().uploadMultiple = true;
        this.directiveRef.dropzone().on("sending", function(file, xhr, formData){
            formData.append("type", name);
            formData.append("name", file.name);
            formData.append("extension", component.getExtension(file.name));
            formData.append("UserId", component.AuthService.currentUser._id);
            formData.append("typeUpload", type);
            formData.append("date", file.lastModifiedDate );
            formData.append("entity", file.entity);
            if(file.entity == "asset"){
                formData.append("dateSec", file.lastModified );
            }
            if( asset != undefined ){
                formData.append("asset", JSON.stringify( asset ));
            }
            if( companyId == undefined ){
                formData.append("CompanyId", this.currentCompany._id);
            }else{
                formData.append("CompanyId", companyId);
            }
        });
        if( this.files.length == 0 ){
            this.uploadedFiles.emit(true);
        }
      return this.directiveRef.dropzone().processFiles(this.files);
    }

    getExtension(filename){
        return filename.split('.').pop();
    }
}
