import {Component, OnInit, OnDestroy, Input, ViewChild} from '@angular/core'
import { TagService } from '../../../core/services/tag/tag.service';
import { SocketService } from '../../../core/services/socket/socket.service';
import {AuthService} from '../../../core/services/auth/auth.service';
import { ConfirmationDialogService } from '../../../core/services/confirm/confirm.service'
import { TagCatService } from '../../../core/services/tagcat/tagcat.service';
import { FeatureCatService } from '../../../core/services/featurecat/featurecat.service';
import { StateCatService } from '../../../core/services/statecat/statecat.service';
import { CompanyService } from '../../../core/services/company/company.service';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { DragulaService } from 'ng2-dragula';
import {Observable, Subscription, Subject, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AssetExt } from '../../../core/models/types.extension';

class Tag {
    		_id: '';
		name: '';
		TagCatId: '';
		CompanyId: '';
}

@Component({
    selector: 'tag',
    template: require('./tag.html'),
     styles: [require('./tag.scss')],
	 providers: [TagService, ConfirmationDialogService]
})

export class TagComponent implements OnInit, OnDestroy  {
    @ViewChild('instance') instance: NgbTypeahead;
    @ViewChild('instanceState') instanceState: NgbTypeahead;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    focusState$ = new Subject<string>();
    clickState$ = new Subject<string>();
    subs = new Subscription();
    @Input() asset: AssetExt;
    tags: Tag[] = [];
    TagService;
    tagCats;
    featureCats;
    stateCats;
    SocketService;
	AuthService;
    currentUser;
    newFeature:any = '';
    newState:any = '';
    currentCompany;
	newTag = '';
	errors = {};
    submitted = false;
    TagAssetService;
    TagCatService;
    DragulaService;
    FeatureCatService;
    FeatureCatAssetService
    StateCatService;
    CompanyService;
    StateCatAssetService;
    ConfirmationDialogService;
    DeviceDetectorService;


	static parameters = [SocketService, AuthService, DragulaService,  TagCatService, FeatureCatService, TagService, StateCatService, CompanyService, ConfirmationDialogService, DeviceDetectorService];
    constructor( private socketService: SocketService, private authService: AuthService, private dragulaService: DragulaService, private tagCatService: TagCatService, private featureCatService: FeatureCatService, private tagService: TagService, private stateCatService: StateCatService, private companyService: CompanyService, private confirmeService: ConfirmationDialogService, private deviceDetector : DeviceDetectorService) {
        this.TagService = tagService;
		 this.SocketService = socketService;
         this.AuthService = authService;
        this.TagCatService = tagCatService;
        this.DragulaService = dragulaService;
        this.TagService = tagService;
        this.FeatureCatService = featureCatService;
        this.StateCatService = stateCatService;
        this.CompanyService = companyService;
        this.ConfirmationDialogService = confirmeService;
        this.DeviceDetectorService = deviceDetector;
        this.currentUser = this.AuthService.currentUser;
        this.setCurrentCompany();
        }
        setCurrentCompany(){
            this.currentCompany = this.AuthService.currentSelectedCompany;
            this.setAll();
            this.AuthService.currentSelectedCompanyChanged.subscribe(company => {
                this.currentCompany = company;
                this.setAll();
            });
        }
        setAll(){
            this.CompanyService.queryInclude({"_id": this.currentCompany._id}, ["TagCats", {association: "Tags", include:["TagCat"]}, "FeatureCats", "StateCats"]).subscribe((companies) => {
                var company = companies[0];
                this.featureCats = company.FeatureCats;
                if( this.asset.FeatureCats != undefined ){
                    this.featureCats = this.featureCats.filter(e => {return this.asset.FeatureCats.map(m => m._id).includes(e._id) == false});
                }

                this.tagCats = company.TagCats;
                this.tags = company.Tags;
                if( this.asset.Tags != undefined ){
                    this.tags = this.tags.filter(e => {return this.asset.Tags.map(m => m._id).includes(e._id) == false});
                }

                this.stateCats = company.StateCats;

                if( this.asset.StateCats != undefined ){
                   this.stateCats = this.stateCats.filter(e => {return this.asset.StateCats.map(m => m._id).includes(e._id) == false});
                }

                this.setDropEvent();
            })
        }


        getTagByCats(tagCat){
            return this.tags.filter(e => {
                return e.TagCatId == tagCat._id;
            })
        }
        sendToParent(tag){
            if( this.DeviceDetectorService.isMobile()){
                this.asset.Tags.push(tag);
            }else{
            }

        }
        getValue(){
            if( this.DeviceDetectorService.isMobile()){
                return null;
            }else{
                return "bag-task1";
            }

        }


    enterFeature(event){
            var newFeature = this.newFeature;
            if( newFeature != "" ){


            var elems = this.featureCats.filter((data) => {return data.name == this.newFeature});
            if( elems.length == 0 ){
                this.ConfirmationDialogService.confirm(newFeature, "This feature dosen't exist, do you want to create it ?")
                .then((confirmed) => {
                    if( confirmed ){
                        this.addFeatureCat(newFeature);
                    }
                  })

            }else{
                elems.forEach(element => {
                    if( this.asset.FeatureCats.find(e => e.name == this.newFeature) == undefined){
                        this.asset.FeatureCats.push(element);
                        this.removeFromArray(this.featureCats, element);
                    }
                });
            }
        }
            this.newFeature = '';

        event.stopPropagation();

    }


    enterState(event){
        var newState = this.newState;
        if( newState != "" ){


        var elems = this.stateCats.filter((data) => {return data.name == this.newState});
        if( elems.length == 0 ){
            this.ConfirmationDialogService.confirm(newState, "This state dosen't exist, do you want to create it ?")
            .then((confirmed) => {
                if( confirmed ){
                    this.addStateCat(newState);
                }
              })

        }else{
            elems.forEach(element => {
                if( this.asset.StateCats.find(e => e.name == this.newState) == undefined ){
                    this.asset.StateCats.push(element);
                    this.removeFromArray(this.stateCats, element);
                }
            });
        }
      }
     this.newState = '';

      event.stopPropagation();
    }
	 ngOnInit() {

     }

     setDropEvent(){
        this.subs.add(this.dragulaService.drop("bag-task1")
        .subscribe(({ name, el }) => {
            var elems = this.tags.filter((data) => {
                var name = el["outerText"];
                if( name != undefined ){
                    if( name.charAt(0) == " "){
                        return data.name == el["outerText"].slice( 1 );
                    }
                }
                return data.name == el["outerText"];
            });
            elems.forEach(element => {
                $(el).remove();
                //TODO HALIT
                var currentTag = this.tags.filter( e => e.name == element.name)[0];
                this.asset.Tags.push(currentTag);
            });
        }));
     }

    checkForm():Boolean{
        var result = true;
        if( this.newFeature == undefined || this.newFeature == "" ){
        }else{
            if( this.featureCats.includes(this.newFeature ) == false ){
                this.errors["feature"] = "Feature not created";
                result = false;
            }
        }
        if( this.newState == undefined || this.newState == "" ){
        }else{
            if( this.stateCats.includes(this.newState) == false ){
                this.errors["state"] = "State not created. Click on Add button.";
                result = false;
            }
        }

        return result;
    }

    addFeatureCat(elem){
        return this.FeatureCatService.create({name: elem, CompanyId: this.currentCompany._id}).subscribe((data) => {
            this.asset.FeatureCats.push(data);
        }, (error) => {
            var test = error;
        });
    }
    addNewTags(elem, elemCat){
        var existingTagCat = this.tagCats.filter(e => e.name == elemCat.name);
        if( existingTagCat.length > 0 ){
            elem.TagCatId = existingTagCat[0]['_id'];
            this.TagService.create(elem).subscribe((data) => {
                this.tags.push(data);
         });
        }else{
            this.TagCatService.create(elemCat).subscribe((data) => {
                this.tagCats.push(data);
                elem.TagCatId = data._id;
                this.TagService.create(elem).subscribe((dataT) => {
                    this.tags.push(dataT);
             });
          });
        }

    }
    addStateCat(elem){
        return this.StateCatService.create({name: elem, color: "yellow", CompanyId: this.currentCompany._id}).subscribe((data) => {
            this.asset.StateCats.push(data);
        });
    }



    removeCurrentFeature(elem){
        this.featureCats.push(elem);
        this.removeFromArray(this.asset.FeatureCats, elem);
    }
    removeCurrentState(elem){
        this.stateCats.push(elem);
        this.removeFromArray(this.asset.StateCats, elem);
    }

    removeCurrentTag(elem){
        this.tags.push(elem);
        this.removeFromArray( this.asset.Tags, elem);
    }


    ngOnDestroy() {

    }

    removeFromArray(array, element){
        var index = array.indexOf(element);
        array.splice(index, 1);
    }

    searchFeatures = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(term => term === '' ?  this.featureCats.map(v => { return v.name })
                : this.featureCats.map(v => { return v.name }).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
            );
    }
    searchStates = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.clickState$.pipe(filter(() => !this.instanceState.isPopupOpen()));
        const inputFocus$ = this.focusState$;
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(term => term === '' ?  this.stateCats.map(v => { return v.name })
                : this.stateCats.map(v => { return v.name }).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
            );
    }

}

