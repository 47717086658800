import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompanyService } from '../../core/services/company/company.service';
import { SocketService } from '../../core/services/socket/socket.service';
import { ConfirmationDialogService } from '../../core/services/confirm/confirm.service';
import { FileService } from '../../core/services/file/file.service';
import { AuthService } from '../../core/services/auth/auth.service';

class Company {
    _id: '';
    name: '';
    adress: '';
    candownload: '';
    logo: '';
    background: '';
    shortname: '';
    }

@Component({
    selector: 'companyList',
    template: require('./company-list.html'),
     styles: [require('./company-list.scss')],
     providers: [CompanyService, ConfirmationDialogService, FileService]
})


export class CompanyListComponent implements OnInit, OnDestroy  {
    message/*: string*/;
    companys: Company[] = [];
    CompanyService;
    SocketService;
    ConfirmService;
    FileService;
    errors = {};
    AuthService;
    currentUser;
    logoPath;
    static parameters = [CompanyService, ConfirmationDialogService, FileService, SocketService, AuthService];
    constructor(private companyService : CompanyService, private confirmService:ConfirmationDialogService, private fileService: FileService, private socketService: SocketService, private authService: AuthService) {
        this.CompanyService = companyService;
        this.SocketService = socketService;
        this.ConfirmService = confirmService;
        this.FileService = fileService;
        this.AuthService = authService;
        this.setCurrentUser();
    }

    ngOnInit() {
        this.CompanyService.queryInclude({isActive: true}, [])
          .subscribe((companys: Company[]) => {
               this.companys = companys;
               this.SocketService.syncUpdates('company', this.companys);
           });
   }

   callComboBox(company){
    this.ConfirmService.confirm(company.name, 'Do you really want to remove this company ?')
    .then((confirmed) => {
           this.CompanyService.remove(company).subscribe(() => {
              if( this.AuthService.currentSelectedCompany._id == company._id ){
                  this.AuthService.setUserCompany();
              }
           });
      })
   }
   setCurrentUser(){
    this.currentUser = this.AuthService.currentUser;
    }
   ngOnDestroy() {
       this.SocketService.unsyncUpdates('company');
   }
}

