import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { transferEnv } from '../../../app.constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


class MultimediaVariantType {
    _id?: string;
	id?: string;
	name?: string;
	duration?: number;
	isinteractive?: boolean;
    digitaltype?: string;
    isvideo?: boolean;
	format?: string;
FileId?: string;

}


@Injectable()
export class MultimediaVariantService {
    urlTransfer = transferEnv;
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<MultimediaVariantType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/multimediavariants', {params: body}) as Observable<MultimediaVariantType[]>;
        }else{
            return this.http.get('/api/multimediavariants') as Observable<MultimediaVariantType[]>;
        }

    }
	queryInclude(query:{}, include:{}): Observable<MultimediaVariantType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/multimediavariants',  {params: body}) as Observable<MultimediaVariantType[]>;
        }else{
            return this.http.get('/api/multimediavariants') as Observable<MultimediaVariantType[]>;
        }

    }
    get(id: string): Observable<MultimediaVariantType> {
        return this.http.get(`/api/multimediavariants/${id}`) as Observable<MultimediaVariantType>;
    }
    create(multimediavariant: MultimediaVariantType): Observable<MultimediaVariantType> {
        return this.http.post(`/api/multimediavariants`, multimediavariant);
    }
	update(multimediavariant: MultimediaVariantType): Observable<MultimediaVariantType> {
       return this.http.put(`/api/multimediavariants/${multimediavariant.id || multimediavariant._id}`, multimediavariant);
    }
    setVideoAsset(multimediavariants: MultimediaVariantType[], assetId: number): Observable<any> {
        var body = {};
        body["assetId"] = assetId;
        body["variants"] = JSON.stringify(multimediavariants);
        return this.http.post(`/api/multimediavariants/video`, body);
    }
    updateInclude(multimediavariant: MultimediaVariantType, query:{}, include:{}): Observable<MultimediaVariantType[]> {
      if( query != undefined || include != undefined ){
			var body = {};
			 body["where"] = JSON.stringify(query);
             body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/multimediavariants/${multimediavariant.id || multimediavariant._id}`,  multimediavariant,  {params: body}) as Observable<MultimediaVariantType[]>;
        }else{
            return this.http.put(`/api/multimediavariants/${multimediavariant.id || multimediavariant._id}`, multimediavariant) as Observable<MultimediaVariantType[]>;
        }
    }
    getFileInfo(id): Observable<any>{
        return this.http.get(`${this.urlTransfer}/api/rstransfer/info?id=${id}`) as Observable<any>;
    }
    getFilesInfo(id: number[]): Observable<any>{
        var ids = id.join("&id=");
        return this.http.get(`${this.urlTransfer}/api/rstransfer/info?id=${ids}`) as Observable<any>;
    }
	remove(multimediavariant): Observable<MultimediaVariantType> {
        return this.http.delete(`/api/multimediavariants/${multimediavariant.id || multimediavariant._id}`);
    }
    queryRemove(query:{}): Observable<MultimediaVariantType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/multimediavariants',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

