import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TagService } from '../../../../core/services/tag/tag.service';
import { TagCatService } from '../../../../core/services/tagcat/tagcat.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { TagComponent } from '../tag.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'tagList',
    template: require('./tag-list.html'),
    styles: [require('./tag-list.scss')],
    providers:[TagCatService, TagService]
})
export class TagListComponent implements OnInit, OnDestroy {
    @Input() parentTag: TagComponent;
    message/*: string*/;
    TagService;
    TagCatService;
    currentCompany;
    AuthService;
    tagCats;
    linkedTags;
    tags;
    TagManagerService;
    DeviceDetectorService;
    Parent;
    static parameters = [TagService, TagCatService, AuthService, DeviceDetectorService];
    constructor( private tagService: TagService, private tagCatService: TagCatService, private authService: AuthService, private deviceDetectore: DeviceDetectorService) {
        this.TagService = tagService;
        this.TagCatService = tagCatService;
        this.AuthService = authService;
        this.DeviceDetectorService = deviceDetectore;
        this.Parent = parent;
    }


    ngOnInit(){

    }


    ngOnDestroy(){

    }


}
