import { FilterMultimediaInteractivePipe } from './multimedia-filter-interactive.pipe';
import { FileNamePipe } from './file-name.pipe';
import { SecurePipe } from './secure.pipe';
import { FileSizePipe } from './file-size.pipe';
import { PrettyPrintPipe } from './json-perttyprint.pipe';
import { FilterMultimediaVideoPipe } from './multimedia-filter-video.pipe';
import { NgModule } from '@angular/core';
import { VideoTypePipe } from './video-type.pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    FilterMultimediaVideoPipe,
    PrettyPrintPipe,
    FileSizePipe,
    VideoTypePipe,
    SecurePipe,
    FileNamePipe,
    FilterMultimediaInteractivePipe
  ],
  exports: [
    FilterMultimediaVideoPipe,
    PrettyPrintPipe,
    FileSizePipe,
    VideoTypePipe,
    SecurePipe,
    FileNamePipe,
    FilterMultimediaInteractivePipe
  ]
})

export class MultimediaFilterVideoModule {}
