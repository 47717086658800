import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class StateCatType {
    _id?: string;
	id?: string;
	name?: string;
	color?: string;
CompanyId?: string;
	
}

	@Injectable({ providedIn: 'root' })
	export class StateCatService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<StateCatType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/statecats', {params: body}) as Observable<StateCatType[]>;
        }else{
            return this.http.get('/api/statecats') as Observable<StateCatType[]>;
        }
       
    }
	queryInclude(query:{}, include:{}): Observable<StateCatType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/statecats',  {params: body}) as Observable<StateCatType[]>;
        }else{
            return this.http.get('/api/statecats') as Observable<StateCatType[]>;
        }
       
    }
    get(id: string): Observable<StateCatType> {
        return this.http.get(`/api/statecats/${id}`) as Observable<StateCatType>;
    }
    create(statecat: StateCatType): Observable<StateCatType> {
        return this.http.post(`/api/statecats`, statecat);
    }
	update(statecat: StateCatType): Observable<StateCatType> {
       return this.http.put(`/api/statecats/${statecat.id || statecat._id}`, statecat);
    }
    updateInclude(statecat: StateCatType, query:{}, include:{}): Observable<StateCatType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/statecats/${statecat.id || statecat._id}`,  statecat,  {params: body}) as Observable<StateCatType[]>;
        }else{
            return this.http.put(`/api/statecats/${statecat.id || statecat._id}`, statecat) as Observable<StateCatType[]>;
        }
    }
	remove(statecat): Observable<StateCatType> {
        return this.http.delete(`/api/statecats/${statecat.id || statecat._id}`);
    }
    queryRemove(query:{}): Observable<StateCatType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/statecats',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

