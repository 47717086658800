import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SearchPaneComponent } from './search-pane.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

export const ROUTES/*: Routes*/ = [
    { path: 'search-pane', component: SearchPaneComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        CommonModule,
        BrowserModule,
        FormsModule,
        NgbModule.forRoot()
    ],
    declarations: [
        SearchPaneComponent,
    ],
    exports: [
        SearchPaneComponent,
    ],
})
export class SearchPaneModule {}
