import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { InfoDialogComponent } from '../../../ui/info-dialog/info-dialog.component';

@Injectable()
export class InfoDialogService {

  constructor(private modalService: NgbModal) { }

 confirm(
    title: string,
    message: string,
    color: string,
    hasConfirmation: Boolean = false,
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(InfoDialogComponent, { size: dialogSize });
    modalRef.componentInstance.color = color;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.hasConfirmation = hasConfirmation;
    modalRef.componentInstance.message = message;

    return modalRef.result;
  }

}
