import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HistoryPaneComponent } from './history-pane.component';
import { CommonModule } from '@angular/common';

export const ROUTES/*: Routes*/ = [
    { path: 'history-pane', component: HistoryPaneComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        CommonModule,
        BrowserModule
    ],
    declarations: [
        HistoryPaneComponent,
    ],
    exports: [
        HistoryPaneComponent,
    ],
})
export class HistoryPaneModule {}
