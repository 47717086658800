export class ParserSubject{
    fileName;
    infoAssets;
    tags;
    metainfos;
    constructor(fileName, infoAssets, tags){
        this.fileName = fileName || '';
        this.infoAssets = infoAssets || {};
        this.tags = tags || {};
        this.metainfos = {};
        this.parseFileInfos();
        return this.metainfos;
    }
    parseFileInfos(){
        console.log(this.fileName);
        var fileName = this.fileName.replace("." + this.getExtension(this.fileName), '');
        var infos = fileName.split('_');
        var company = infos.slice(-1).pop();
        infos.pop();
        var fullName = '';
        var ref = '';
        infos.forEach(element => {
            var elemArray = element.split('.');
            var idClient;
            if(  elemArray.length > 1 ){
                idClient = elemArray;
                ref = element;
            }else{
                fullName = fullName + " " + element;
            }
        });
        this.metainfos.fullname = fullName;
        this.metainfos.companyuniqueid = ref;
        this.getMetadatas();

    }

    getExtension(fileName){
        console.log(fileName);
        return fileName.split('.').pop();
    }

    getMetadatas(){
        var infos = this.infoAssets;
        var parsedInfos = this.parseInfos(infos);
        var tagsToAdd = [];
        var currentTags = this.tags;
        var tagShortname = currentTags.filter( t => t.name == parsedInfos["shortname"]);
        var tagRef = currentTags.filter( t => t.name == parsedInfos["ref"]);
        var result = {};
        this.metainfos.tagShortname = tagShortname;
        this.metainfos.tagRef = tagRef;
    }

    parseInfos(infos){
        var parsedInfos = {};
        if( !(this.metainfos.companyuniqueid == null)){
            var keys = this.metainfos.companyuniqueid.split(".");
            parsedInfos = {};
            parsedInfos["collection"] = JSON.parse(infos[0]["collection"])[keys[0][0]];
            parsedInfos["casebezel"] = JSON.parse(infos[0]["casebezel"])[keys[1]];
            var dialKey = keys[2][0] == "0" ? keys[2].substring(1, 4) : keys[2];
            parsedInfos["dial"] = JSON.parse(infos[0]["dial"])[dialKey];
            parsedInfos["strap"] = JSON.parse(infos[0]["strap"])[keys[3]];
            parsedInfos["shortname"] = JSON.parse(infos[0]["shortname"])[keys[0][0]];
            parsedInfos["type"] = JSON.parse(infos[0]["type"])[keys[0]];
            parsedInfos["ref"] =  keys[0];
            if( keys.length > 5){
                parsedInfos["setting"] = JSON.parse(infos[0]["setting"])[keys[4]];
                parsedInfos["specific"] = JSON.parse(infos[0]["specific"])[keys[5].substring(0, 3)];
            }else if( keys.length > 4){
                if( isNaN( keys[4] ) == false ){
                    parsedInfos["setting"] = JSON.parse(infos[0]["setting"])[keys[4]];
                }else{
                    parsedInfos["specific"] = JSON.parse(infos[0]["specific"])[keys[4].substring(0, 3)];
                }
            }
        }
        return parsedInfos;
    }
}
