/*eslint no-process-env:0*/

// Production specific configuration
// =================================
module.exports = {
    // Server IP
    ip: process.env.OPENSHIFT_NODEJS_IP
        || process.env.ip
        || undefined,

    // Server port
    port: process.env.OPENSHIFT_NODEJS_PORT
        || process.env.PORT
        || 4000,
        transfer: "https://transfer.rs-solutions.ch",
        url: "https://rs-lightbox.ch",
        clientContact: "https://contacts.rs-solutions.ch",
        apiContact: "https://contactsapi.rs-solutions.ch",
        officialMail: "mail@rs-solutions.ch",
        auth: "https://auth.rs-solutions.ch",
        sequelize: {
            host: 'kwor.myd.infomaniak.com',
            username: 'kwor_lbappuser',
            password: 'carminoDL207',
            database: 'kwor_lightbox_app',
            dialect: 'mysql',
            port: '3306'
    },
};
