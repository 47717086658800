import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RetouchComponent } from './retouch.component';
import { FormsModule } from '@angular/forms';
import { ResizableModule } from '../../../ui/resizeable/resizable.module';
import { AngularEditorModule } from '../../../ui/editor/angular-editor.module';
import { NgMagicIframeModule } from '@sebgroup/ng-magic-iframe';

export const ROUTES/*: Routes*/ = [
    { path: 'retouch', component: RetouchComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        CommonModule,
        FormsModule,
        ResizableModule,
        AngularEditorModule,
        NgMagicIframeModule
    ],
    declarations: [
        RetouchComponent,
    ],
    exports: [
        RetouchComponent,
    ],
})
export class RetouchModule {}
