import { ModuleType } from 'rs-transfer';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationDialogComponent } from '../../../ui/confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }

 confirm(
    title: string,
    message: string,
    hasInput: boolean = false,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    body: string = '',
    email: string = '',
    companyId: string = '0',
    actionType: ModuleType = ModuleType.Undefined,
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize, backdrop : 'static',
    keyboard : false });
    modalRef.componentInstance.hasInput = hasInput;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.body = body;
    modalRef.componentInstance.companyId = companyId;
    modalRef.componentInstance.actionType = actionType;

    modalRef.componentInstance.email = email;

    return modalRef.result;
  }

}
