import { UserService } from './../../core/services/auth/user.service';
import { User } from './../../core/models/types';

import { CompanyColorService } from './../../core/services/companycolor/companycolor.service';
import { MasterlineService } from './../../core/services/masterline/masterline.service';
import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { CompanyService } from '../../core/services/company/company.service';
import { SocketService } from '../../core/services/socket/socket.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FileComponent } from '../shared-pages/file/file.component';
import { CompanyColor, Company, Masterline } from '../../core/models/types';
import { GuidelineComponent } from './../asset/guideline/guideline.component';
import '../../core/models/types.extension';
import { FileDto, ApplicationType, ModuleType } from 'rs-transfer';
import { Subject, Observable } from 'rxjs';
import {  transferEnv } from '../../app.constants';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

@Component({
    selector: 'company',
    template: require('./company.html'),
    styles: [require('./company.scss')],
    providers: [CompanyService, CompanyColorService, MasterlineService]
})

export class CompanyComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(GuidelineComponent) guidelineComponent: GuidelineComponent;
    @ViewChildren(FileComponent) fileComponents: QueryList<FileComponent>;
    urlTransfer = transferEnv;
    companys: Company[] = [];
    company: Company = new Company();
    CompanyService;
    SocketService;
    CompanyColorService;
    MasterlineService;
    AuthService;
    Router;
    currentUser;
    activatedRoute;
    newCompany = '';
    errors = {};
    submitted = false;
    tokenUser = '';
    newIds = [];
    allIds = [];
    UserService;
    files: FileDto[] = [];
    users;
    userNames;
    uploadFiles: Subject<any> = new Subject();
    urlInfos = {
      ApplicationId: ApplicationType.Lightbox,
      AccessToken : localStorage.getItem('access_token'),
      Url: this.urlTransfer,
      ReturnFile: true,
      GenerateThumb: true,
      Module : ModuleType.MasterLine,
      texts: {
        afterUploadMsg_success: 'Successfully Uploaded !',
        afterUploadMsg_error: 'Upload Failed !',
        title: 'Dropzone',
      }
    };

    static parameters = [CompanyService, SocketService, AuthService, Router, ActivatedRoute, CompanyColorService, MasterlineService, UserService];
    constructor(private companyService: CompanyService, private socketService: SocketService, private authService: AuthService, private router: Router, private route: ActivatedRoute, private companyColorService: CompanyColorService, private masterLineService: MasterlineService, private userService: UserService) {
        this.CompanyService = companyService;
        this.SocketService = socketService;
        this.AuthService = authService;
        this.activatedRoute = route;
        this.Router = router;
        this.MasterlineService = masterLineService;
        this.CompanyColorService = companyColorService;
        this.tokenUser = this.AuthService.tokenUser;
        this.UserService = userService;
        this.setCurrentUser();
    }

    setCurrentUser() {
        this.currentUser = this.AuthService.currentUser;
    }

    ngAfterViewInit() {
    }

    ngOnInit() {

        if( this.company.CompanyColors.length == 0 ){
            this.company.CompanyColors = [];
            this.company.CompanyColors.push(new CompanyColor());
        }
        this.UserService.query().subscribe((data) => {
            this.users = data;
            this.userNames = this.users.map( e => e.email);
        });
        this.CompanyService.queryInclude({ "_id": this.activatedRoute.snapshot.paramMap.get('id') }, ["CompanyColors", "InfoAssets", {association: "Guidelines", include:["File"]}, {association: "Masterlines", include:["Users"]}]).subscribe((company) => {
            if (company[0].CompanyColors.length == 0) {
                company[0].CompanyColors.push(new CompanyColor());
            }

            this.company = company[0];
            this.company.Guidelines = [this.company.Guidelines["last"]];

            if( this.company.Masterlines.length > 0 ){
                this.company.Masterlines.forEach(element => {
                    var file = new FileDto();
                    file.id = Number(element.FileId);
                    this.allIds.push(element._id);
                    this.files.push(file);
                });
             }
            var logoId = this.company.logo;
            var backgroundId = this.company.background;
            if( this.guidelineComponent != undefined ){
                this.guidelineComponent.setCompanyGuideline(this.company);
            }
            if (logoId != null) {
                if( this.fileComponents != undefined ){
                    this.fileComponents.first.setLinkedFile(logoId);
                }
            }
            if (backgroundId != null) {
                if( this.fileComponents != undefined ){
                    this.fileComponents.last.setLinkedFile(backgroundId);
                }
            }
        })
    }
    formatter = (result: string) => result.email;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.users.filter(v => v.email.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
    changeUser(event, masterline, masterlineInput){
        masterline.Users = masterline.Users != undefined ? masterline.Users : [];
        masterline.Users.push(event.item);
        masterlineInput.value = "";
        event.preventDefault();

    }
    removeUser(masterline, user){
        masterline.Users = masterline.Users.filter(function( obj ) {
            return obj.email !== user.email;
        });
    }
    ngOnDestroy() {
       // this.SocketService.unsyncUpdates('company');
    }

    save(form) {
        this.updateCompany(form);
    }

    onSelect(event){
        var actualFiles = event.actualFiles;
        var newMasterlines = [];
        actualFiles.forEach(element => {
            var currentFile = this.company.Masterlines.filter( e => e.name == element.name);
            if( currentFile.length > 0 ){
              newMasterlines.push(currentFile[0]);
            }else{
                var masterline = new Masterline();
                masterline.FileId = element.id;
                masterline.name = element.name;
                masterline.format = element.type;
                masterline.CompanyId = this.company._id;
                masterline.type = "Company";
                newMasterlines.push(masterline);
            }
        });
        this.company.Masterlines = newMasterlines;
    }

    updateCompany(form) {
        if (this.checkForm(form) == false) {
            return;
        }
        if (form.invalid) return;

        this.submitted = true;
        var companyComponent = this;
        return this.CompanyService.update(this.company)
            .subscribe(
                (editedCompany) => {
                    if( this.company.CompanyColors[0]._id == undefined || this.company.CompanyColors[0]._id == ""){
                        this.company.CompanyColors[0].CompanyId = this.company._id;
                        this.CompanyColorService.create(this.company.CompanyColors[0]).subscribe();
                    }else{
                        this.company.CompanyColors[0].CompanyId = this.company._id;
                        this.CompanyColorService.update(this.company.CompanyColors[0]).subscribe();
                    }
                    this.fileComponents.forEach(com => {
                        com.updateFile(this.company._id);
                    });
                    this.guidelineComponent.fileComponent.updateFile(companyComponent.company._id);
                    this.urlInfos["CompanyId"] = this.company._id;
                    this.uploadFiles.next(true);
                    this.uploadFiles.subscribe((data) => {
                        if( data.length >= 0 && (data.length == companyComponent.files.length)){
                                var promises = [];
                                if( data instanceof Array ){
                                    data.forEach(element => {
                                        var masterlines = this.company.Masterlines.filter( e => e.name == element.name);
                                        if( masterlines.length > 0 && masterlines[0]._id != "" ){
                                            var masterline = masterlines[0];
                                            masterline.FileId = element.id;
                                            masterline.format = element.type;
                                            masterline.type = "Company";
                                            this.newIds.push(masterline._id);
                                            promises.push(this.MasterlineService.update(masterline).toPromise());
                                        }else{
                                            var masterline = new Masterline();
                                            masterline.FileId = element.id;
                                            masterline.format = element.type;
                                            masterline.type = "Company";
                                            masterline.name = element.name;
                                            masterline.CompanyId = this.company._id;
                                            promises.push(this.MasterlineService.create(masterline).toPromise());
                                        }
                                });
                                }
                        }
                        Promise.all(promises).then((data) => {
                            companyComponent.removeOldMasterlines().then((data) => {
                                alert("Successfully update !");
                            });
                        })
                    });

                },
                (err) => {
                    this.errors = {};

                    // Update validity of form fields that match the sequelize errors
                    if (err.name) {
                        this.errors["Company"] = err.error.errors[0].message.replace(err.error.errors[0].path, "");
                    }
                });
    }

    docUpload(event){
        var test = "";
    }
    removeOldMasterlines(){
        var idsToRemove = [];
        this.allIds.forEach(element => {
            if( this.newIds.includes(element)){

            }else{
                idsToRemove.push(element);
            }
        });
        var promises = [];
    idsToRemove.forEach(element => {
        promises.push(  this.MasterlineService.queryRemove({"type":"Company", "_id":element}).toPromise());
    });
    return Promise.all(promises);
    }
    checkForm(form) {
        this.errors = {};
        if (this.fileComponents.first.file == undefined) {
            this.errors["Company"] = this.fileComponents.first.name + " " + " needs file";
            return false;
        } else if (this.fileComponents.last.file == undefined) {
            this.errors["Company"] = this.fileComponents.last.name + " " + " needs file";
            return false;
        } else {
            return true;
        }
    }

}

