import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { FileComponent } from '../../pages/shared-pages/file/file.component';
import { AuthService } from '../../core/services/auth/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fileImporter',
    template: require('./file-importer.html'),
    providers: [NgbActiveModal]
    // styles: [require('./file-importer.css')],
})
export class FileImporterComponent implements OnInit {
    @ViewChild(FileComponent) file: FileComponent;
    @Input() isChildComponent = false;
    AuthService;
    currentCompany;
    currentUser;
    comment;
    NgbActiveModal;

    static parameters = [AuthService, NgbActiveModal];
    constructor(private authService: AuthService, activeModal: NgbActiveModal) {
        this.AuthService = authService;
        this.NgbActiveModal = activeModal;
        this.currentUser = this.AuthService.currentUser;
        this.setCurrentCompany();
    }
    setCurrentCompany(){
        this.currentCompany = this.AuthService.currentSelectedCompany;
        this.AuthService.currentSelectedCompanyChanged.subscribe(company => {
            this.currentCompany = company;
        });
    }

    ngOnInit(){

    }

    uploadFiles(){
        this.file.files.push(this.file.file);
        this.file.addFiles("import", this.currentCompany._id);
    }
    uploadValidation(){
        alert("ok");
    }
}
