import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FileComponent } from './file.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';


export const ROUTES/*: Routes*/ = [
    { path: 'file', component: FileComponent },
];

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    // Change this to your upload POST address:
     
   };

@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        DropzoneModule
    ],
    declarations: [
        FileComponent,
    ],
    providers: [
        {
          provide: DROPZONE_CONFIG,
          useValue: DEFAULT_DROPZONE_CONFIG
        }
      ],
    exports: [
        FileComponent,
    ],
})
export class FileModule {}
