import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {CustomClass} from "./config";

export interface UploadResponse {
  imageUrls: [string];
  imageTypes: [string];
  imageNames: [string];
}

@Injectable({
  providedIn: 'root'
})
export class AngularEditorService {

  savedSelection: Range | null;
  selectedText: string;
  uploadUrl: string;
  acceptedFormat = ['application/pdf', 'application/rtf', 'application/zip', 'application/postscript', 'application/vnd.apple.numbers', 'application/vnd.ms-powerpoint',  'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', '	application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "image/bmp",
  "image/cis-cod",
  "image/gif",
  "image/ief",
  "image/jpeg",
  "image/png",
  "image/pipeg",
  "image/svg+xml",
  "image/tiff",
  "image/x-cmu-raster",
  "image/x-cmx",
  "image/x-icon",
  "image/x-portable-anymap",
  "image/x-portable-bitmap",
  "image/x-portable-graymap",
  "image/x-portable-pixmap",
  "image/x-rgb",
  "image/x-xbitmap",
  "image/x-xpixmap",
  "image/x-xwindowdump"	];

  constructor(private http: HttpClient, @Inject(DOCUMENT) private _document: any) {
  }

  /**
   * Executed command from editor header buttons exclude toggleEditorMode
   * @param command string from triggerCommand
   */
  executeCommand(command: string) {
    const commands = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre'];
    if (commands.includes(command)) {
      this._document.execCommand('formatBlock', false, command);
    }

    this._document.execCommand(command, false, null);
  }

  /**
   * Create URL link
   * @param url string from UI prompt
   */
  createLink(url: string) {
    if (!url.includes("http")) {
      this._document.execCommand('createlink', false, url);
    } else {
      const newUrl = '<a href="' + url + '" target="_blank">' + this.selectedText + '</a>';
      this.insertHtml(newUrl);
    }
  }

  /**
   * insert color either font or background
   *
   * @param color color to be inserted
   * @param where where the color has to be inserted either text/background
   */
  insertColor(color: string, where: string): void {
    const restored = this.restoreSelection();
    if (restored) {
      if (where === 'textColor') {
        this._document.execCommand('foreColor', false, color);
      } else {
        this._document.execCommand('hiliteColor', false, color);
      }
    }
  }

  /**
   * Set font name
   * @param fontName string
   */
  setFontName(fontName: string) {
    this._document.execCommand("fontName", false, fontName);
  }

  /**
   * Set font size
   * @param fontSize string
   */
  setFontSize(fontSize: string) {
    this._document.execCommand("fontSize", false, fontSize);
  }

  /**
   * Create raw HTML
   * @param html HTML string
   */
  private insertHtml(html: string): void {

    const isHTMLInserted = this._document.execCommand('insertHTML', false, html);

    if (!isHTMLInserted) {
      throw new Error('Unable to perform the operation');
    }
  }

  /**
   * save selection when the editor is focussed out
   */
  saveSelection(): any {
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        this.savedSelection = sel.getRangeAt(0);
        this.selectedText = sel.toString();
      }
    } else if (this._document.getSelection && this._document.createRange) {
      this.savedSelection = document.createRange();
    } else {
      this.savedSelection = null;
    }
  }

  /**
   * restore selection when the editor is focussed in
   *
   * saved selection when the editor is focussed out
   */
  restoreSelection(): boolean {
    if (this.savedSelection) {
      if (window.getSelection) {
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(this.savedSelection);
        return true;
      } else if (this._document.getSelection /*&& this.savedSelection.select*/) {
        // this.savedSelection.select();
        return true;
      }
    } else {
      return false;
    }
  }



  /** check any slection is made or not */
  private checkSelection(): any {

    const slectedText = this.savedSelection.toString();

    if (slectedText.length === 0) {
      throw new Error('No Selection Made');
    }

    return true;
  }

  /**
   * Upload file to uploadUrl
   * @param file
   */
  uploadFiles(files: [File?], config) {
    $("#editor")[0].focus();
    const uploadData: FormData = new FormData();
    var refusedFiles = [];
    files.forEach(element => {
        var extension = element.type;
        if( config.refusedMimes != [''] && config.refusedMimes != undefined){
            this.acceptedFormat = this.acceptedFormat.filter( (value) => { return config.refusedMimes.includes(value) == false });
        }
        if( this.acceptedFormat.includes(extension) ){
            if( config.acceptedMimes != [''] && config.acceptedMimes != undefined ){
                if(  config.acceptedMimes.includes(extension)){
                    uploadData.append('files', element, element.name);
                }
            }else{
                uploadData.append('files', element, element.name);
            }
        }else{
           //refusedFiles.push( `<div class="image-textarea"><i class="fas fa-file-excel fa-2x"></i> Format File Refused ${element.name } </div>` );
        }
    });
    if( refusedFiles.length > 0 ){
        this._document.execCommand('insertHTML', false, '<br>' + refusedFiles + '<br>');
    }
    return this.http.post<UploadResponse>(this.uploadUrl, uploadData, {
      reportProgress: true
    }).subscribe(e => {
               this.insertImage(e.imageUrls, e.imageTypes, e.imageNames);
    });
  }


  /**
   * Insert image with Url
   * @param imageUrl
   */
  insertImage(imageUrls: [string | ArrayBuffer ], imageTypes: [string], imageNames:[string]) {
      var code = '';
      for( var i = 0; i < imageUrls.length; i++ ){
          if( imageTypes[i] == "image" ){
            var imageThumb = imageUrls[i].split('/');
            var lastPath = imageThumb[imageThumb.length - 1];
            var newIconPath = 'icon' + '-' + lastPath;
            code = code + `<div class="image-textarea-bloc" contenteditable="false">
            <img src="${"api/files/comment/"}${newIconPath}"></img>
            <div class="image-textarea-title" contenteditable="false">${imageNames[i]}
            <div class="image-textarea-actions"><a href="${imageUrls[i]}" target="_blank"><i class="fas fa-expand-arrows-alt"></i></a><a href="${imageUrls[i]}" download="${imageNames[i]}"><i class="fas fa-cloud-download-alt"></i></a><i class="fas fa-trash-alt" style="cursor: pointer" onclick="this.parentNode.parentNode.parentNode.parentNode.removeChild(this.parentNode.parentNode.parentNode);"></i></div>
            </div>
            </div><br>`;
          }else if(imageTypes[i] == "inline-image"){
            code = code + `<div class="image-textarea-bloc" contenteditable="false">
            <img width="100px" height="auto" src="${imageUrls[i]}"></img>
            <div class="image-textarea-title" contenteditable="false">${imageNames[i]}
            <div class="image-textarea-actions"><a href="${imageUrls[i]}" target="_blank"><i class="fas fa-expand-arrows-alt"></i></a><a href="${imageUrls[i]}" download="${imageNames[i]}"><i class="fas fa-cloud-download-alt"></i></a><i class="fas fa-trash-alt" style="cursor: pointer" onclick="this.parentNode.parentNode.parentNode.parentNode.removeChild(this.parentNode.parentNode.parentNode);"></i></div>
            </div>
            </div><br>`;
          }
          else{
           code = code + `<div class="image-textarea-bloc" contenteditable="false">
                <i class="far fa-file-image fa-8x"></i>
            <div class="image-textarea-title" contenteditable="false">${imageNames[i]}
            <div class="image-textarea-actions"><a href="${imageUrls[i]}" target="_blank"><i class="fas fa-expand-arrows-alt"></i></a> <a href="${imageUrls[i]}" download="${imageNames[i]}"><i class="fas fa-cloud-download-alt"></i></a><i class="fas fa-trash-alt" style="cursor: pointer" onclick="this.parentNode.parentNode.parentNode.parentNode.removeChild(this.parentNode.parentNode.parentNode);"></i></div>
            </div>
            </div><br>`;
          }

      }
      this._document.execCommand('insertHTML', false,  code + '<br>');
  }

  setDefaultParagraphSeparator(separator: string) {
    this._document.execCommand("defaultParagraphSeparator", false, separator);
  }

  createCustomClass(customClass: CustomClass) {
    let newTag = this.selectedText;
    if(customClass){
      const tagName = customClass.tag ? customClass.tag : 'span';
      newTag = '<' + tagName + ' class="' + customClass.class + '">' + this.selectedText + '</' + tagName + '>';
    }

    this.insertHtml(newTag);
  }
}
