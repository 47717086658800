import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ListPaneComponent } from './list-pane.component';

export const ROUTES/*: Routes*/ = [
    { path: 'list-pane', component: ListPaneComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
    ],
    declarations: [
        ListPaneComponent,
    ],
    exports: [
        ListPaneComponent,
    ],
})
export class ListPaneModule {}
