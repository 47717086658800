import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMultimedia'
})
export class MultimediaPipe implements PipeTransform {

  constructor() { }
  transform(type) {
    var video = ["mp4", "avi", "mov"];
    var picture = ["jpeg", "jpg", "png", "tif"];
    var pdf = ["pdf"];
    var html = ["zip"];
    var result = "multimedia";
    if( video.includes(type) ){
        result = "video";
    }else if(picture.includes(type) ){
        result = "image";
    }else if(pdf.includes(type) ){
        result = "pdf";
    }else if(html.includes(type) ){
        result = "html";
    }
    return result;
  }

}