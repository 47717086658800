import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InfoAssetsComponent } from './info-assets.component';
import { CommonModule } from '@angular/common';

export const ROUTES/*: Routes*/ = [
    { path: 'info-assets', component: InfoAssetsComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        CommonModule
    ],
    declarations: [
        InfoAssetsComponent,
    ],
    exports: [
        InfoAssetsComponent,
    ],
})
export class InfoAssetsModule {}
