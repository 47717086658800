import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InfoDialogComponent } from './info-dialog.component';

export const ROUTES/*: Routes*/ = [
    { path: 'info-dialog', component: InfoDialogComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        CommonModule
    ],
    declarations: [
        InfoDialogComponent,
    ],
    exports: [
        InfoDialogComponent,
    ],
})
export class InfoDialogModule {}
