import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export class CommandVisualsService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    command(assets: [], user, company, message): Observable<any> {
        var dataToSend = {};
        dataToSend["user"] = user;
        dataToSend["company"] = company;
        dataToSend["assets"] = assets;
        dataToSend["message"] = message.data;
        dataToSend["email"] = message.email;
        dataToSend["emailsToSend"] = message.emailsToSend;
        dataToSend["files"] = message.files;
        dataToSend["videoInfos"] = message.videoInfos;
        dataToSend["isVideo"] = message.isVideo;
        return this.http.post(`/api/usercommands`, dataToSend) as Observable<any>;
    }
}

