import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from './auth.service';
import { PermissionConstants } from '../../constants/permission.constants';
import { RouteConstants } from '../../constants/route.constants';

@Injectable({
  providedIn: 'root'
})
export class AccessService implements OnDestroy {
  constructor(private authService: AuthService) {
  }

  /** ALL ROUTE PERMISSIONS */
  canActivateRoute(pageId: string) : boolean {
    if(this.isDev){
      return true;
    }
    if(pageId == RouteConstants.AppRouting_Archive_Page){
      return this.showArchive;
    }
    else if(pageId == RouteConstants.AppRouting_TagAdmin_PageId) {
      return this.manageTag;
    }
    else if (pageId == RouteConstants.AppRouting_Verify_Page) {
      return this.verifyAsset;
    }
    else if (pageId == RouteConstants.AppRouting_AirTool_Page) {
        return this.showLightable;
    }
    else if (pageId == RouteConstants.AppRouting_Guideline_PageId) {
        return this.showGuideline;
    }
    else if (pageId == RouteConstants.AppRouting_HistoryPanePageId) {
        return this.showHistoryMenu;
    }
    else if (pageId == RouteConstants.AppRouting_HistoryBlockPageId) {
        return this.showHistoryAsset;
    }
    else if (pageId == RouteConstants.AppRouting_FileImport_PageId) {
        return this.importAsset;
    }
    else if (pageId == RouteConstants.AppRouting_Processing_PageId) {
        return this.showProcessing;
    }
    else if (pageId == RouteConstants.AppRouting_Masterline_PageId) {
        return this.showMasterLine;
    }
    else{
      return false;
    }
  }


  /** HELPERS */
  private getPermission(permission: string): boolean {
    if (this.authService.hasAccess(PermissionConstants.Development)){
      return true;
    }
    return this.authService.hasAccess(permission);
  }

  public ngOnDestroy(): void {
  }

  /** ALL APPLICATION PERMISSIONS */
  //
  // Global
  get isDev(): boolean { return this.getPermission(PermissionConstants.Development); }
  // asset
  get archiveAsset(): boolean { return this.getPermission(PermissionConstants.GEC_Archive_Asset); }
  get deleteAsset(): boolean { return this.getPermission(PermissionConstants.GEC_Delete_Asset); }
  get verifyAsset(): boolean { return this.getPermission(PermissionConstants.GEC_Verify_Asset); }
  get exportAsset(): boolean { return this.getPermission(PermissionConstants.GEC_Export_Asset); }
  get importAsset(): boolean { return this.getPermission(PermissionConstants.GEC_Import_Asset); }
  get commandAsset(): boolean { return this.getPermission(PermissionConstants.GEC_Command_Asset); }
  get createAsset(): boolean { return this.getPermission(PermissionConstants.GEC_Create_Asset); }
  get showHistoryAsset(): boolean { return this.getPermission(PermissionConstants.GEC_Show_History_Asset); }
  get approveTag(): boolean { return this.getPermission(PermissionConstants.LGT_Approve_Asset); }
  get manageTag(): boolean { return this.getPermission(PermissionConstants.GEC_Manage_Tag); }
  get showHistoryMenu(): boolean { return this.getPermission(PermissionConstants.GEC_Show_History_Menu); }
  get showArchive(): boolean { return this.getPermission(PermissionConstants.GEC_Show_Archive); }
  get showGuideline(): boolean { return this.getPermission(PermissionConstants.GEC_Show_Guideline); }
  get showProcessing(): boolean { return this.getPermission(PermissionConstants.GEC_Show_Processing); }
  get showLightable(): boolean { return this.getPermission(PermissionConstants.GEC_Show_Lightable); }
  get showCompany(): boolean { return this.getPermission(PermissionConstants.GEC_Show_Company); }
  get addVideo(): boolean { return this.getPermission('lgt-add-video'); }
  get showVideo(): boolean { return this.getPermission('lgt-access-video'); }
  get archiveVideo(): boolean { return this.getPermission('lgt-archive-video'); }
  get showMasterLine(): boolean { return this.getPermission(PermissionConstants.GEC_Show_Masterline); }
  get showAllMasterLine(): boolean { return this.getPermission(PermissionConstants.GEC_Show_All_Masterline); }
  get accessPublicity(): boolean { return this.getPermission('lgt-access-publicity'); }
  get createSelection(): boolean { return this.getPermission('lgt-create-selection'); }
  get hasAccessOnlyVideo(): boolean { return !this.accessPublicity && this.showVideo; }
  get hasAccessOnlyPublicity(): boolean { return this.accessPublicity && !this.showVideo; }
  get hasAllModuleAccess(): boolean { return this.accessPublicity && this.showVideo; }
}
