import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { noop, find, remove } from 'lodash';

class AssetType {
    _id?: string;
	id?: string;
	type?: string;
	rsuniqueid?: string;
	companyuniqueid?: string;
	isenabled?: boolean;
	fullname?: string;
	assetfile?: string;
	comment?: string;
	begindate?: string;
	enddate?: string;
	isarchived?: boolean;
	hasconstraint?: boolean;
	isguideline?: boolean;
	istoverify?: boolean;
	isapproved?: boolean;
	version?: string;
	isversion?: boolean;
	islastversion?: boolean;
UserId?: string;
	CompanyId?: string;
	WeFileId?: string;
	AnnotationId?: string;
	ValidationId?: string;

}


@Injectable({
    providedIn: 'root',
})
export class AssetService {
    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<AssetType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/assets', {params: body}) as Observable<AssetType[]>;
        }else{
            return this.http.get('/api/assets') as Observable<AssetType[]>;
        }

    }
    queryInclude(query:{}, include:{}): Observable<AssetType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
             body["include"] = JSON.stringify(include);
             var assetParams = localStorage.getItem("assetsParams");
             if( assetParams != JSON.stringify(body)){
                localStorage.setItem("assetsParams", JSON.stringify(body));
                return this.http.get('/api/assets',  {params: body}) as Observable<AssetType[]>;
             }else{
                const observable = Observable.create(function subscribe(observer) {
                    // Wait until all operations have completed
                    observer.next("Cached");
                    observer.complete();

                });
                return observable;
             }
        }else{
            return this.http.get('/api/assets') as Observable<AssetType[]>;
        }

    }
    queryIncludeWhitoutCache(query:{}, include:{}): Observable<AssetType> {
        if( query != undefined || include != undefined ){
                  var body = {};
               body["where"] = JSON.stringify(query);
               body["include"] = JSON.stringify(include);
           return this.http.get('/api/assets',  {params: body}) as Observable<AssetType[]>;
          }else{
              return this.http.get('/api/assets') as Observable<AssetType[]>;
          }

      }
    get(id: string): Observable<AssetType> {
        return this.http.get(`/api/assets/${id}`) as Observable<AssetType>;
    }
    create(asset: AssetType): Observable<AssetType> {
        return this.http.post(`/api/assets`, asset);
    }

    export(ids: {}): Observable<any> {
        var body = {};
        body["where"] = JSON.stringify(ids);
        return this.http.get(`/api/assets/export`, {params: body, responseType: 'blob'});
    }

    updateCache(asset: AssetType, type){
        if( asset != undefined ){
            var assets = JSON.parse(localStorage.getItem("assets"));
            let oldItem =  find(assets, {_id: asset._id});

            let index = assets.indexOf(oldItem);
            if( type == "create"){
                if( oldItem ){
                    assets.splice(index, 1, asset);
                }else{
                    assets.push(asset);
                }
            }else if( type == "delete" ){
                remove(assets, {_id: asset._id});
            }
            localStorage.setItem("assets", JSON.stringify(assets));
        }
    }

    createSelection(ids: {}): Observable<any> {
        var body = {};
        body["where"] = JSON.stringify(ids);
        return this.http.get(`/api/assets/createSelection`, {params: body});
    }
	update(asset: AssetType): Observable<AssetType> {
        this.updateCache(asset, "create");
       return this.http.put(`/api/assets/${asset.id || asset._id}`, asset) ;
    }
    updateInclude(asset: AssetType, query:{}, include:{}): Observable<AssetType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/assets/${asset.id || asset._id}`,  asset,  {params: body}) as Observable<AssetType[]>;
        }else{
            return this.http.put(`/api/assets/${asset.id || asset._id}`, asset) as Observable<AssetType[]>;
        }
    }
	remove(asset): Observable<AssetType> {
        this.updateCache(asset, "delete");
        return this.http.delete(`/api/assets/${asset.id || asset._id}`);
    }
    queryRemove(query:{}): Observable<AssetType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/assets',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

