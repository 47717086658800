import { MultimediaFilterVideoModule } from './../../../core/pipes/multimedia-filter-video.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MultimediavariantComponent } from './multimediavariant.component';
import { SafePipe } from '../../../core/pipes/sanitizer.pipe';
import { FileModule } from '../../shared-pages/file/file.module';
import { NgMagicIframeModule } from '@sebgroup/ng-magic-iframe';
import { MultimediaPipe } from './multimediavariant.pipe';
import { FilterMultimediaPipe } from './multimedia-filter.pipe';
import { FilterMultimediaSMPipe } from './multimedia-filter-sm.pipe.';

export const ROUTES/*: Routes*/ = [
    { path: 'multimediavariant', component: MultimediavariantComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        BrowserModule,
        FileModule,
        NgMagicIframeModule,
        MultimediaFilterVideoModule
    ],
    declarations: [
        MultimediavariantComponent, SafePipe, MultimediaPipe, FilterMultimediaPipe,
        FilterMultimediaSMPipe
    ],
    exports: [
        MultimediavariantComponent,
    ],
})
export class MultimediavariantModule {}
