import { RsLoaderModule } from 'rs-loader';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AuthModule } from '../core/services/auth/auth.module';

import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarModule } from 'ng-sidebar';
import { CompanyListModule } from './../pages/company-list/company-list.module';
@NgModule({
    imports: [
        CommonModule,
        AuthModule.forRoot(),
        NgbModule,
        RouterModule,
        SidebarModule,
        CompanyListModule,
        RsLoaderModule
    ],
    declarations: [
        NavbarComponent,
        FooterComponent
    ],
    exports: [
        NavbarComponent,
        FooterComponent

    ]
})
export class DirectivesModule {}
