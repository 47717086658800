import { MultimediaVariant } from './../../core/models/types';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grdFilter'
})
export class GrdFilterPipe implements PipeTransform {
  transform(items: any, filter: any, filterMetadata, filterInverseMetadata, isAll: boolean, isValidation: boolean): any {
   var defaultFilter = false;
    if (!filter){
      return items;
    }

    if (!Array.isArray(items)){
      return items;
    }

    if (filter && Array.isArray(items)) {
      var cumulatedFilter = [];
      var symbole = "";

      if( typeof filter["Tags"] != "object" ){
      if( filter.Tags.substring(0,1) == "#" && filter.Tags.length == 1){
        return items;
      }
      if( filter.Tags.substring(0,1) == "#" && filter.Tags.length > 1){
        delete filter["companyuniqueid"];
        delete filter["fullname"];

        if( filter["Tags"].includes(", ") ){
          cumulatedFilter = filter["Tags"].split(", ");
          symbole = ", ";
        }else if( filter["Tags"].includes(",") ){
          cumulatedFilter = filter["Tags"].split(",");
          symbole = ",";
        }else if( filter["Tags"].includes(" ") ){
          cumulatedFilter = filter["Tags"].split(" ");
          symbole = " ";
        }
        if( filter["Tags"].toLowerCase() != "#guideline" && filter["Tags"].toLowerCase() != "#video" && filter["Tags"].toLowerCase() != "#digital" && filter["Tags"].toLowerCase() != "#socialmedia"){
          filter["FeatureCats"] =  filter["FeatureCats"].replace("#", "");
          filter["Tags"] =  filter["Tags"].replace("#", "");
          filter["StateCats"] =  filter["StateCats"].replace("#", "");
        }
      }
     }
      let filterKeys = Object.keys(filter);

      if (defaultFilter) {
        return items.filter(item =>
            filterKeys.reduce((x, keyName) =>
                (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
      }
      else {

       var result = items.filter(item => {
        if( cumulatedFilter.length > 0){
            return this.cumulFilter(filterKeys, filter, item, cumulatedFilter, symbole);
        }
        return filterKeys.some((keyName) => {
           return this.filterItem(keyName, filter, item);
        });
      });

       filterInverseMetadata.count = items.length - result.length;
        filterMetadata.count = result.length;
        if( isValidation ){
            var vresult = result.filter(e => e.islastversion == true);
            var  vitems = items.filter(e => e.islastversion == true);
            filterInverseMetadata.count = vitems.length - vresult.length;
            filterMetadata.count = vresult.length;
         }
        filterMetadata.assets = result;

        if(filter["Tags"].toLowerCase() == "#guideline"){
          result = this.setGuideline(items, filterInverseMetadata, filterMetadata, items);
      }
      if(filter["Tags"].toLowerCase() == "#video"){
        result = this.setVideo(items, filterInverseMetadata, filterMetadata, items);
    }
    if(filter["Tags"].toLowerCase() == "#digital"){
        result = this.setDigital(items, filterInverseMetadata, filterMetadata, items);
    }
    if(filter["Tags"].toLowerCase() == "#socialmedia"){
        result = this.setSocialMedia(items, filterInverseMetadata, filterMetadata, items);
    }
      if( isAll ){
        return items.filter(item => result.indexOf(item) < 0);
      } else{
        return result;
      }

      }
    }
  }

  setGuideline(result, inverse, filter, items){
    var newResult = result.filter(item => item.isguideline);
    inverse.count = items.length - newResult.length;
    filter.count = newResult.length;
    filter.assets = newResult;
    return newResult;
  }

  setVideo(result, inverse, filter, items){
    var newResult = result.filter(item => item.MultimediaVariants.filter( e => e.isvideo == true).length > 0);
    inverse.count = items.length - newResult.length;
    filter.count = newResult.length;
    filter.assets = newResult;
    return newResult;
  }


  setDigital(result, inverse, filter, items){
    var newResult = result.filter(item => item.MultimediaVariants.filter( e => e.isvideo != true && e.isinteractive == true).length > 0);
    inverse.count = items.length - newResult.length;
    filter.count = newResult.length;
    filter.assets = newResult;
    return newResult;
  }

  setSocialMedia(result, inverse, filter, items){
    var newResult = result.filter(item => item.MultimediaVariants.filter( e => e.isvideo != true && e.isinteractive != true).length > 0);
    inverse.count = items.length - newResult.length;
    filter.count = newResult.length;
    filter.assets = newResult;
    return newResult;
  }

  arrayContainsArray (superset, subset) {
    return subset.every((value) => {
      return superset.filter(childItem => {
        return new RegExp(value, 'gi').test(childItem) || value == "";
     }).length > 0;
    });
  }
  arrayContainsOneElemOfArray (superset, subset) {
    return superset.some(r=> subset.indexOf(r) >= 0);
  }

  cumulFilter(filterKeys, filter, item, cumulatedFilter, symbole){
      var itemValues = [];
      cumulatedFilter.forEach((element, index) => {
          if( element == "" || element == "#" || element == "," || element == ", " ){
            cumulatedFilter.splice( index )
          }
      });
      filterKeys.forEach(filterItem => {
        item[filterItem].forEach(tagItem => {
          itemValues.push("#" + tagItem.name);
        });
      });
      if( item.isguideline ){
          itemValues.push("#Guideline");
      }
      if( item.MultimediaVariants.filter( e => e.isvideo).length > 0 ){
        itemValues.push("#Video");
      }
      if( item.MultimediaVariants.filter( e => e.isvideo != true && e.isinteractive != true).length > 0 ){
        itemValues.push("#Digital");
      }
      if( item.MultimediaVariants.filter( e => e.isvideo != true && e.isinteractive == true).length > 0 ){
        itemValues.push("#SocialMedia");
      }
      if( symbole == "," || symbole == ", "){
        if( this.arrayContainsOneElemOfArray(itemValues, cumulatedFilter )){
          return true;
        }else{
          return false;
        }
      }else if( symbole == " " ){
        if( this.arrayContainsArray(itemValues, cumulatedFilter )){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
      //Travail/Client/test.htm

  }

  filterItem(keyName, filter, item){
    if( keyName[0] == keyName[0].toUpperCase()){
      if( typeof filter[keyName] == "object"){

      }else{
        return item[keyName].filter(childItem => {
          return filter[keyName].toLowerCase() == childItem["name"].toLowerCase() || filter[keyName] == "";
    }).length > 0;
      }
    }else{
      if( typeof filter[keyName] == "object"){
        var filterTest = filter[keyName];
        return new RegExp(filterTest["fullname"], 'gi').test(item[keyName]) || filterTest["fullname"] == "";
      }
      return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
    }
  }
}

