import { Component, Input, ViewChild, OnInit, AfterViewChecked, Output, EventEmitter } from '@angular/core';
import { AssetExt } from '../../../core/models/types.extension';
import { FileComponent } from '../../shared-pages/file/file.component';
import { MultimediaVariant } from '../../../core/models/types';
import {DomSanitizer} from '@angular/platform-browser';

import { iif } from 'rxjs';

@Component({
    selector: 'multimediavariant',
    template: require('./multimediavariant.html'),
     styles: [require('./multimediavariant.scss')],
})
export class MultimediavariantComponent implements OnInit, AfterViewChecked{
    @Input() asset: AssetExt;
    @Input() tokenUser;
    @Input() isEditable;
    @Input() activated: boolean = false;
    @Input() isSM: boolean;
    @Input() isMobile = false;
    @Input() redirect;
    @ViewChild(FileComponent) fileComponent: FileComponent;
    @Output() addedFiles = new EventEmitter(true);
    @Output() uploadedFiles = new EventEmitter(true);
    sizes = {};
    Sanitizer;
    width;
    height;
    isNotLoaded = true;
    loaded = {};
    minHeight = 120;
    frameAreLoaded = {};
    changeDone = false ;
    isLoaded = false;
    showIframe = false;
    oldAsset;

    static parameters = [DomSanitizer];
    constructor(private sanitizer: DomSanitizer) {
     this.Sanitizer = sanitizer;
    }
    remove(variant){
        this.asset.MultimediaVariants = this.asset.MultimediaVariants.filter(e => e.name != variant.name);
    }

    ngOnInit(){

    }

    ngAfterViewChecked(){

    }

  frameLoaded(event, element, index){
    if( event.event == "iframe-click"){
        $(element).find("iframe").attr("src", event.src);
    }else if( event.event == "iframe-loaded"){
        if( this.isLoaded == false ){
            this.loadIframe(index, element);
        }else{
            if( this.asset.MultimediaVariants.length - 1 == index ){
                this.showIframe = true;
            }
        }
    }else if( event.event == "iframe-unloaded" ){
        this.isLoaded = true;
    }
  }

  onVariantAdded(event){
      this.addedFiles.emit(event);
  }
  loadIframe(index, element){
    this.oldAsset = Object.assign({}, this.asset);
    this.minHeight = this.
    isEditable ? 10 : 50;
    if( this.isSM ){
        this.minHeight = this.isEditable ? 10 : 30;
    }
        var inside = $(element).find("iframe").contents().find("#ad");
        var height;
        var width;

        if( inside.length == 0 ){
            inside = $(element).find("iframe").contents().find("body").children().first();
            if( inside.length > 0 ){
            var elem = inside[0];
            var tagName = elem.tagName;
            if( tagName == "IMG"){
                height = elem.height;
                width = elem.width;
            }else if( tagName == "EMBED"){
                height =  (elem.height * this.minHeight ) / 100;
            }else if( tagName == "VIDEO"){
                height = elem.videoHeight;
                width = elem.videoWidth;
                var video = elem;
                elem.muted = true;
                $(elem).remove();
                $(element).find("iframe").contents().find("body").append(elem);
            }
        }
        }else{
            height = $(element).find("iframe").contents().find("#ad").css("height").replace("px", "");
            width = $(element).find("iframe").contents().find("#ad").css("width").replace("px", "");
        }
        this.sizes[index + "-height"] = Math.round(height);
        this.sizes[index + "-width"] = Math.round(width);

        if( this.isMobile){
            return;
        }
        if(!(height == undefined || width == undefined)){
            var newWidth = (width * (height * this.minHeight ) / 100 ) / height;

            if(element.clientWidth != newWidth){
                $(element).css("width", newWidth);
                $(element).css("height", (height * this.minHeight ) / 100 );
                var scale = ((newWidth * 100) / width) / 100;
                $(element).find("iframe").css({
                    'height' : height + "px",
                    'width' : width + "px",
                    '-webkit-transform' : 'scale(' + scale + ')',
                    '-moz-transform'    : 'scale(' + scale + ')',
                    '-ms-transform'     : 'scale(' + scale + ')',
                    '-o-transform'      : 'scale(' + scale + ')',
                    'transform'         : 'scale(' + scale + ')',
                    'transform-origin': '0 0',
                    '-moz-transform-origin': '0 0',
                    '-webkit-transform-origin': '0 0',
                  });
            }
        }
  }

  updapteParentFile(event){
    this.uploadedFiles.emit(event);
  }

    setMultimediaVariants(){
        this.fileComponent.files.forEach(element => {
            var multimediaVariant = new MultimediaVariant();
            multimediaVariant.isvideo = false;
            multimediaVariant.name = element.name.replace(".zip", "");
            this.asset.MultimediaVariants.push(multimediaVariant);
        });
    }



    getWidth(element){

      }
}
