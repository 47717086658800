import { NgModule, } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { CompanyListComponent } from './company-list.component';
import { CompanyModule } from '../company/company.module';

export const ROUTES/*: Routes*/ = [
    { path: 'company-list', component: CompanyListComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        BrowserModule,
        CompanyModule
    ],
    declarations: [
        CompanyListComponent,
    ],
    exports: [
        CompanyListComponent,
    ],
})
export class CompanyListModule {}
