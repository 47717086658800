import { AuthService } from './../../../core/services/auth/auth.service';
import { Asset, MultimediaVariant } from '../../../core/models/types';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import $ from 'jquery';

@Component({
    selector: 'ngbd-modal-validation-content',
    template: require('./search-tool-modal.html'),
    styles: [require('./search-tool-modal.scss')],
    providers: [NgbTabsetConfig]
  })
  export class NgbdModalValidationContent implements OnInit, OnDestroy {
    @Input() readLinked;
    @Input() isParent;
    @Input() linkedAssets;
    @Input() filteredListAssets;
    @Input() asset;
    @Input() Company;
    @Input() infoAssets;
    @Input() isVideo = false;
    multimediaActivated = false;
    @Input() tokenUser;
    @Input() currentUser;
    isFullScreen = true;
    isMobile = false;
    selectedVideo;
    isInfos = false;
    DeviceDetectorService;
    AuthService;
    constructor(public activeModal: NgbActiveModal, config: NgbTabsetConfig, deviceService: DeviceDetectorService, authService: AuthService) {
      this.DeviceDetectorService = deviceService;
      if( this.DeviceDetectorService.isMobile() ){
        config.orientation = 'vertical';
        this.isMobile = true;
      }else{
        config.justify = 'end';
        config.orientation = 'horizontal';
        this.isMobile = false;
      }
      this.AuthService = authService;
    }

    ngOnInit(){

    }

    ngOnDestroy(){

    }
    getLinkedAssets(asset){
        var linked = [];
        if( asset.Children.length > 0 && asset.Parents.length > 0 ){
            linked = this.removeDuplicates(asset.Children.concat(asset.Parents), "_id");
        }else if( asset.Children.length > 0 ){
            linked =  asset.Children;
        }else if( asset.Parents.length > 0 ){
            linked =  asset.Parents;
        }
        return linked;
    }


    previousAsset(){
      var currentAssetIndex = this.filteredListAssets.indexOf(this.asset);
      var newIndex = 0;
      if( currentAssetIndex == 0 ){
        newIndex = this.filteredListAssets.length - 1;
      }else{
        newIndex = currentAssetIndex - 1;
      }
      this.asset = this.filteredListAssets[newIndex];
      event.stopPropagation();
    }

    nextAsset(){
      var currentAssetIndex = this.filteredListAssets.indexOf(this.asset);
      var newIndex = 0;
      if( currentAssetIndex == this.filteredListAssets.length - 1 ){
        newIndex = 0;
      }else{
        newIndex = currentAssetIndex + 1;
      }
      this.asset = this.filteredListAssets[newIndex];
      event.stopPropagation();
    }

    loadLinkedAsset(event, asset){
        this.asset = this.filteredListAssets.filter(a => a._id == asset._id)[0];
        event.stopPropagation();
    }

    preventClick(event){
      event.stopPropagation();
    }
    isLinked(){
      return this.readLinked;
    }

    removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }
    setActive(){
      if( this.isMobile ){
        $(document).ready(function(){
            $("#linked-modal-body").prependTo(".tab-content");
            $("#linked-modal-body").css("padding", "0px");
            $(".modal-content").css("overflow-y", "auto");
          });
      }
      if( this.readLinked ){
        this.isInfos = true;
        this.isFullScreen = false;
        this.readLinked = true;
        return 'tab-infos';
      }
    }
    getIsFullScreen(){
      return this.isFullScreen;
    }

    hasMultimedia(){
            return this.asset.MultimediaVariants.filter((e) => e.isinteractive && e.isvideo != true).length > 0 ;
    }
    hasVideo(){
        return this.asset.MultimediaVariants.filter((e) =>  e.isvideo).length > 0 ;
    }

    hasSM(){
            return this.asset.MultimediaVariants.filter((e) => e.isinteractive == false).length > 0 ;
    }
    changeSelectedVideo(event){
        // this.selectedVideo = file;
      }
    beforeChange(event){
      this.readLinked = false;
      if( event.activeId == "tab-titledesktop" || event.activeId == "tab-titlemobile"){
        this.isFullScreen = false;
      }else if( event.nextId == "tab-titledesktop" || event.nextId == "tab-titlemobile"){
        this.isFullScreen = true;
      }


      if( event.nextId == "tab-infos"){
        this.isInfos = true;
      }else{
        this.isInfos = false;
      }

      if( event.nextId == 'tab-digit' || event.nextId == "social-media"){
        var component = this;

        var currentMultimediaVariants;
        var id = "tab-digit";
        if( event.nextId == "social-media" ){
            id = "social-media";
            currentMultimediaVariants = this.asset.MultimediaVariants.filter(e => e.isinteractive == false && e.isvideo != true);
        }else{
            currentMultimediaVariants = this.asset.MultimediaVariants.filter(e => e.isinteractive == true  && e.isvideo != true);
        }

        $("#" + id + "-panel .seb-iframe").each(function(index, element){
            var link = '/api/files/' + currentMultimediaVariants[index].FileId;
            element['src'] = link;
        })
        this.multimediaActivated = true;
      }else{
        this.multimediaActivated = false;
      }
    }

    getPicture(){
        return "/api/files/" + this.asset.assetfile;
    }
  }
