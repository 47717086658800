import { AnnotationService } from '../../../core/services/annotation/annotation.service';
import { Component, Input, OnInit, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { NgbdModalValidationContent } from '../../search-tool/validation/search-tool-modal.component';
import { SocketService } from '../../../core/services/socket/socket.service';
import * as LC from '../../../../assets/js/literallycanvas/js/_js_libs/literallycanvas-core';
import * as KellyColorPicker from '../../../../assets/js/color-picker/js/html5kellycolorpicker';
import { DomSanitizer } from "@angular/platform-browser";
import {AssetExt}  from '../../../core/models/types.extension';
import { AssetService } from '../../../core/services/asset/asset.service';
import { Annotation, Validation, User, StateCat } from '../../../core/models/types';
import { AngularEditorConfig } from '../../../ui/editor/config';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../../core/services/confirm/confirm.service';

@Component({
    selector: 'retouch',
    template: require('./retouch.html'),
     styles: [require('./retouch.scss')],
     providers:[AssetService, SocketService, AnnotationService, ConfirmationDialogService]
})
export class RetouchComponent implements OnInit, OnDestroy, AfterViewInit{
    @Input() asset: AssetExt;
    @Input() children: [AssetExt];
    @Input() token: '';
    @Input() parent: NgbdModalValidationContent;
    @Input() currentUser: User;

    isActionDisplayed = false;
    rangeOpacity = 100;
    imagePath;
    isCompareShowed = false;
    htmlContent;
    commentWidth = "20px";
    versionAsset;
    editable = false;
    SocketAssetService = new SocketService();
    lc = null;
      AssetService;
      editorConfig: AngularEditorConfig = {
        spellcheck: true,
        minHeight: "200px",
        minWidth: "267px",
        width: "267px",
        refusedMimes: ["image/tiff"],
        placeholder: 'Enter comment here...',
        translate: 'no',
        uploadUrl: 'api/files/comment',
        toolBar: [['bold', 'italic'], ['uploadFile']]
      };
    AnnotationService;
    static parameters = [AssetService, AnnotationService, NgbModal, DomSanitizer, ConfirmationDialogService];
    constructor(private assetService: AssetService, private annotationService: AnnotationService, private modalService: NgbModal, private sanitizer: DomSanitizer, private confirmationDialog: ConfirmationDialogService) {
        this.AssetService = assetService;
        this.AnnotationService = annotationService;
    }
    @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
        console.log("Processing beforeunload...");
        // Do more processing...
          this.saveAnnotation();
    }
    ngOnInit(){

        if( this.currentUser.role != "rs-user"){
            this.isActionDisplayed = true;
            this.editable = true;
        }
        if( this.asset["Validation"] != undefined ){
            this.isActionDisplayed = false;
            this.editable = false;
        }
        if( this.asset.Parents != undefined ){
            this.asset.Parents = this.asset.Parents.reverse();
        }
        if( this.asset.AnnotationId){

            this.AnnotationService.getDatas(this.asset.CompanyId, this.asset._id, "drawing").subscribe((annotation) => {
                var snapshot = JSON.parse(annotation);
                this.setAnnotationTool(snapshot);
              //  this.SocketAssetService.syncUpdates("annotation", this.asset["Annotation"]);
            })
            this.AnnotationService.getDatas(this.asset.CompanyId, this.asset._id, "comment").subscribe((annotation) => {
                this.htmlContent = annotation;
            }, (error) => {
                console.log(error);
            })
        }else{
            this.setAnnotationTool(null);
        }
        this.dragElement($("#dragable-zone")[0], $("#dragable-area")[0], $(".anotations-zone")[0]);
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

        }, (reason) => {

        });
      }

    onResizeEnd(event){
        this.commentWidth = event.rectangle.width + "px";
    }

    ngOnDestroy(){
        this.saveAnnotation();
        localStorage.removeItem("drawing");
     //   this.SocketAssetService.unsyncUpdates("asset");
    }

    ngAfterViewInit(){
       // this.lc.repaintLayer("main");
    }
    getResizePosition(){
        if($('#sidebar').hasClass('active')){
            return 30;
        }else{
            return 285;
        }
    }
    setAnnotationTool(snapshot){
        var tools;
        var colors;

        var setCurrentByName;
        var findByName;

        // the only LC-specific thing we have to do
        var containerOne = document.getElementsByClassName('literally')[0];
        var component = this;
        var img = new Image();
        img.src = "/api/files/" + this.asset.assetfile;
        var toolbarPosition = 'bottom';
        var showLC = function() {
            component.lc = LC.init(containerOne, {
            defaultStrokeWidth: 3,
            strokeWidths: [10, 20, 50],
            secondaryColor: 'transparent'.escapeHTML,
            zoomMin: 1,
            zoomMax: 8,
            font: "12px Roboto",
            snapshot: snapshot,
            isActionDisplayed: component.isActionDisplayed,
            toolbarPosition: toolbarPosition,
            backgroundShapes: [
                LC.createShape(
                  'Image', {image: img, center:true}),
              ]
          });

          $(window).click(function() {
            $(".popup-kelly-color").hide();
            component.lc.setColor('primary', $(".colorpicker-custom").val());
            });
          $('#test').click(function(){
              console.log(component.lc);
          })
          var save = function(isLoaded) {
            component.lc.isLoaded = false;
           // component.saveAnnotation();
          }
          var adapt = function(){

         };
         component.lc.on('snapshotLoad', adapt());

          component.lc.on('drawingChange', save);
          component.lc.on('pan', save);
          component.lc.on('zoom', save);

          $("#clear-lc").click(function() {
            component.lc.clear();
          });

          $("#undo-lc").click(function() {
            component.lc.undo();
          });

          $("#redo-lc").click(function() {
            component.lc.redo();
          });
          $("#zoom-lc").click(function() {
            component.lc.zoom(0.2);
          });
          $("#dezoom-lc").click(function() {
            component.lc.zoom(-0.2);
          });
          $("#resize-zoom-lc").click(function() {
            component.lc.setZoom(1);
          });
          // Set up our own tools...
          tools = [
            {
              name: 'pencil',
              cursor: 'rs-c-pen',
              el: document.getElementById('tool-pencil'),
              tool: new LC.tools.Pencil(component.lc),
              strokeWidth: 5
            },
            {
                name: 'pencil2',
                cursor: 'rs-c-pen',
                el: document.getElementById('tool-pencil2'),
                tool: new LC.tools.Pencil(component.lc),
                strokeWidth: 10
              },
            {
              name: 'text',
              cursor: 'rs-c-text',
              el: document.getElementById('tool-text'),
              tool: new LC.tools.Text(component.lc)
            },{
              name: 'tool-eraser',
              cursor: 'rs-c-eraser',              el: document.getElementById('tool-eraser'),
              tool: new LC.tools.SelectShape(component.lc)
            },
            {
                name: 'tool-select',
                cursor: 'rs-c-select',
                el: document.getElementById('tool-select'),
                tool: new LC.tools.SelectShape(component.lc)
              },
            {
                name: 'tool-pan',
                cursor: 'rs-c-pan',
                el: document.getElementById('tool-pan'),
                tool: new  LC.tools.Pan,
            }
          ];

          findByName = function(ary, val) {
            var vals;
            vals = ary.filter(function(v){
              return v.name == val;
            });
            if ( vals.length == 0 )
              return null;
            else
              return vals[0];
          };

          // Wire tools

          tools.forEach(function(t) {
              if( t.name == "text"){
                component.lc.textTool = t.tool;
              }else if(  t.name == "tool-select" ){
                  component.lc.selectTool = t.tool;
              }
            $(t.el).click(function() {
              var sw;
                t.tool.cursor = t.cursor;
               t.tool.strokeWidth = t.strokeWidth;
                component.lc.setTool(t.tool);
              if(t.name == 'tool-eraser'){
                  t.tool['isEraser'] = true;
              }
              $('#tools-sizes').toggleClass('disabled', (t.name == 'text'));
            });
          });
        };


        $(document).ready(function() {
          // disable scrolling on touch devices so we can actually draw
          $(document).bind('touchmove', function(e) {
             // return e.preventDefault();
          });

          setTimeout(() => {
            showLC();
          }, 1000);

          $("#colorTool-picker").click(function(e){
              var position = $(this).offset();
              var top =  $(this).css("bottom");
              var left =  $(this).css("left");
              $(".popup-kelly-color").toggle();
              e.stopPropagation();
        });

        $('.tool.action').click(function(){
            $('.tool.action').removeClass('toolActive');
            $(this).toggleClass('toolActive');
        });
        $('.popup-kelly-color').click(function(e){

        });
            //http://jsfiddle.net/V92Gn/682/
            //http://jsfiddle.net/V92Gn/678/

            var colorPickers = new Array();
          var inputs = document.getElementsByClassName('colorpicker-custom');

          var cfg = {alpha_slider : false, size : 100, method : 'triangle'};
          for (var i = 0; i < inputs.length; i++) {
                cfg['input'] = inputs[i];
              colorPickers.push(new KellyColorPicker(cfg));
          }
        });
            // Bubble text url http://jsfiddle.net/V92Gn/682/
    }
    changeRange(){
        $("#literraly-core canvas")[1]["style"]["opacity"] = '' + this.rangeOpacity / 100;
    }
    saveComment(){
        if( this.versionAsset != undefined ){
            return;
        }
        if( this.asset['Annotation'] == undefined ){
            var annotation = new Annotation();
             annotation.comment = this.htmlContent;
        }else{
            var annotation = this.asset['Annotation'];
              annotation.comment = this.htmlContent;
        }

        this.asset['Annotation'] = annotation;
    }
    saveAnnotation(){
        if(  this.editable ){
            this.saveComment();
        }
        if( this.isActionDisplayed == false ){
            return;
        };
        if( this.asset['Annotation'] == undefined ){
            var annotation = new Annotation();
            annotation.drawing = JSON.stringify(this.lc.getSnapshot());
        }else{
            var annotation = this.asset['Annotation'];
            annotation.drawing = JSON.stringify(this.lc.getSnapshot());
        }

        this.asset['Annotation'] = annotation;

        this.AssetService.create(this.asset).subscribe((asset) => {
        }, (error) => {
            console.log(error);
        });
    }

    setPositions(){
        var result =  $('.resize-ghost-element')[0];
        if( result == undefined ){
            result = $('#version-wrapper')[0];
            var width = Number(result.style.width.replace("px", ""));
            var $currentVersion = $('#current-version');
            var $logoVersion = $('#logo-version');
            $currentVersion.css({'left': ( width + 10 + 'px')});
            $logoVersion.css({'left': (width - 7 +  'px')});
        }else{
            $('#current-version').css({'left': ( result.offsetWidth + 10 + 'px')});
            $('#logo-version').css({'left': ( result.offsetWidth - 7 +  'px')});
        }

    }
    sendRequest(){
        this.confirmationDialog.confirm(this.asset.companyuniqueid, 'Confirm request ?')
        .then((confirmed) => {
           if( confirmed){
            var validation = new Validation();
            validation.isvalidate = false;
            this.asset['Validation'] = validation;
            this.AssetService.create(this.asset).subscribe((asset) => {
                this.parent.activeModal.dismiss('Cross click');
            }, (error) => {
                console.log(error);
            });
           }
          });
          event.stopPropagation();
    }

    setVersion(asset){
        if( asset == this.versionAsset ){
            asset = this.asset;
            this.versionAsset = undefined;
        }else{
            this.versionAsset = asset;
        }

        this.imagePath = 'api/files/' + asset.assetfile;


         if( asset == this.asset ){
            this.isCompareShowed = false;
         }else{
            this.isCompareShowed = true;
         }
         this.setPositions();
    }
    sendValidation(){
        this.confirmationDialog.confirm(this.asset.companyuniqueid, 'Confirm validation ?')
        .then((confirmed) => {
           if( confirmed){
            var validation = new Validation();
        validation.isvalidate = true;
        this.asset['Validation'] = validation;
        this.AssetService.create(this.asset).subscribe((asset) => {
            this.parent.activeModal.dismiss('Cross click');
        }, (error) => {
            console.log(error);
        });
           }
          });
          event.stopPropagation();
    }

    dragElement(element, zone, areaZone) {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(element.id )) {
          /* if present, the header is where you move the DIV from:*/
          document.getElementById(element.id ).onmousedown = dragMouseDown;
        } else {
          /* otherwise, move the DIV from anywhere inside the DIV:*/
          zone.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;

          var topZone = (zone.offsetTop - pos2);
          var leftZone = (zone.offsetLeft - pos1);
          if( leftZone < areaZone.offsetLeft + 50 ){
              leftZone = areaZone.offsetLeft + 50 ;
          }
          if( topZone < areaZone.offsetTop - 100 ){
              topZone = areaZone.offsetTop - 100;
          }
          if( leftZone > areaZone.offsetLeft + areaZone.offsetWidth){
              leftZone = areaZone.offsetLeft + areaZone.offsetWidth;
          }
          if( topZone > areaZone.offsetTop + areaZone.offsetHeight - 100){
            topZone = areaZone.offsetTop + areaZone.offsetHeight - 100;
         }

            // set the element's new position:
            zone.style.top = topZone + "px";
            zone.style.left = leftZone + "px";
        }

        function closeDragElement() {
          /* stop moving when mouse button is released:*/
          document.onmouseup = null;
          document.onmousemove = null;
        }
      }

}


