import { AuthService } from '../../core/services/auth/auth.service';
import { Component } from '@angular/core';
import { AssetService } from '../../core/services/asset/asset.service';

@Component({
    selector: 'historyPane',
    template: require('./history-pane.html'),
    providers: [AssetService]
    // styles: [require('./history-pane.css')],
})
export class HistoryPaneComponent {
    assets;
    AssetService;
    AuthService;
    currentCompany;
    static parameters = [AssetService];
    constructor(private assetService: AssetService, private authService: AuthService) {
        this.AuthService = authService;
        this.AssetService = assetService;
        this.setCurrentCompany();
    }

    setCurrentCompany(){
        this.currentCompany = this.AuthService.currentSelectedCompany;
        this.setAssets();
        this.AuthService.currentSelectedCompanyChanged.subscribe(company => {
                this.currentCompany = company;
                this.setAssets();
        });
    }


    setAssets(){
        this.AssetService.queryInclude({CompanyId: this.currentCompany._id}, [{association: "HistoryBlocks", include:["User"]}]).subscribe((assets) =>{
            this.assets = assets.filter( e => e.HistoryBlocks != undefined );
            this.assets = this.assets.filter( e => e.HistoryBlocks.filter( h => h.User.CompanyId != 1).length > 0 );
          });
    }

}
