import { Component, Input, ViewChild, OnInit, AfterViewInit, OnChanges} from '@angular/core';
import { Company } from '../../../core/models/types';
import { AssetExt } from '../../../core/models/types.extension';
import { FileComponent } from '../../shared-pages/file/file.component';
import { Guideline } from '../../../core/models/types';
import { GuidelineService } from '../../../core/services/guideline/guideline.service';
import { AuthService } from './../../../core/services/auth/auth.service';




@Component({
    selector: 'guideline',
    template: require('./guideline.html'),
    providers: [GuidelineService]
    // styles: [require('./guideline.css')],
})
export class GuidelineComponent implements OnInit, AfterViewInit {
        @Input() asset: AssetExt;
        @Input() company: Company;
        @Input() tokenUser;
        @Input() isEditable;
        @Input() isMobile = false;
        @ViewChild(FileComponent) fileComponent: FileComponent;
        oldAsset;
        viewLoaded = false;
        static parameters = [GuidelineService];
        GuidelineService;
        AuthService;
        constructor(private guidelineService: GuidelineService, private authService: AuthService) {
            this.GuidelineService = this.guidelineService;
            this.AuthService = authService;
        }

        ngOnInit() {

        }


        setCompanyGuideline(company){

        }

        ngOnDestroy(){

        }
        remove(asset) {
            this.asset.Guidelines.splice( this.asset.Guidelines.indexOf(asset), 1 );
        }

        ngAfterViewInit() {

        }

        setGuidelines() {
            this.fileComponent.files.forEach(element => {
                var guideline = new Guideline();
                guideline.name = element.name.replace('.zip', '');
                this.asset.Guidelines.push(guideline);
            });
        }
}
