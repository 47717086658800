import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class FileType {
    _id?: string;
	id?: string;
	name?: string;
	extension?: string;
	path?: string;
	blob?: string;
  
		userid?: string;
    size?: string;
		companyid?: string;
}

@Injectable()
export class FileService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query?: {}): Observable<FileType[]> {
      
        if( query != undefined ){
            var body = {};
            body["where"] = JSON.stringify(query);
            return this.http.get('/api/files', {params:body}) as Observable<FileType[]>;
       }else{
         return this.http.get('/api/files') as Observable<FileType[]>;
       }
    }
    get(id: string): Observable<any> {
        return this.http.get(`/api/files/${id}`) as Observable<any>;
    }

    getThumb(id: string): Observable<any> {
        return this.http.get(`/api/files/thumb/${id}`, { responseType: 'blob' });
    }
    getIcon(id: string): Observable<any> {
        return this.http.get(`/api/files/icon/${id}`) as Observable<any>;
    }
    create(file: FileType): Observable<FileType> {
        return this.http.post(`/api/files`, file);
    }
	update(file: FileType): Observable<FileType> {
       return this.http.put(`/api/files/${file.id || file._id}`, file);
    }
    remove(file): Observable<FileType> {
        return this.http.delete(`/api/files/${file.id || file._id}`);
    }

    removeById(id): Observable<FileType> {
        return this.http.delete(`/api/files/${id}`);
    }
    getByUrl(url): Observable<any> {
        return this.http.get(url);
    }
}

