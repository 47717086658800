import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import {CompanyService} from '../../core/services/company/company.service';
import {AuthService} from '../../core/services/auth/auth.service';
import "ag-grid-angular";

@Component({
    selector: 'airTool',
    template: require('./air-tool.html'),
    styles: [require('./air-tool.scss')],
    providers: [CompanyService, DatePipe]
})
export class AirToolComponent {
    private gridApi;
    private gridColumnApi;
    private gridParams;
    private components;
    private columnDefs;
    private rowData: [];
    rowSelection;
    AuthService;

    CompanyService;

    static parameters = [CompanyService, AuthService, DatePipe];
    constructor(private companyService: CompanyService, private authService: AuthService, private datePipe: DatePipe) {
        this.AuthService = authService;
        this.CompanyService = companyService;
        this.rowSelection = "multiple";
        this.columnDefs = [
            {headerName: 'Full Name', field: 'fullname', editable: true, sortable: true, filter: true },
            {headerName: 'Company name', field: 'companyuniqueid', editable: true, sortable: true, filter: true  },
            {headerName: 'Comment', field: 'Comments', valueGetter: (params)=>{
                return params.data.Comments[0].description;
            }, valueSetter: (params) => {
                params.data.Comments[0].description = params.newValue;
            }, editable: true, sortable: true, filter: true},
            {headerName: 'Picture', field: 'assetfile', cellRenderer: (params) => {
                return '<img src=' + '"/api/files/icon/' + params.value +  '" >';
            }, editable: false,  autoHeight: true, width: 120},
            {headerName: 'Validity Begin', field: 'Periods',  cellEditor: "datePicker", editable: true, sortable: true, filter: true},
            {headerName: 'Validity End', field: 'Periods', cellEditor: "datePicker", editable: true, sortable: true, filter: true},
            {headerName: 'Created', field: 'createdAt', valueFormatter: (params) =>{ return this.datePipe.transform(params.value, "dd-yyyy-MM")}, editable: false, sortable: true, filter: true},
            {headerName: 'Updapted', field: 'updatedAt', valueFormatter: (params) =>{ return this.datePipe.transform(params.value, "dd-yyyy-MM")}, editable: false, sortable: true, filter: true},
            {headerName: 'Created By', field: 'User', valueGetter: (params)=>{
                return params.data.User.firstname + " " + params.data.User.name;
            },  editable: false, sortable: true, filter: true},
            {headerName: 'Tags', field: 'Tags', editable: false, sortable: true, filter: true},
            {headerName: 'Features', field: 'FeatureCats', editable: false, sortable: true, filter: true},
            {headerName: 'States', field: 'StateCats', editable: false, sortable: true, filter: true},
        ];
        this.components = { datePicker: getDatePicker() };
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.CompanyService.queryInclude({_id: 1}, [{association: "Assets", include:[ "Tags", "FeatureCats", "StateCats", "HistoryBlocks", "User"]}]).subscribe(data => {
            this.rowData = data[0].Assets;

         }, error => {

         });
        setTimeout(function() {
            params.api.resetRowHeights();
          }, 200);
          setTimeout(function() {
            params.api.resetRowHeights();
          }, 2000);
          setTimeout(function() {
            params.api.resetRowHeights();
          }, 3000);
      }

}

function getDatePicker() {
    function Datepicker() {}
    Datepicker.prototype.init = function(params) {
      this.eInput = document.createElement("input");
      this.eInput.value = params.value;
      ($(this.eInput)as any).datepicker({ dateFormat: "dd/mm/yy" });
    };
    Datepicker.prototype.getGui = function() {
      return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function() {
      this.eInput.focus();
      this.eInput.select();
    };
    Datepicker.prototype.getValue = function() {
      return this.eInput.value;
    };
    Datepicker.prototype.destroy = function() {};
    Datepicker.prototype.isPopup = function() {
      return false;
    };
    return Datepicker;
  }
