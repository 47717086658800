import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class AnnotationType {
    _id?: string;
	id?: string;
	folder?: string;
	comment?: string;
	drawing?: string;
UserId?: string;

}


@Injectable()
export class AnnotationService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<AnnotationType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/annotations', {params: body}) as Observable<AnnotationType[]>;
        }else{
            return this.http.get('/api/annotations') as Observable<AnnotationType[]>;
        }

    }
	queryInclude(query:{}, include:{}): Observable<AnnotationType[]> {
	  if( query != undefined || include != undefined ){
                var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/annotations',  {params: body}) as Observable<AnnotationType[]>;
        }else{
            return this.http.get('/api/annotations') as Observable<AnnotationType[]>;
        }

    }
    get(id: string): Observable<AnnotationType> {
        return this.http.get(`/api/annotations/${id}`) as Observable<AnnotationType>;
    }
    getDatas(CompanyId, AssetId, type): Observable<string> {
        if( CompanyId && AssetId && type){
                  var body = {};
               body["CompanyId"] = CompanyId;
               body["AssetId"] =  AssetId;
               body["type"] =  type;
             return this.http.get('/api/annotations/datas',  {params: body, responseType: 'text'}) as Observable<string>;
        }
    }
    create(annotation: AnnotationType): Observable<AnnotationType> {
        return this.http.post(`/api/annotations`, annotation);
    }
	update(annotation: AnnotationType): Observable<AnnotationType> {
       return this.http.put(`/api/annotations/${annotation.id || annotation._id}`, annotation);
    }
    updateInclude(annotation: AnnotationType, query:{}, include:{}): Observable<AnnotationType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/annotations/${annotation.id || annotation._id}`,  annotation,  {params: body}) as Observable<AnnotationType[]>;
        }else{
            return this.http.put(`/api/annotations/${annotation.id || annotation._id}`, annotation) as Observable<AnnotationType[]>;
        }
    }
	remove(annotation): Observable<AnnotationType> {
        return this.http.delete(`/api/annotations/${annotation.id || annotation._id}`);
    }
    queryRemove(query:{}): Observable<AnnotationType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/annotations',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

