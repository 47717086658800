import { FormsModule } from '@angular/forms';
import { MultimediaFilterVideoModule } from './../../../core/pipes/multimedia-filter-video.module';
import { RsTransferModule } from 'rs-transfer';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { VideoComponent } from './video.component';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgControlsModule } from 'videogular2/compiled/controls';

export const ROUTES = [
    { path: 'lgt-video', component: VideoComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        BrowserModule,
        RsTransferModule,
        MultimediaFilterVideoModule,
        FormsModule,
        VgCoreModule,
        VgBufferingModule,
        VgOverlayPlayModule,
        VgControlsModule
    ],
    declarations: [
        VideoComponent
    ],
    exports: [
        VideoComponent,
    ],
})
export class VideoModule {}
