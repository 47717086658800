import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cacheable } from 'ngx-cacheable';

class SelectionType {
    _id?: string;
	id?: string;
	comment?: string;
	name?: string;
	CompanyId?: string;
}


@Injectable()
export class SelectionService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<SelectionType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/selections', {params: body}) as Observable<SelectionType[]>;
        }else{
            return this.http.get('/api/selections') as Observable<SelectionType[]>;
        }

    }
    queryInclude(query:{}, include:{}): Observable<SelectionType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/selections',  {params: body}) as Observable<SelectionType[]>;
        }else{
            return this.http.get('/api/selections') as Observable<SelectionType[]>;
        }

    }
    get(id: string): Observable<SelectionType> {
        return this.http.get(`/api/selections/${id}`) as Observable<SelectionType>;
    }
    create(selection: SelectionType): Observable<SelectionType> {
        return this.http.post(`/api/selections`, selection);
    }


	update(selection: SelectionType): Observable<SelectionType> {
       return this.http.put(`/api/selections/${selection.id || selection._id}`, selection) ;
    }
    updateInclude(selection: SelectionType, query:{}, include:{}): Observable<SelectionType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/selections/${selection.id || selection._id}`,  selection,  {params: body}) as Observable<SelectionType[]>;
        }else{
            return this.http.put(`/api/selections/${selection.id || selection._id}`, selection) as Observable<SelectionType[]>;
        }
    }
	remove(selection): Observable<SelectionType> {
        return this.http.delete(`/api/selections/${selection.id || selection._id}`);
    }
    queryRemove(query:{}): Observable<SelectionType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/selections',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

