import { Component } from '@angular/core';

@Component({
    selector: 'iconChoice',
    template: require('./icon-choice.html'),
    // styles: [require('./icon-choice.css')],
})
export class IconChoiceComponent {
    message/*: string*/;

    static parameters = [];
    constructor() {
        this.message = 'hello';
    }
}
