import { Component, Input, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'infoDialog',
    template: require('./info-dialog.html'),
     styles: [require('./info-dialog.scss')],
})

export class InfoDialogComponent implements OnInit {
    ConfirmService;
    @Input() title: string;
    @Input() message: string;
    @Input() color: string = 'red';
    @Input() hasConfirmation: Boolean = false;
    ActiveModal;
    static parameters = [NgbActiveModal];
    constructor(private activeModal: NgbActiveModal) {
        this.ActiveModal = activeModal;
    }
    ngOnInit(){
        var currentClass = this;
        if( this.hasConfirmation == false ){
            setTimeout(function(){ currentClass.close() }, 2000);
        }
    }
      public close(isConfirmed = true) {
        this.ActiveModal.close(isConfirmed);
      }


}
