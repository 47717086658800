

import { MultimediaFilterVideoModule } from './../../core/pipes/multimedia-filter-video.module';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchToolComponent } from './search-tool.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgbdModalContent} from './search-tool-modal.component';
import {NgbdModalValidationContent} from './validation/search-tool-modal.component';
import { FormsModule } from '@angular/forms';
import { GrdFilterPipe } from './search-tool.pipe';
import {GrdFilterInversePipe} from './search-tool-inverse.pipe';
import {TourNgBootstrapModule} from 'ngx-tour-ng-bootstrap';
import { SearchPaneModule } from './search-pane/search-pane.module';
import { GuidelineModule } from '../../pages/asset/guideline/guideline.module';
import { MultimediavariantModule } from '../../pages/asset/multimediavariant/multimediavariant.module';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { InfoAssetsModule } from '../../pages/asset/info-assets/info-assets.module';
import { FileImporterModule } from './../file-importer/file-importer.module';
import { OrderModule } from 'ngx-order-pipe';
import { RetouchModule } from '../processing/retouch/retouch.module';
import { VideoModule } from '../asset/video/video.module';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgControlsModule } from 'videogular2/compiled/controls';
export const ROUTES/*: Routes*/ = [
    { path: 'search-tool', component: SearchToolComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        CommonModule,
        NgbModule.forRoot(),
        TourNgBootstrapModule,
        FormsModule,
        SearchPaneModule,
        GuidelineModule,
        MultimediavariantModule,
        VideoModule,
        RetouchModule,
        DeviceDetectorModule,
        InfoAssetsModule,
        OrderModule,
        FileImporterModule,
        MultimediaFilterVideoModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule

    ],
    declarations: [
        NgbdModalContent,
        NgbdModalValidationContent,
        SearchToolComponent,
        GrdFilterPipe,
        GrdFilterInversePipe
    ],
    exports: [
        SearchToolComponent,
    ],
    entryComponents: [NgbdModalContent, NgbdModalValidationContent]
})
export class SearchToolModule {}
