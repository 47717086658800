import { Component } from '@angular/core';

@Component({
    selector: 'sortedCategory',
    template: require('./sorted-category.html'),
    // styles: [require('./sorted-category.css')],
})
export class SortedCategoryComponent {
    message/*: string*/;

    static parameters = [];
    constructor() {
        this.message = 'hello';
    }
}
