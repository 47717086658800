
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CompanyComponent } from './company.component';
import { FileModule } from '../shared-pages/file/file.module';
import {CompanyColorModule} from '../shared-pages/companycolor/companycolor.module';
import { GuidelineModule } from '../asset/guideline/guideline.module';
import {RsTransferModule} from 'rs-transfer';

export const ROUTES
/*: Routes*/ = [
    { path: 'company', component: CompanyComponent },
    { path: 'company/:id', component: CompanyComponent },

];



@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        NgbModule,
        FileModule,
        CompanyColorModule,
        GuidelineModule,
        RouterModule.forChild(ROUTES),
        RsTransferModule
    ],
    declarations: [
        CompanyComponent,
    ],
    exports: [
        CompanyComponent,
    ],
})
export class CompanyModule {}

