import { InfoDialogService } from './../../../core/services/info-dialog/info-dialog.service';


import { CommandVisualsService } from './../../../core/services/command-visuals/command-visuals.service';
import { ConfirmationDialogService } from './../../../core/services/confirm/confirm.service';
import { MultimediaVariantService } from './../../../core/services/multimediavariant/multimediavariant.service';
import { Component, Input, ViewChild, OnInit, AfterViewChecked, Output, EventEmitter } from '@angular/core';
import { AssetExt } from '../../../core/models/types.extension';
import { MultimediaVariant } from '../../../core/models/types';
import {DomSanitizer} from '@angular/platform-browser';
import { FileDto, ApplicationType, ModuleType, TypeDisplayedFileDto } from 'rs-transfer';
import { Subject } from 'rxjs';
import {  transferEnv } from '../../../app.constants';


@Component({
    selector: 'lgt-video',
    template: require('./video.html'),
     styles: [require('./video.scss')],
     providers: [MultimediaVariantService, ConfirmationDialogService, CommandVisualsService, InfoDialogService]
})
export class VideoComponent implements OnInit, AfterViewChecked{
    urlTransfer = transferEnv;
    @Input() asset: AssetExt;
    @Input() tokenUser;
    @Input() isEditable;
    @Input() activated: boolean = false;
    @Input() isSM: boolean;
    @Input() isMobile = false;
    @Input() selectedVideo;
    @Input() currentCompany;
    @Input() currentUser;
    sizes = {};
    Sanitizer;
    width;
    height;
    isNotLoaded = true;
    loaded = {};
    minHeight = 120;
    frameAreLoaded = {};
    changeDone = false ;
    isLoaded = false;
    showIframe = false;
    mediaInfos;
    oldAsset;
    ConfirmationDialogService;
    videoInfos;
    MultimediaVariantService;
    InfoDialogService;
    files: FileDto[] = [];
    linkedFiles: FileDto[] = [];
    uploadFiles: Subject<any> = new Subject();
    uploadMastersFiles: Subject<any> = new Subject();
    @Output() addedFiles = new EventEmitter(true);

    CommandVisualsService;
    urlInfos = {
      CompanyId : undefined,
      ApplicationId: ApplicationType.Lightbox,
      AccessToken : localStorage.getItem('access_token'),
      Module : ModuleType.Video,
      Url: this.urlTransfer,
      GenerateThumb: true,
      texts: {
        afterUploadMsg_success: 'Successfully Uploaded !',
        afterUploadMsg_error: 'Upload Failed !',
        title: 'Upload Video',
      }
    };
    static parameters = [DomSanitizer, MultimediaVariantService, ConfirmationDialogService, CommandVisualsService, InfoDialogService];
    constructor(private sanitizer: DomSanitizer, private multimediaService: MultimediaVariantService, private confirmationDialog: ConfirmationDialogService, private commandVisual: CommandVisualsService, private dialogService: InfoDialogService) {
     this.Sanitizer = sanitizer;
     this.MultimediaVariantService = multimediaService;
     this.ConfirmationDialogService = confirmationDialog;
     this.CommandVisualsService = commandVisual;
     this.InfoDialogService = dialogService;
    }
    remove(asset){
        this.asset.MultimediaVariants.splice( this.asset.MultimediaVariants.indexOf(asset), 1 );
    }

    ngOnInit(){
    }

    ngAfterViewChecked(){

    }

    onSelect(event) {
        this.addedFiles.emit(true);
    }
    docUpload(event){

    }
    onRemove(event) {
        this.addedFiles.emit(true);
    }

    sendCommand(){
            this.ConfirmationDialogService.confirm("New command", "Describe your command : ", true, "Send", 'Cancel', '', '', this.currentCompany._id,  ModuleType.Video, 'lg')
            .then((confirmed) => {
                if( confirmed != false ){
                    this.CommandVisualsService.command([this.asset], this.currentUser, this.currentCompany, confirmed).subscribe((data) => {
                        this.InfoDialogService.confirm("Command of assets", 'Command successfully passed', 'green')
                    }, (err)=> {
                        this.InfoDialogService.confirm("Command of assets", 'Command fail', 'red')
                    });
                }
              })

    }
    setVisible(){
        if( this.selectedVideo.isvisible ){
            this.selectedVideo.isvisible = false;
        }else{
            this.selectedVideo.isvisible = true;
        }
        this.MultimediaVariantService.update(this.selectedVideo).subscribe();

    }
    async downloadAllFiles(){
        $('.download-all').each(function(index, elem){
            new Promise((resolve, reject) => {
                resolve(window.open(elem["href"], '_blank'));
              });
        });
    }
    getUrl(videos){
        var url = this.urlInfos.Url + '/api/rstransfer/' + this.selectedVideo.TransferId + '?displayType=1' + "&access_token=" + localStorage.getItem("access_token");
        if( videos.src != url){
            videos.src = url;
            videos.load();
        }else{
            return url;
        }
    }

    setFiles(multimediaVariants: MultimediaVariant[]){
        var values = multimediaVariants.filter( e =>  e.digitaltype == "Linked");
        var masters = multimediaVariants.filter( e => e.digitaltype == "Master");
        if( masters.length > 0 ){
            var file = new FileDto();
            file.id = Number(masters[0].TransferId);
            file.type = 2;
            this.files.push(file);
        }
        if( values.length > 0 ){
            values.forEach(element => {
                var file = new FileDto();
                file.id = Number(element.TransferId);
                file.displayType = TypeDisplayedFileDto.Thumb;
                file.type = 2;
                this.linkedFiles.push(file);
            });
        }

    }
    changeVideo(frame){
       var id = frame.target.value;
       if( id != "" ){
           this.selectedVideo = this.asset.MultimediaVariants.filter( e => e._id == id)[0];
       }
    }
    upload(CompanyId){
        this.urlInfos.CompanyId = CompanyId;
        var linekFiles = new Promise<object[]>((resolve, reject) => {
        this.uploadFiles.next(true);
        this.uploadFiles.subscribe((data) => {
            if( data.length >= 0 ){
                var videoFiles = [];
                if( data == "No Files"){
                    resolve([]);
                    return;
                }
                data.forEach(element => {
                        var videoFile = {id: element, type: "Linked"};
                        videoFiles.push(videoFile);
                });
                resolve(videoFiles);
            }else{
                resolve([]);
                return;
            }
        }, (error) => {
            if( error.includes("No Files")){
                resolve();
            }else{
                reject(error);
            }
        });
     });

     var masterFiles = new Promise<object[]>((resolve, reject) => {
        this.uploadMastersFiles.next(true);
        this.uploadMastersFiles.subscribe((dataMaster) => {
            if( dataMaster.length >= 0 ){
                var videoFiles = [];
                if( dataMaster == "No Files"){
                    resolve([]);
                    return;
                }
                dataMaster.forEach(element => {
                    var videoFile = {id: element, type: "Master"};
                    videoFiles.push(videoFile);
                });
                resolve(videoFiles);
            }else{
                resolve([]);
                return;
            }
        }, (error) => {
            if( error.includes("No Files")){
                resolve();
            }else{
                reject(error);
            }
        });
     });
     return Promise.all([linekFiles, masterFiles]);
    }

}
