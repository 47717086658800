
import { Injectable, EventEmitter, Output } from '@angular/core';
import { UserService } from './user.service';
import { CompanyService } from '../company/company.service';
import { HttpClient } from '@angular/common/http';
import { safeCb } from '../util';
import { authEnv, urlEnv } from '../../../app.constants';
import {UserExt, Company} from '../../models/types';
import { UserManager, User, WebStorageStateStore, } from 'oidc-client';
import { AccessService } from './access.service';
import { CookieService } from 'ngx-cookie-service';
// @flow

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    auth = authEnv;
    url = urlEnv;
    config = {
    authority: this.auth,
    client_id: "lightbox",
    redirect_uri: this.url + "/assets/oidc-login-redirect.html",
    silent_redirect_uri: this.url + "/assets/silent-redirect.html",
    scope: 'openid profile email roles api-mailcenter api-contact api-transfer companies applications api-lightbox',
    response_type: 'id_token token',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: false,
    accessTokenExpiringNotificationTime: 120, // x second before show session expring popup
    };
    _currentUser: UserExt = new UserExt();
    _currentSelectedCompany: Company = new Company();
    idsUser:User;
    @Output() currentSelectedCompanyChanged = new EventEmitter(true);
    UserService;
    CompanyService;
    isLoggedIn = false;
    access:AccessService = new AccessService(this);
    isRs: boolean = false;
    userManager = new UserManager(this.config);
    static parameters = [HttpClient, UserService, CompanyService, CookieService];
    constructor(private http: HttpClient, private userService: UserService, private companyService: CompanyService, private cookieService: CookieService) {
        this.http = http;
        this.UserService = userService;
        this.CompanyService = companyService;
        var component = this;
        this.userManager.getUser().then(user => {
            component.idsUser = user;
            this.renewTokenManual();
            if( user ){
            component.isRs = user.profile.companies.includes("RS-Solutions");
            component.ligUser(user);
            document.getElementById('rs-loader').style.display = "none";
            }
        });
        this.userManager.events.addAccessTokenExpired(args => {
            this.logout();
        });
    }

    renewTokenManual(): Promise<any> {
        return this.userManager.signinSilent();
    }

    /**
     * Check if userRole is >= role
     * @param {String} userRole - role of current user
     * @param {String} role - role to check against
     */

    get currentUser() {
        return this._currentUser;
    }


    set currentUser(user) {
        this._currentUser = user;
    }

    set currentSelectedCompany(company:Company){
        this._currentSelectedCompany = company;
        this.globalOverride(company);
        this.currentSelectedCompanyChanged.emit(company);
    }

    get currentSelectedCompany(){
        return this._currentSelectedCompany ;
    }

    globalOverride(company) {
        if( company  ){
            if( company.CompanyColors ){
                if( company.CompanyColors.length > 0 ){
                    document.querySelector('body').style.setProperty('--clientColor',     company.CompanyColors[0].color);
                }
            }
        }

    }
    setUserCompany(){
        this.currentSelectedCompany = this.currentUser.Company;
    }


    hasAccess(role: string): boolean {
        var result =false;
        if( this.idsUser != undefined ){
            if( this.idsUser.profile.role  != undefined){
                if(  this.idsUser.profile.role instanceof Array ){
                    if(this.idsUser.profile.role.some(r => r == role)){
                        result = true;
                    }
                }else{
                    if(this.idsUser.profile.role == role){
                        result = true;
                    }
                }

            }
        }

        return result;
    }

    startAuthentication(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    completeAuthentication(): Promise<void> {
        return this.userManager.signinRedirectCallback().then(user => {
            this.ligUser(user);
        });
    }

    ligUser(user){
        this.idsUser = user;
        localStorage.setItem('access_token', user.access_token);
        this.cookieService.set( 'token', user.access_token );
        return this.UserService.get().toPromise()
            .then((user: UserExt) => {
                this.currentUser = user;
                this.currentSelectedCompany = user.Companies[0];
                return user;
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }
    signoutRedirectCallback(): Promise<any> {
        return this.userManager.signoutRedirectCallback().then((logout) => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('company-selected');
            localStorage.removeItem('user');
            localStorage.removeItem('assets');
            localStorage.removeItem('assetsParams');
        });
    }

    logout(): Promise<any> {
        return this.userManager.signoutRedirect();
    }

    sendRequestpassword(email:String){
        return this.UserService.requestPassword(email).toPromise().then((result) => {
            return Promise.resolve(result);
        },
        (error) => {
            return Promise.reject(error);
        }
        );
    }


    /**
     * Gets all available info on a user
     *
     * @param  {Function} [callback] - function(user)
     * @return {Promise}
     */
    getCurrentUser(callback?) {
        safeCb(callback)(this.currentUser);
        return Promise.resolve(this.currentUser);
    }

    /**
     * Checks if user is logged in
     * @param {function} [callback]
     * @returns {Promise}
     */
    isLoggedInOIDC(): Promise<Boolean> {
        return this.userManager.getUser().then((user) => {
            this.isLoggedIn =  user != null && !user.expired;
            return this.isLoggedIn;
        });
    }

    getClaims(): any {
        return this.idsUser.profile;
    }

    /**
     * Get auth token
     *
     * @return {String} - a token string used for authenticating
     */
    get tokenUser(){
        return localStorage.getItem("access_token");
    }
}
