import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class FeatureCatType {
    _id?: string;
	id?: string;
	name?: string;
CompanyId?: string;
	
}

	@Injectable({ providedIn: 'root' })
	export class FeatureCatService {
	static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

	query(query:{}): Observable<FeatureCatType[]> {
	  if( query != undefined ){
			 var body = {};
			 body["where"] = JSON.stringify(query);
			 return this.http.get('/api/featurecats', {params: body}) as Observable<FeatureCatType[]>;
        }else{
            return this.http.get('/api/featurecats') as Observable<FeatureCatType[]>;
        }
       
    }
	queryInclude(query:{}, include:{}): Observable<FeatureCatType[]> {
	  if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.get('/api/featurecats',  {params: body}) as Observable<FeatureCatType[]>;
        }else{
            return this.http.get('/api/featurecats') as Observable<FeatureCatType[]>;
        }
       
    }
    get(id: string): Observable<FeatureCatType> {
        return this.http.get(`/api/featurecats/${id}`) as Observable<FeatureCatType>;
    }
    create(featurecat: FeatureCatType): Observable<FeatureCatType> {
        return this.http.post(`/api/featurecats`, featurecat);
    }
	update(featurecat: FeatureCatType): Observable<FeatureCatType> {
       return this.http.put(`/api/featurecats/${featurecat.id || featurecat._id}`, featurecat);
    }
    updateInclude(featurecat: FeatureCatType, query:{}, include:{}): Observable<FeatureCatType[]> {
      if( query != undefined || include != undefined ){
			    var body = {};
			 body["where"] = JSON.stringify(query);
			 body["include"] = JSON.stringify(include);
			 return this.http.put(`/api/featurecats/${featurecat.id || featurecat._id}`,  featurecat,  {params: body}) as Observable<FeatureCatType[]>;
        }else{
            return this.http.put(`/api/featurecats/${featurecat.id || featurecat._id}`, featurecat) as Observable<FeatureCatType[]>;
        }
    }
	remove(featurecat): Observable<FeatureCatType> {
        return this.http.delete(`/api/featurecats/${featurecat.id || featurecat._id}`);
    }
    queryRemove(query:{}): Observable<FeatureCatType> {
		var body = {};
		body["where"] = JSON.stringify(query);
        return this.http.delete('/api/featurecats',  {params: body});
    }
        getByUrl(url): Observable<any> {
			return this.http.get(url);
		}
}

