import { Component, OnInit, OnDestroy } from '@angular/core';
import { StateCatService } from '../../core/services/statecat/statecat.service';
import { TagCatService } from '../../core/services/tagcat/tagcat.service';
import { TagService } from '../../core/services/tag/tag.service';
import { FeatureCatService} from '../../core/services/featurecat/featurecat.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { SocketService } from '../../core/services/socket/socket.service';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { InfoDialogService } from '../../core/services/info-dialog/info-dialog.service';

@Component({
    selector: 'tagAdmin',
    template: require('./tag-admin.html'),
     styles: [require('./tag-admin.scss')],
     providers: [StateCatService, TagCatService, TagService, FeatureCatService, SocketService, InfoDialogService]
})
export class TagAdminComponent implements OnInit, OnDestroy {
    FeatureCatService;
    StateCatService;
    TagCatService;
    TagService;
    AuthService;
    errors = {};
    currentCompany;
    tagCatName;
    stateCats;
    newStateCat = {name: '', color: '', CompanyId: ''};
    tagCats;
    newTagCat = {name: '', CompanyId: ''};
    tags;
    tagCatsName;
    newTag = {name: '', TagCatId: '', CompanyId: ''};
    featureCats;
    isVideo = false;
    isDigital = false;
    isSocialMedia = false;
    newFeatureCat = {name: '', CompanyId: ''};
    SocketTagService = new SocketService();
    SocketFeatureService = new SocketService();
    SocketTagCatService = new SocketService();
    SocketStateService = new SocketService();
    color;
    searchOptions = {};
    InfoDialogService;
    static parameters = [StateCatService, TagCatService, TagService, FeatureCatService, AuthService, InfoDialogService];
    constructor(private stateCatService: StateCatService,  private tagCatService: TagCatService, private tagService: TagService, private featureCatService: FeatureCatService, private authService: AuthService, private dialogService: InfoDialogService) {
        this.StateCatService = stateCatService;
        this.FeatureCatService = featureCatService;
        this.TagCatService = tagCatService;
        this.TagService = tagService;
        this.AuthService = this.authService;
        this.InfoDialogService = dialogService;

    }

    ngOnInit(){
        this.setCurrentCompany();
    }

     ngOnDestroy(){
        this.SocketTagCatService.unsyncUpdates("tagcat");
        this.SocketTagService.unsyncUpdates("tag");
        this.SocketFeatureService.unsyncUpdates("featurecat");
        this.SocketStateService.unsyncUpdates("statecat");
    }

    setCurrentCompany(){
        this.currentCompany = this.AuthService.currentSelectedCompany;
        this.getSelectedCompanies(this.currentCompany);
        this.AuthService.currentSelectedCompanyChanged.subscribe(company => {
            this.getSelectedCompanies(company);
        });
    }
    getSelectedCompanies(company){
        this.currentCompany = company;
        this.FeatureCatService.queryInclude({CompanyId : company._id}, ["Assets"]).subscribe((result) =>{
            this.featureCats = result;
            this.SocketFeatureService.syncUpdates("featurecat", result);
         })
        this.StateCatService.queryInclude({CompanyId : company._id}, ["Assets"]).subscribe((result) =>{
                this.stateCats = result;
                this.SocketStateService.syncUpdates("statecat", result);
          })
          var searchCatOptions = {CompanyId : company._id};
        this.TagCatService.queryInclude(searchCatOptions, ["Tags"]).subscribe((result) =>{
            this.tagCats = result;
            this.tagCatsName = result.map(m =>{
                return m.name;
            })
            this.SocketTagCatService.syncUpdates("tagcat", result);
         })
         this.TagService.queryInclude({CompanyId : company._id}, ["Assets"]).subscribe((result) =>{
            this.tags = result;
            this.SocketTagService.syncUpdates("tag", result);
      })

    }

    search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.tagCats.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
    formatter = (x: {name: string}) => x.name;
    saveTagCat(form) {
        if(form.invalid) return;
        return this.TagCatService.create({
            name: this.newTagCat.name,
            isvideo: true,
            CompanyId: this.currentCompany._id
        }).subscribe((data) => {
           this.newTagCat = {name: '', CompanyId: ''};
            },
           (err) => {
            this.errors = {};

            // Update validity of form fields that match the sequelize errors
            if(err.name) {
                this.errors["TagCat"] = err.error.errors[0].message.replace(err.error.errors[0].path, "");
            }
           }
        )
    }

    setDisplay(tagCat){
        if( tagCat.isdisplayed == true ){
            tagCat.isdisplayed = false;
        }else{
            tagCat.isdisplayed = true;
        }
        this.TagCatService.update(tagCat).subscribe();
    }
    setDisplayTag(tagCat){
        if( tagCat.istagsdisplayed == true ){
            tagCat.istagsdisplayed = false;
        }else{
            tagCat.istagsdisplayed = true;
        }
        this.TagCatService.update(tagCat).subscribe();
    }
    setVideoTag(tagCat){
        if( tagCat.isvideo == true ){
            tagCat.isvideo = false;
        }else{
            tagCat.isvideo = true;
        }
        this.TagCatService.update(tagCat).subscribe();
    }

    getTagName(tag){
        if( this.tagCats != undefined ){
            var tagCat =  this.tagCats.filter(e => {return e._id ==  tag.TagCatId});
            if( !(tagCat == undefined || tagCat.length == 0 )){
                return "(" +  tagCat[0].name + ")";
            }else{
                return "";
            }
        }
    }

    saveTag(form) {
		if(form.invalid) return;
        if (typeof this.tagCatName === 'string' || this.tagCatName instanceof String){
            return this.saveTagCatAndTag();
        }
        return this.TagService.create({
            name: this.newTag.name,
            TagCatId: this.tagCatName._id,
            CompanyId: this.currentCompany._id
        }).subscribe((data) => {
            this.newTag.name = '';
            this.tagCatName = '';
        }, (err) => {
            this.errors = {};
            // Update validity of form fields that match the sequelize errors
            if(err.name) {
                this.errors["Tag"] = err.error.errors[0].message.replace(err.error.errors[0].path, "");
            }
        })
    }
    saveTagCatAndTag(){
        return this.TagCatService.create({
            name: this.tagCatName,
            CompanyId: this.currentCompany._id
        }).subscribe((data) => {
            return this.TagService.create({
                name: this.newTag.name,
                TagCatId: data._id,
                CompanyId: this.currentCompany._id
            }).subscribe((data) => {
                this.newTag.name = '';
                this.tagCatName = '';
            },
            (err) => {
                this.errors = {};

                // Update validity of form fields that match the sequelize errors
                if(err.name) {
                    this.errors["Tag"] = err.error.errors[0].message.replace(err.error.errors[0].path, "");
                }
            })
        })
    }

    getTagsByTagCat(tagCat){
        if( this.tags ){
            return this.tags.filter((elem) => {return elem.TagCatId == tagCat._id});
        }else{
            return [];
        }
    }

    saveFeatureCat(form) {
		if(form.invalid) return;

        return this.FeatureCatService.create({
            name: this.newFeatureCat.name,
            CompanyId: this.currentCompany._id
        }).subscribe((data) => {
            this.newFeatureCat = {name: '', CompanyId: ''};
        },
        (err) => {
            this.errors = {};

            // Update validity of form fields that match the sequelize errors
            if(err.name) {
                this.errors["FeatureCat"] = err.error.errors[0].message.replace(err.error.errors[0].path, "");
            }
        })
    }

    saveStateCat(form) {
		if(form.invalid) return;
        return this.StateCatService.create({
            name: this.newStateCat.name,
            color: this.color,
            CompanyId: this.currentCompany._id
        }).subscribe((data) => {
            this.newStateCat = {name: '', color: '', CompanyId: ''};
        },
            (err) => {
                this.errors = {};

                // Update validity of form fields that match the sequelize errors
                if(err.name) {
                    this.errors["StateCat"] = err.error.errors[0].message.replace(err.error.errors[0].path, "");
                }
            })
    }

    deleteTag(entity) {
        if( entity["Assets"] == undefined ||  entity["Assets"].length == 0 ){
        return this.TagService.remove(entity)
            .subscribe(() => {

            });
        }else{
            this.InfoDialogService.confirm("Removing tag", 'Cannot remove, with linked assets', 'red')
            .then((confirmed) => {

              })
        }
    }
    deleteTagCat(entity) {
        if( entity["Tags"] == undefined ||  entity["Tags"].length == 0 ){
            return this.TagCatService.remove(entity)
            .subscribe(() => {

            });
            }else{
                this.InfoDialogService.confirm("Removing tag", 'Cannot remove, with linked tags', 'red')
                .then((confirmed) => {

                  })
            }

    }
    deleteFeatureCat(entity) {
            if( entity["Assets"] == undefined ||  entity["Assets"].length == 0 ){
                return this.FeatureCatService.remove(entity)
                .subscribe(() => {

                });
                }else{
                    this.InfoDialogService.confirm("Removing tag", 'Cannot remove, with linked assets', 'red')
                    .then((confirmed) => {

                      })
                }
    }
    deleteStateCat(entity) {
        if( entity["Assets"] == undefined || entity["Assets"].length == 0 ){
            return this.StateCatService.remove(entity)
            .subscribe(() => {

            });
            }else{
                this.InfoDialogService.confirm("Removing tag", 'Cannot remove, with linked assets', 'red')
                .then((confirmed) => {

                  })
            }

    }

    editTagCat(tag){
        tag.edit = true;
    }
    updateTagCat(tag){
        this.TagCatService.update(tag).subscribe();
        tag.edit = false;
    }
    editTag(tag){
            tag.edit = true;
    }
    updateTag(tag){
        this.TagService.update(tag).subscribe();
        tag.edit = false;
    }
    editFeatureCat(tag){
        tag.edit = true;
    }
    updateFeatureCat(tag){
        this.FeatureCatService.update(tag).subscribe();
        tag.edit = false;
    }
    editStateCat(tag){
            tag.edit = true;
    }
    updateStateCat(tag){
        this.StateCatService.update(tag).subscribe();
        tag.edit = false;
    }

}
