import { Guideline } from '../../core/models/types';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../core/services/auth/auth.service';
import {TagManagerService} from '../../core/services/tag-manager/tag-manager.service';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
    selector: 'main',
    template: require('./main.html'),
    styles: [require('./main.scss')],
    providers: [TagManagerService,]
})
export class MainComponent implements OnInit, OnDestroy {
    TagManagerService;
    isLoggedIn = false;
    currentUser;
    AuthService;
    ActivatedRoute;
    currentCompany;
    Router;
    guidelineUrl;
    kento = "tfcFoOzhNjzwh4FVyo85eyG82UIyUolM";
    static parameters = [HttpClient, TagManagerService, AuthService, ActivatedRoute, Router];
    constructor(private http: HttpClient, private tagManagerService: TagManagerService, private authService: AuthService, private activatedRoute : ActivatedRoute, private router: Router) {
        this.http = http;
        this.TagManagerService = tagManagerService;
        this.AuthService = authService;
        this.Router = router;
        this.reset();
        this.currentUser = this.AuthService.currentUser;
        this.setCurrentCompany();
        //Connexion et déconnexion

            this.AuthService.isLoggedInOIDC().then( (result) => {
                if( result){
                    this.isLoggedIn = true;
                }
            });


    }

    login(){


    }
    ngOnInit() {

        if( process.env.NODE_ENV === 'qa' ){
            $('body').css('background', 'repeating-linear-gradient(   45deg,   #2F3238,   #2F3238 10px,   #363940 10px,   #363940 20px )');
        }

    }
    setCurrentCompany(){
        this.currentCompany = this.AuthService.currentSelectedCompany;
    }


    reset() {
        this.AuthService.isLoggedInOIDC().then( (result) => {
            if( result ){
                this.isLoggedIn = true;
            }
        });
        this.AuthService.getCurrentUser().then(user => {
            this.currentUser = user;
        });

    }

    ngOnDestroy() {

    }

}
