import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { AssetExt } from '../../../core/models/types.extension';

@Component({
    selector: 'linkedAssets',
    template: require('./linked-assets.html'),
    styles: [require('./linked-assets.scss')],
})
export class LinkedAssetsComponent implements OnInit, OnChanges {
    message/*: string*/;
    @Input() asset: AssetExt;
    @Input() tokenUser;
    @Input() assets;
    currentSearch;
    static parameters = [];
    linkedAssets;

    constructor() {

    }

    ngOnInit(){

    }

    ngOnChanges(){
        this.concactArrays();
    }

    concactArrays(){
        if( this.notEmpty(this.asset.Children) && this.notEmpty(this.asset.Parents) ){
           return  this.removeDuplicates(this.asset.Children.concat(this.asset.Parents), "_id");
        }

        if(  this.notEmpty(this.asset.Children)){
            return  this.asset.Children;
        }
        if( this.notEmpty(this.asset.Parents)){
            return this.asset.Parents;
        }
    }
    notEmpty(object){
        var result = false;
        if( object != undefined ){
            if( object.length > 0  ){
                result = true;
            }
        }

        return result;
    }

    removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }
    changeSearch(currentSearch){
        this.currentSearch = currentSearch;
   }
  removeSearch(){
      this.currentSearch = "";
    }

    onEnter(){
        this.addLinked();
    }

    addLinked(){
        if(  this.notEmpty(this.asset['Children'])){
            this.asset['Children'].push(this.currentSearch);
        }else{
            this.asset['Children'] = [];
            this.asset['Children'].push(this.currentSearch);
        }
        this.currentSearch = "";
        this.concactArrays();
    }

    remove(linkedAsset){
        if(  this.notEmpty(this.asset['Children'])){
            for( var i = 0; i < this.asset['Children'].length; i++){
                if ( this.asset['Children'][i]["fullname"] === linkedAsset.fullname) {
                    this.asset['Children'].splice(i, 1);
                }
             }
        }
        if( this.notEmpty(this.asset['Parents'])){
            for( var i = 0; i < this.asset['Parents'].length; i++){
                if ( this.asset['Parents'][i]["fullname"] === linkedAsset.fullname) {
                    this.asset['Parents'].splice(i, 1);
                }
             }
        }
    }
}


