import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CompanyColorService } from '../../../core/services/companycolor/companycolor.service';
import { SocketService } from '../../../core/services/socket/socket.service';
import {AuthService} from '../../../core/services/auth/auth.service';

class CompanyColor {
    	_id: '';
        name: '';
        color: '';
		CompanyId: '';
}

@Component({
    selector: 'companycolor',
    template: require('./companycolor.html'),
     styles: [require('./companycolor.scss')],
	 providers: [CompanyColorService]
})

export class CompanyColorComponent implements OnInit, OnDestroy  {
    companycolors: CompanyColor[] = [];
    @Input() companycolor: CompanyColor =  new CompanyColor();
    CompanyColorService;
    color;
	SocketService;
	AuthService;
	currentUser;
	newCompanyColor = '';
	 errors: {field?: Error} = {};
    submitted = false;

	static parameters = [CompanyColorService, SocketService, AuthService];
    constructor(private companycolorService: CompanyColorService, private socketService: SocketService, private authService: AuthService) {
        this.CompanyColorService = companycolorService;
         this.SocketService = socketService;
        this.companycolor.color = this.color;
		 this.AuthService = authService;
         this.currentUser = this.AuthService.currentUser;
    }

	 ngOnInit() {
		 this.CompanyColorService.query()
		   .subscribe((companycolors: CompanyColor[]) => {
                this.companycolors = companycolors;
                this.SocketService.syncUpdates('companycolor', this.companycolors);
            });

    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('companycolor');
    }

	save(form){
        if( this.companycolor._id == undefined ){
            this.createCompanyColor(form);
        }else{
            this.updateCompanyColor(form);
        }
    }


    createCompanyColor(form) {
		if(form.invalid) return;

        this.submitted = true;

            return this.CompanyColorService.create({
                name: this.companycolor.name,
                color: this.companycolor.color,
		CompanyId: this.companycolor.CompanyId,
			})
		.subscribe(err => {
                this.errors = {};

                // Update validity of form fields that match the sequelize errors
                if(err.name) {
                    err.fields.forEach(field => {
                        this.errors[field] = err.message;
                    });
                }

                this.submitted = false;
            })
    }
	updateCompanyColor(form) {
		if(form.invalid) return;

        this.submitted = true;

            return this.CompanyColorService.update({
		 _id: this.companycolor._id,
				name: this.companycolor.name,
                color: this.companycolor.color,
		CompanyId: this.companycolor.CompanyId,
			})
		.catch(err => {
                this.errors = {};

                // Update validity of form fields that match the sequelize errors
                if(err.name) {
                    err.fields.forEach(field => {
                        this.errors[field] = err.message;
                    });
                }

                this.submitted = false;
            });
    }


    deleteCompanyColor(companycolor) {
        return this.CompanyColorService.remove(companycolor._id)
            .subscribe(() => {
                console.log('Deleted CompanyColor');
            });
    }

}

