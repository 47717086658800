import { Component } from '@angular/core';
import { AssetService } from '../../core/services/asset/asset.service';
import { SocketService } from '../../core/services/socket/socket.service';
import { AuthService } from '../../core/services/auth/auth.service';


@Component({
    selector: 'listPane',
    template: require('./list-pane.html'),
     styles: [require('./list-pane.scss')],
     providers: [ AssetService ]
})
export class ListPaneComponent {
    AssetService:AssetService;
    SocketService: SocketService;
    AuthService: AuthService;

    static parameters = [AssetService, SocketService, AuthService];
    constructor(assetService: AssetService, socketService: SocketService, authService: AuthService) {
        this.AssetService = assetService;
        this.SocketService = socketService;
        this.AuthService = authService;
    }
}
