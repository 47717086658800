import { MasterlineModule } from './pages/masterline/masterline.module';

import {
    NgModule,
    ApplicationRef,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    removeNgStyles,
    createNewHosts,
    createInputTransfer,
} from '@angularclass/hmr';

import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { MainModule } from './layout/main/main.module';
import { DirectivesModule } from './layout/directives.module';
import { JwtModule } from '@auth0/angular-jwt';

import { FileModule } from '../app/pages/shared-pages/file/file.module';
//GENERATORBEGIN
 import {CaptionModule } from './pages/caption/caption.module';
 import {HistoryBlockModule } from './pages/historyblock/historyblock.module';
//GENERATOREND
import { IconChoiceModule } from './pages/icon-choice/icon-choice.module';


import { ListPaneModule } from './pages/list-pane/list-pane.module';

import { SortedCategoryModule } from './pages/sorted-category/sorted-category.module';

import { SearchPaneModule } from './pages/search-tool/search-pane/search-pane.module';
import { SearchToolModule } from './pages/search-tool/search-tool.module';

import {SocketService} from './core/services/socket/socket.service';
import { ConfirmationDialogModule } from './ui/confirmation-dialog/confirmation-dialog.module';

import { TagAdminModule } from './pages/tag-admin/tag-admin.module';

import { TagListModule } from './pages/asset/tag/tag-list/tag-list.module';

import { DragulaService } from 'ng2-dragula';

import { FileImporterModule } from './pages/file-importer/file-importer.module';

import { InfoDialogModule } from './ui/info-dialog/info-dialog.module';
import {TourNgBootstrapModule} from 'ngx-tour-ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';


import { MonitorToolModule } from './pages/monitor-tool/monitor-tool.module';

import { AirToolModule } from './pages/air-tool/air-tool.module';

import { LinkedAssetsModule } from './pages/asset/linked-assets/linked-assets.module';

import { GuidelineModule } from './pages/asset/guideline/guideline.module';

import { InfoAssetsModule } from './pages/asset/info-assets/info-assets.module';

import { MultimediavariantModule } from './pages/asset/multimediavariant/multimediavariant.module';

import { HistoryPaneModule } from './pages/history-pane/history-pane.module';

import { GuidelineShowModule } from './pages/guideline-show/guideline-show.module';

import { ProcessingModule } from './pages/processing/processing.module';

import { RetouchModule } from './pages/processing/retouch/retouch.module';
import { TokenInterceptor } from './core/services/auth/token.interceptor';

export function tokenGetter() {
    return localStorage.getItem('access_token');
}

const appRoutes: Routes = [{ path: '',
    redirectTo: '/home',
    pathMatch: 'full'
}];

@NgModule({
    imports: [BrowserModule, NgbModule.forRoot(), HttpClientModule, JwtModule.forRoot({
        config: {
            tokenGetter,
        }
    }), RouterModule.forRoot(appRoutes, { enableTracing: process.env.NODE_ENV === 'development' }), MainModule, MasterlineModule, DirectivesModule, FileModule, IconChoiceModule, ListPaneModule, SortedCategoryModule, SearchPaneModule, SearchToolModule, ConfirmationDialogModule, TagAdminModule, TagListModule, FileImporterModule, InfoDialogModule, TourNgBootstrapModule.forRoot(), CaptionModule, HistoryBlockModule, MonitorToolModule, AirToolModule, CaptionModule, HistoryBlockModule, LinkedAssetsModule, GuidelineModule, InfoAssetsModule, MultimediavariantModule, HistoryPaneModule, GuidelineShowModule, ProcessingModule, RetouchModule,
     //GENERATORBEGIN2
 CaptionModule ,
 HistoryBlockModule ,
//GENERATOREND2
],
    declarations: [
        AppComponent,
    ],
    providers:[SocketService, DragulaService, CookieService,  {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      }],
    bootstrap: [AppComponent],
})
export class AppModule {
    static parameters = [ApplicationRef];
    constructor(private appRef: ApplicationRef) {
        this.appRef = appRef;
    }

    hmrOnInit(store) {
        if (!store || !store.state) return;
        console.log('HMR store', store);
        console.log('store.state.data:', store.state.data);
        // inject AppStore here and update it
        // this.AppStore.update(store.state)
        if ('restoreInputValues' in store) {
            store.restoreInputValues();
        }
        // change detection
        this.appRef.tick();
        Reflect.deleteProperty(store, 'state');
        Reflect.deleteProperty(store, 'restoreInputValues');
    }

    hmrOnDestroy(store) {
        var cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
        // recreate elements
        store.disposeOldHosts = createNewHosts(cmpLocation);
        // inject your AppStore and grab state then set it on store
        // var appState = this.AppStore.get()
        store.state = {data: 'yolo'};
        // store.state = Object.assign({}, appState)
        // save input values
        store.restoreInputValues = createInputTransfer();
        // remove styles
        removeNgStyles();
    }

    hmrAfterDestroy(store) {
        // display new elements
        store.disposeOldHosts();
        Reflect.deleteProperty(store, 'disposeOldHosts');
        // anything you need done the component is removed
    }
}
