import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSMPipe'
})
export class FilterMultimediaSMPipe implements PipeTransform {

  constructor() { }
  transform(array) {
    var filteredArray = array.filter(e => {return e.isinteractive == true && e.isvideo != true})
    return filteredArray;
  }

}
