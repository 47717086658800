import { Component } from '@angular/core';

@Component({
    selector: 'processing',
    template: require('./processing.html'),
     styles: [require('./processing.scss')],
})
export class ProcessingComponent {

}
