import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MonitorToolComponent } from './monitor-tool.component';

export const ROUTES/*: Routes*/ = [
    { path: 'monitor-tool', component: MonitorToolComponent },
];


@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
    ],
    declarations: [
        MonitorToolComponent,
    ],
    exports: [
        MonitorToolComponent,
    ],
})
export class MonitorToolModule {}
